@import '../../../styles/style-variables.scss';



.daily-goal-wrapper {
    margin-top: -1rem;
    min-height: 27.5rem;
    position: relative;

    // .abs-div-to-center {
    //     position: absolute;
    //     top: 50%;
    //     /* Position Y halfway in */
    //     left: 20%;
    //     /* Position X halfway in */
    //     transform: translate(-50%, -50%);
    //     /* Move it halfway back(x,y) */
    // }

    .daily-goal-image {
        position: absolute;
        // left: 36.3rem;
        top: 43%;
        /* Position Y halfway in */
        left: 50%;
        /* Position X halfway in */
        transform: translate(-50%, -50%);
        /* Move it halfway back(x,y) */
    }

    .daily-goal-image-size {
        height: 24rem;
        max-width: 100%;
        object-fit: contain;

    }

    .info-wrapper {
        position: absolute;
        // left: 41rem;
        bottom: -2.7rem;
        // top: 50%;
        /* Position Y halfway in */
        left: 50%;
        /* Position X halfway in */
        transform: translate(-50%, -50%);
    }

    .ideal-text {
        font-size: $font-size-18px;
        font-weight: 600;
        color: #979797;
        margin-top: -1rem;
    }

    .scroll-bar {
        width: 1rem;
        // border: 1px solid blue;
        height: 16rem;
        background: #F0F0F0;
        border-radius: 20px;
        transform: rotate(180deg);
        // background-size:auto 30px;
        background-repeat: no-repeat;
    }

    .scroll-bar-bg {
        background: linear-gradient(90deg, #ED4264 0%, #FFEDBC 100%);
        border-radius: 20px;
        // transform: matrix(0, 1, 1, 0, 0, 0);
    }

    .rocket-image-size {
        width: 3.5rem;
    }

    .rocket-wrapper {
        position: absolute;
        // top: 3rem;
        // left: 46.2rem;
        // bottom: 8rem;
        // top: 50%;
        /* Position Y halfway in */
        left: 49.5%;
        /* Position X halfway in */
        transform: translate(-50%, -50%);
        /* Move it halfway back(x,y) */
    }




    .scroll-wrapper {
        position: absolute;
        top: 3rem;
        top: 50%;
        /* Position Y halfway in */
        left: 50%;
        /* Position X halfway in */
        transform: translate(-50%, -50%);
        /* Move it halfway back(x,y) */
        // position: relative;
    }

    .right-side-selection {
        border-radius: 10rem;
        background-color: white;
        // border: 1px solid black;
        width: 1.2rem;
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        // margin-top: 3.2rem;

    }

    .time-wrapper {
        position: absolute;
        top: 3rem;
        // left: 49rem;
        top: 43%;
        /* Position Y halfway in */
        left: 68%;
        /* Position X halfway in */
        transform: translate(-50%, -50%);
        /* Move it halfway back(x,y) */
        z-index: 9999999;
    }

    .time-wrapper-left {
        // top: 10rem;
        // left: 42.5rem;
        // top: 20%;
        /* Position Y halfway in */
        left: 32%;
        bottom: -5rem;
        /* Position X halfway in */
        transform: translate(-50%, -50%);
        /* Move it halfway back(x,y) */
    }

    .left-side-margin {
        margin-top: 3.3rem;
    }

    .left-side-first-min-mt {
        margin-top: 4rem;
    }

    .right-side-second-mt {
        margin-top: 3.5rem !important;
    }

    .green-dot {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 10rem;
        background-color: #50D29D;
        // border: 1px solid black;
    }

    .min-text {
        font-size: $font-size-12px;
        font-weight: 600;
        color: #474646;
    }


}