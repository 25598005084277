@import '../../../styles/style-variables.scss';

.custom-toast-message {
    .custom-toast-message-for-mobile-number {
        background: rgba(0, 0, 0, 0.5);
        /* Background 1 */
        color: white;
        font-size: $font-size-17px;
        font-weight: 700;
        padding: 0.6rem;
        text-align: center;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    .error-color {
        background: rgba(0, 0, 0, 0.5);
        /* Incorrect Red */

        border: 1px solid #FF7C79;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        color: #FF7C79;
    }

    .green-tick-mark {
        width: 2.125rem;
        margin-left: 1rem;
    }

}