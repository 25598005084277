@import '../../../../styles/style-variables.scss';
.custom-incorrect-details-popup {

    font-size: $font-size-12px;
    margin-top: 10rem !important;
    float: center;
    max-width: 32.5rem !important;
    // height: 28.125rem !important;


    .modal-body {
        padding: 0 !important;
    }

    .modal-content {
        // padding: 1rem;
        // background: #F8FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        min-height: 28rem !important;
    }


    .challenge-header {
        width: 32.5rem;
        height: 290px;
        position: absolute;
        left: 0rem;
        top: 0rem;
        border-radius: 10px;
    }

    .challenge-footer {
        width: 32.5rem;
        height: 70px;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        z-index: 1;
    }

    div::-webkit-scrollbar {
        display: none;
    }

    .close-icon {
        width: 28px;
        float: right;
        position: relative;
        top: 0.5rem;
        right: 0.5rem;
    }

    .title {
        padding-top: 1.5rem;
        padding-left: 2rem;
        color: #263238;
        font-size: $font-size-18px;
        font-weight: 700;
    }


    .active-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.7rem 0rem;
        width: 14rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
        position: relative;
        z-index: 99;
    }


    .save-button-disabled {
        background: #DFDFDF !important;
        color: #9D9D9D !important;
    }

    .connect-later-button {
        background: #BDEBEF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: #1DBFBD;
        padding: 0.7rem 0rem;
        width: 14rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
        position: relative;
        z-index: 99;
    }

    .button-wrapper {
        gap: 2rem;
    }

    .header-text {
        font-size: $font-size-20px;
        font-weight: 600;
        width: 80%;
    }

    .school-label {
        font-size: $font-size-16px;
        font-weight: 500;
        max-width: 90%;
        color: $sub-text-color;
    }

    .label-text {
        font-size: $font-size-18px;
        font-weight: 600;
        max-width: 90%;
    }

    .incorrect-image {
        width: 2rem;
    }

    .radio-button {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 1px solid black;
    }

    .body-wrapper {
        position: relative;
        z-index: 99999;
    }

}