@import '../../../styles/style-variables.scss';

.assessment-header {
    height: 4rem;
    background-color: #6B52C8;
    // margin-left: -2rem;
    padding: 2rem;
    width: calc(100%);

    .chapter-title {
        color: white;
        font-size: $font-size-24px;
        font-weight: 700;
    }

    .timer-icon {
        width: 2rem;
    }

    .time-text {
        color: #F8FAFC;
        font-size: $font-size-22px;
        font-weight: 500;
    }

    .complete-text {
        color: #F8FAFC;
        font-size: $font-size-15px;
        font-weight: 600;
    }

    .progress-bar-wrapper {
        position: relative;
        width: 18rem;
        // height: 12px;
        border-radius: 12px;
        background: #EDFFEB;
        // border: 1px solid #EDFFEB;
    }

    #myprogressBar {
        width: 0rem;
        height: 10px;
        border-radius: 10px;
        background: #04EC02;
        // border: 1px solid #04EC02;
    }

    .circle-status {
        position: absolute;
        top: -3.5px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 5px solid #04EC02;
        ;
        background-color: #EDFFEB;
    }

    .question-number-text {
        position: absolute;
        top: -33px;
        width: 38px;
        height: 25px;
        border-radius: 4.8px;
        background: #17C315;
        // border: 5px solid #04EC02;
        ;
        // background-color: #EDFFEB;
        font-size: $font-size-16px;
        font-weight: 700;
        color: #FFF;
    }

    .incresed-width {
        width: 46px;
    }

    .arrow-for-question-number {
        position: absolute;
        top: -10px;
        margin: 0 auto;
        border-top: 0 solid #17C315;
        border-left: 0 solid #F5D97B;
        border-bottom: 0 solid #D94948;
        border-right: 0 solid #8DB434;
        border-top-width: 10px;
        border-left-width: 10px;
        border-right-width: 10px;
        border-bottom-width: 10px;
        background: transparent;
        width: 0;
        height: 0;
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
    }

    
    




}