@import '../../styles/style-variables.scss';
.clicker-schools-list {

    .sub-header-text {
        font-size: $font-size-32px;
        font-weight: 600;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .header-for-practice-question {
        margin-top: -3.7rem;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .image-size {
        width: 40rem;
        // height: 80px;
    }

    .header-text {
        font-size: $font-size-32px;
        font-weight: 600;
    }

    .table-bg {
        background-color: #F5F6FA;
    }

    .student-table-row {
        font-size: $font-size-32px !important;
        font-weight: 600 !important;
    }

    .question-number-circle {
        width: 4rem;
        height: 4rem;
        justify-content: center;
        align-items: center;
        display: flex;
        // border: 1px solid black;
        border-radius: 50px;
        margin-right: 0.5rem;
        padding: 1.5rem;
        margin-top: 0.2rem;
        background-color: #007bff;
        color: white;
    }

    .question-number {
        font-size: $font-size-40px;
        font-weight: 600;
    }

}