@import '../../../styles/style-variables.scss';

.standard-homework-experience-for-clicker {
    margin-left: -1.8rem;

    .margin-for-concept-list {
        margin-left: 1rem;
    }
}

.standard-classwork-learning-experience{
    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: none !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }
}
