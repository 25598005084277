@import '../../../../styles/style-variables.scss';
.home-chapter-card {
    width: 22rem;
    height: 11rem;
    border-radius: 10px;
    padding: 1rem;
    position: relative;


    .chapter-image-size {
        width: 2.5rem;
    }

    .chapter-title {
        font-size: $font-size-16px;
        font-weight: 700;
    }

    .chapter-progress-bar {
        width: 90%;
        height: 0.9rem;
        background-color: #E4E4E4;
        border-radius: 10px;

        .chapter-inner-progress {
            background-color: #4C95F3 !important;
            height: 0.9rem;
            border-radius: 10px;
        }
    }
    .progress-value {
        font-size: $font-size-13px;
        font-weight: 600;
    }

    .lock-icon-size {
        width: 2rem;
    }

    .blue-arrow-icon-size {
        width: 2.5rem;
        position: absolute;
        right: 1rem;
        bottom: 1rem;
    }
}