@import '../../../styles/style-variables.scss';

.select-level-wrapper {
    min-height: 26.5rem;

    .select-level-image {
        width: 5rem;
    }

    .student-level {
        min-width: 40rem;
        background: #FFFFFF;
        border: 4px solid #CDFFF8;
        border-radius: 50px;
        padding: 1rem;
    }

    .title-text {
        font-size: $font-size-19px;
        font-weight: 700;
        color: #455B64;
    }

    .sub-title-text {
        font-size: $font-size-16px;
        font-weight: 500;
        color: #455B64;
    }

    .green-tick-mark {
        width: 2.5rem;
    }

    .selected-level-bg {
        background: #CDFFF8;
    }

}