@import '../../../../styles/style-variables.scss';

.case-study-start-screen {
    
    .title {
        font-size: $font-size-28px;
        font-weight: 700;
    }

    .image-size {
        width: 900px;
        height: 80px;
    }

    .description-wrapper {
        max-height: 30rem;
        overflow: scroll;
    }

    .bulb-image {
        width: 2rem;
    }

    .info-text {
        font-size: $font-size-17px;
        font-weight: 600;
    }

    .case-study-title {
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .case-study-description {
        font-size: $font-size-16px;
        font-weight: 500;
    }

    .question-number-circle {
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50px;
        border: 1.5px solid #878787;
    }

    .question-number {
        color: #878787;
        font-size: $font-size-24px;
        font-weight: 500;
    }

    .case-study-info-footer {

        position: fixed;
        width: calc(100% + 48px) !important;
        margin-left: -3rem !important;
        bottom: 0;
        right: 0;
        // top : 5rem;
        // left: 0;
        // height: 5rem;

        // bottom: 0;
        // z-index: -1;
        // min-height: 7rem;

        .footer-image-size {
            // position: relative;
            // position: absolute;
            // bottom: 0;
            // right: 0;
            // width: 78.1vw !important;
            width: 100%;
            // object-fit: contain;
            height: 5rem;
            // object-fit: contain;
            // margin-left: -3rem !important;

        }


        .button-wrapper {
            position: relative;
            top: 28px;
            // left: 10rem;
            z-index: 1000;
            right: 3.6rem;
            // bottom: 0;
            // margin-right: calc(100vh - 50px);
        }

        .next-button {
            background: #6B52C8;
            border: 2px solid #6B52C8;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            color: white;
            // padding: 1.1rem 0rem;
            // padding: 10px 31px;
            // padding: 18px 39px;
            // width: 10%;
            padding: 0.5rem 3.5rem;
            font-size: $font-size-20px;
            font-weight: 700;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-top: 1.2rem;
            // position: relative;
            // margin-left: 23rem;
            // top : -5rem;
            // z-index: -1;
            position: relative;

        }


    }



    .sub-header-text {
        font-size: $font-size-20px;
        font-weight: 500;
        color: #353535;
    }

    .choose-complexity {
        font-size: $font-size-20px;
        font-weight: 500;
        color: #455B64;
    }

    .qustion-wrapper {
        padding-top: 24px;
    }

    .complexity {
        font-size: $font-size-18px;
        font-weight: 400;
        color: #455B64;
        cursor: pointer;
    }

    .header-for-practice-question {
        margin-top: -4rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .chapter-icon {
        width: 2.5rem;
        // margin-top: -1rem;
    }

    .chapter-title {
        font-size: $font-size-26px;
        font-weight: 700;
        font-style: 'Mont';
        font-family: 'Mont';
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

}