@import '../../../styles/style-variables.scss';

.select-time-wrapper {

    min-height: 24.5rem;

    .select-time-image {
        width: 33rem;
        min-height: 6.25rem;
    }
    .position {
        position: relative;
    }

    .description-text {
        position: absolute;
        top: 0.5rem;
        right: 1.5rem;
        font-size: $font-size-16px;
        font-weight: 600;
        color: #FFFFFF;
    }
}