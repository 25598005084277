@import'../../styles/style-variables.scss';

.home-component {
    // background: linear-gradient(128deg, #F8FFFA 0%, #FFF 100%);
    // padding: 1rem;

    // height: 50rem !important;

    .student-name {
        font-size: $font-size-26px;
        font-weight: 800;
        color: #263238;
    }

    .welcome-text {
        font-size: $font-size-18px;
        font-weight: 500;
        color: #263238;
    }

    .col-max-width {
        max-width: 20%;
        padding: 0.5rem;
    }

    .upgrade-to-premium {
        border: 1px solid #E98A24;
        background-color: white;
        border-radius: 5px;
        color: #E98A24;
    }

    .badge-icon {
        width: 1.5rem;
    }

    .eclipse-image {
        position: absolute;
        width: 19rem;
        height: 15rem;
        right: 0;
        top: 5.2rem;
        // z-index: 1;
    }

    .header-image-container {
        position: relative;
        z-index: 2;
        width: 100%;
        //  right: 1rem;
        // padding-right: 4rem !important;
        // height: 15.625rem;
        height: 18rem;
        border-radius: 10px;
        border: 1px solid var(--light-50, rgba(69, 91, 100, 0.50));
        background: var(--background-1, #FFF);

        .header-banner {
            // margin-top: 0.1rem !important;
            // padding-top: 1.3rem !important;
            // width: 70rem !important;
            border-radius: 2rem !important;
            width: 100%;
            height: 18rem;
            // object-fit: contain;
        }
    }


    .my-to-do {


        .to-do-text {
            font-size: $font-size-22px;
            font-weight: 800;
            color: #263238;
        }

        .sub-heading {
            font-size: $font-size-14px;
            font-weight: 500;
            color: #263238;
        }

        .homeworks-card {
            // max-width: 70rem;
            gap: 5.5rem;
        }

        .card-max-width {
            min-width: 32.25rem;
            min-height: 12.5rem;
            border-radius: 10px;
        }

        .homework {
            background: #FFF3E8;
        }

        .classwork {
            background: #BEEED3;
        }

        .homework-count-wrapper {
            position: absolute;
            width: 35px;
            height: 35px;
            border-radius: 50px;
            background-color: #0A84FF;
            right: 3.5rem;
            top: -1rem;
            z-index: 11;
        }

        .homework-passed-count-wrapper {
            position: absolute;
            width: 35px;
            height: 35px;
            border-radius: 50px;
            background-color: #FF7C79;
            right: 1.3rem;
            top: -1rem;
            z-index: 11;
        }

        .homework-count {
            color: white;
            margin-top: 0.3rem;
        }

        .right-arrow {
            width: 3rem;
            position: absolute;
            right: 1rem;
            bottom: 1.5rem;
            // top: 5rem;
            z-index: 10;
        }

        .polygon-icon {
            width: 6rem;
            position: absolute;
            right: 0;
            top: 1.4rem;
        }

        .ellipse-icon {
            width: 8rem;
            position: absolute;
            right: 2.5rem;
            top: 0rem;
            z-index: 10;
        }

        .homework-book-icon {
            width: 2.5rem;
        }

        .new-assignment-wrapper {
            border: 1px solid #407BFF;
            background-color: #6E9BFF;
            padding: 0.2rem 0.5rem;
            width: fit-content;
            border-radius: 10px;
            margin-left: 1rem;
        }



        .my-homework-text {
            font-weight: 700;
            font-size: $font-size-20px;
            color: #263238;
        }

        .active-homework-icon {
            width: 1.5rem;
        }

        .new-homework-text {
            color: #FFFFFF;
            font-size: $font-size-15px;
            font-weight: 600;
            margin-top: 0.07rem;
        }

        .panding-assignment-wrapper {
            border: 1px solid #F15B4C;
            background-color: #FFA095;
            padding: 0.2rem 0.5rem;
            width: fit-content;
            border-radius: 10px;
            margin-left: 1rem;
        }

        .pending-homework-text {
            color: #FFFFFF;
            font-size: $font-size-15px;
            font-weight: 600;
        }

        .blurred-card-image {
            // width: 32.25rem;
            width: 100%;
            height: 13.5rem;
        }

        .events-card {
            // max-width: 70rem;
            gap: 5.5rem;
        }

        .event-bg {
            background-color: #E2F1FF;
            color: #FFFFFF;
        }

        .event-disabled {
            width: 100%;
            height: 16rem;
        }

        .course-bg {
            background-color: #FFF8DD;
        }

        // .text-color {
        //     color: #455B64;
        // }

        .know-more-button {
            // color: $white;
            // border-radius: 20px;
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            // // padding: 0.3rem 1.5rem;
            // padding: 10px 60px 9px 60px;
            // font-size: $font-size-15px;
            // font-weight: 700;

            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            color: white;
            padding: 0.65rem 1.5rem;
            // width: 12rem;
            font-size: $font-size-15px;
            font-weight: 600;
            // margin-top: 1.2rem;
            height: 2.6rem;
        }

        .view-answers-button {
            background: #6B52C8;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            color: white;
            padding: 0.65rem 1.5rem;
            // width: 12rem;
            font-size: $font-size-15px;
            font-weight: 600;
            height: 2.6rem;
            // margin-top: 1.2rem;
        }

        .detailed-report-button {
            // background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            color: #FFFFFF !important;
            padding: 0.65rem 1.5rem;
            // width: 15rem;
            font-size: $font-size-15px;
            font-weight: 600;
            // margin-top: 1.2rem;
            border: none;
            background-color: #EC9A3B;
            height: 2.6rem;

        }

    }

    .start-learning {
        .to-do-text {
            font-size: $font-size-22px;
            font-weight: 800;
            color: #263238;
        }

        .view-all-button {
            font-size: $font-size-14px;
            font-weight: 600;
            color: #407BFF;
            padding: 0.5rem 1rem;
            background-color: #E1EBFF;
            border-radius: 8px;
        }

        .sub-heading {
            font-size: $font-size-14px;
            font-weight: 500;
            color: #263238;
        }

        .chapters-list-image {
            // width: 70rem;
            width: 100%;
        }

    }

    .fun-zone-image {
        // width: 70rem;
        width: 100%;
        height: 45rem;
    }

    .home-screen-footer {
        width: calc(100% + 52px);
        height: 15rem;
        // width: cal(100% - 50%);
        // position: absolute;
        // bottom: 0;
        right: 0rem;
        margin-left: -2.6rem;
        // top: 10rem;
        // left: 20rem;
        // object-fit: contain;
    }

    .class-specific-my-to-do {
        .to-do-text {
            font-size: $font-size-22px;
            font-weight: 800;
            color: #263238;
        }

        .sub-heading {
            font-size: $font-size-14px;
            font-weight: 500;
            color: #263238;
        }

        .exam-prep-card {

            // background: #FFFFFF;
            // border-radius: 15px;
            border-radius: 18px;
            background: #DDF;
            box-shadow: 0px 4px 4px 0px rgba(194, 194, 194, 0.25);
            position: relative;
            // height: auto;
            // min-height: 4rem;
            width: 98.5%;

            .case-study-bg {
                width: 6rem;
                position: absolute;
                right: 1.2rem;
                // bottom: 0;
                top: 1.75rem;
                // height: 6rem;
            }

            .divider {
                border: 0.5px solid #C9C9C9;
                width: initial;
                // margin-left: -25px;

            }

            .arrow-size-for-case-study {
                width: 2.9rem;
                position: relative;
                z-index: 99999;
                margin-right: 1.2rem;
            }

            .book-with-apple {
                width: 3rem;
            }

            .heading {
                font-size: 20px;
                font-weight: 600;
            }
        

            .view-more-icon {
                width: 1.2rem;
            }

            .view-more-text {
                color: #009AFF;
                font-size: $font-size-15px;
                font-weight: 600;
            }

            .case-study-item {
                border-radius: 20px;
                background: #FFF;
                width: 100%;
                padding: 1.5rem;
            }

            .case-study {
                border: 1px solid rgb(234, 230, 230);
                border-radius: 10px;
                padding: 1rem;
                padding-left: 0 !important;
            }

            .case-study-title {
                font-size: $font-size-20px;
                font-weight: 700;
                color: #353535;
            }



            // .chapter-activity-arrow {
            //     img {
            //         min-width: 16px;
            //         max-width: 16px;
            //     }
            // }

            .case-study-image {
                width: 40rem;
                margin-left: 0rem;
            }

            .column-max-width {
                max-width: 0.2% !important;
                // margin-top: -0.2rem;
            }

            .sub-heading {
                font-size: $font-size-16px;
                font-weight: 800;
            }

            .sr-no {
                font-weight: 800 !important;
                font-size: 16px !important;
                padding: 0px 0 0px 0px !important;
                margin-bottom: 0px !important;
                // padding-top: -1rem;
            }

            p {
                font-weight: 500 !important;
                font-size: 14px !important;
                padding: 0px 0 0px 0px !important;
                margin-bottom: 0px !important;
                //   display: inline;
                //   align-items: center;
                //   justify-content: space-evenly;
                //   flex-wrap: wrap;
                // display: flex;
                // align-items: flex-end;
                // justify-content: flex-end;
            }

            // img {
            //     min-width: 8rem;

            //     max-width: 50rem;
            // }

            .watch-video-text {
                font-size: 11.5px;
                font-weight: 600;
                color: #FFFFFF;
                align-self: center;
                background-color: #21B8C7;
                border-radius: 8px;
            }

            .learning-resources-text {
                background-color: #1D919C !important;
            }

            .chapter-activity-download {
                img {
                    min-width: 20px !important;
                    max-width: 20px !important;
                }
            }



            .case_study_title {
                font-size: $font-size-16px !important;
                font-weight: 800 !important;
            }


        }


    }


    .select-subject-screen {
        .select-subject-text {
            font-size: 26px;
            font-weight: 700;
        }
    
        .image-size {
            width: 3rem;
        }
    
        .subject-text {
            font-size: 25px;
            font-weight: 600;
        }
    
        .radio-button-wrapper {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50px;
            border: 1px solid darkgray;
    
            .radio-selected {
                width: 1rem;
                height: 1rem;
                border-radius: 50px;
                background-color: darkseagreen;
            }
        }
    
        .subject-card {
            border: 1px solid darkgray;
            border-radius: 8px;
            padding: 1.5rem 2rem;
            max-width: 25rem;
        }
    
        .countingwell-logo-size {
            max-width: 100%;
            object-fit: contain;
            height: 10rem;
        }
    }

}

