@import '../../../styles/style-variables.scss';

.pagination-container {

    .dot-container {

        max-width: 98%;
        overflow-x: scroll;
        margin-left: 0.5rem;


        .index-container {
            width: 2.3rem;
            height: 2.3rem;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .small-index-container {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #C4C4C4;
        }

        .active-index {
            color: white;
            align-self: center;
            font-size: $font-size-18px;
        }

        .inactive-index {
            color: white;
            align-self: center;
            font-size: $font-size-12px;
        }

        .gradient-border-height {
            width: 3rem;
            height: 0.2rem;
            background-color: #C4C4C4;
        }

    }

    div::-webkit-scrollbar {
        display: none;
    }
}