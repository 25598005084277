@import '../../../styles/style-variables.scss';

.learning-content-popup-header {
    .learning-content-header-image-size {
        width: 900px;
        height: 80px;
        position: absolute;
        top: 4.2rem;
        right: 0;
    }

    .pk-label {
        font-size: $font-size-18px;
        font-weight: 700;
        background-color: #D5D4F7;
        padding: 0.5rem 1rem;
        border-radius: 8px;
    }

    .elo-title-text {

        font-size: $font-size-30px;
        font-weight: 700;
    }

    .sub-title-text {
        font-size: $font-size-22px;
        font-weight: 600;
    }

    .divider-image-width {
        width: 100%;
        height: 1.5px;
    }

    .ask-doubt-image-size {
        width: 3rem;
        margin-right: 1rem;
    }

    .prev-knowledge-image {
        width: 3rem;
        margin-right: 1rem;
        margin-top: -0.55rem;
    }

}