@import "../../../styles/style-variables.scss";

.cw-common-custom-modal-center-for-assessmentendpopup {
    font-size: $font-size-12px;
    margin-top: 14rem !important;
    float: center;
    max-width: 37.5rem !important;
    // height: 28.125rem !important;

    div::-webkit-scrollbar {
        display: none;
    }

    .flagged-header {
        margin-top: -0.5rem;
    }

    .modal-content {
        padding: 1rem;
        background: #F8FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        height: 28rem !important;
    }

    .flag-size {
        width: 3rem;
    }

    .challenge-header {
        width: 600px;
        height: 290px;
        position: absolute;
        left: 0rem;
        top: 0rem;
        border-radius: 20px;
    }

    .challenge-footer {
        width: 600px;
        height: 70px;
        position: absolute;
        left: -1rem;
        // top: -1.6rem;
        bottom: -1rem;
        border-radius: 20px;
        z-index: 1;
    }

    .badge-image {
        width: 3.7rem;
        position: absolute;
        left: 17rem;
        top: 0.5rem;
    }

    .time-limit-image {
        width: 5.5rem;
        position: absolute;
        left: 17rem;
        top: 0rem;
    }

    .position {
        position: relative;
    }

    .header-text {
        margin-top: -0.2rem !important;
        // text-align: center;
        font-size: $font-size-20px;
        font-weight: 700;
    }

    .time-up-header-text {
        margin-top: 1.5rem !important;
         // text-align: center;
         font-size: $font-size-20px;
         font-weight: 700;
    }

    .close-icon {
        width: 28px;
        float: right;
        position: relative;
        top: -1.6rem;
        right: 0.5rem;
    }

    .clock_image_size {
        width: 3rem;
    }

    .timer-text {
        font-size: $font-size-19px;
        font-weight: 700;
    }

    .min-text {
        font-size: $font-size-19px;
        font-weight: 700;
        color: #455B6480;
    }

    .flagged-questions-list {
        background: #FFFFFF;
        border: 1px solid #E4E4E4;
        border-radius: 10px;
        min-height: 10rem;
        width: 30rem;
        position: relative;
        

        .flagged-questions {
            width: 25rem;
            
            overflow: scroll;
            flex-wrap: wrap;
            gap: 0.7rem;
        }

        .fagged-card-hight {
            max-height: 4.5rem;
        }

        .flag-circle {
            width: 27px;
            height: 27px;
            border: 1px solid #FF9501;
            border-radius: 50px;
        }
    }

    .flag-image-size {
        width: 2rem;
    }

    .review-answers-image {
        width: 9rem;
    }

    .sub-text {
        font-size: $font-size-17px;
        font-weight: 700;
         margin-left: 0.5rem;
    }

    .header-sub-text {
        font-size: $font-size-17px;
        font-weight: 500;
        color: #455B64;
         margin-left: 0.5rem;
    }

    .flagged-card-footer {
        width: 30rem;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 10px;
    }

    .image-width {
        width: 180px;
    }

    .name-header {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-16px;
        //   line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .challege-yourself {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 800;
        font-size: $font-size-18px;
        line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .take-challenge {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: $font-size-17px;
        color: #FFFFFF;

        height: 45px;
        padding-top: 0.7rem;
        background: #6B52C8;
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25) !important;
        border-radius: 40px;
        position: relative;
        //   top: 1rem;
        z-index: 10;
        width: 18.75rem;
    }

    .review-answers {
        width: 200px !important;
    }

    // .button-wrapper {
    //     margin-top: 12.7rem;
    // }

    .continue-button {
        width: 200px;
        height: 45px;
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        // border: 2px solid transparent;
        position: relative;
        z-index: 10;
        padding-top: 0.5rem;
        display: inline-block;
        font-size: $font-size-18px;
        font-weight: 600;
        color: #6B52C8;
        background: #FFFFFF !important;
        // margin-top: 1rem;
        border: 1px solid #6B52C8;

    }

    // .continue-button::before {
    //     content: "";
    //     width: 200px;
    //     height: 45px;
    //     position: absolute;
    //     inset: 0;
    //     border-radius: 30px;


    //     padding: 2px;
    //     /* control the border thickness */
    //     background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
    //     -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    //     -webkit-mask-composite: xor;
    //     mask-composite: exclude;
    //     pointer-events: none;
    // }

    .pratice-container {
        margin-top: -4.5rem;
    }

    .margin-left {
        margin-left: 5.5rem;
        margin-bottom: 1rem;
        font-weight: 800;
        font-size: 1rem;
    }

    .timeup-image {
        width: 20rem;
    }
}