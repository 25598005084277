@import '../../../styles/style-variables.scss';
.check-point-questions {
    .submit-button {
        background-color: #6f42c1;
        border-radius: 50px;
        color: #FFFFFF;
        padding: 0.7rem 3rem;
        font-size: $font-size-16px;
        font-weight: 600;
        margin-bottom: 2rem;
        margin-top: -3rem;
        cursor: pointer;
    }
    .notes-wrapper {
        border: 2px solid #C7B8FF;
        border-radius: 20px;
        margin-bottom: 2rem;
        padding-left: 1.5rem;
        padding-top: 1.2rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
        // background: linear-gradient(180deg, #FFFFFF 0%, #D1C9ED 100%);
    }

    .notes-header-text {
        font-size: $font-size-22px;
        font-weight: 700;
    }

    .notes-image-size {
        position: absolute;
        top: -1.2rem;
        right: 0;
        width: 5rem;
        border-top-right-radius: 20px;
    }
}