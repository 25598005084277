@import '../../../styles/style-variables.scss';

.cw-custom-modal-for-image-preview-v1 {
    margin: auto !important;
    // margin-top: 12rem !important;
    // width: 100% !important;
    // min-height: 10rem !important;


    .modal-content {
        width: 100% !important;
        // min-height: 15rem;
    }

    .modal-body {
        width: 100%;
    }

    .image-icon-wrapper {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }

    .theme-close-icon {
        width: 1.5rem;
    }

    .image-size-for-preview {
        max-width: 100% !important;
        height: 35rem !important;
        // object-fit: contain !important;
    }

    .play-again-button-wrapper {
        background: #dfe3e7;
        width: 100%;
        padding: 0.5rem;
        border-radius: 10px;
        // border-top-left-radius: 0px !important;
        // border-top-right-radius: 0px !important;
    }

    .reload-image-size {
        width: 1.5rem;
    }

    .play-again-text {
        font-size: $font-size-18px;
        font-weight: 500;
    }


}