@import '../../../styles/style-variables.scss';
.diagnostic-assessment-list {

    .title {
        font-size: $font-size-28px;
        font-weight: 700;
    }

    .image-size {
        width: 900px;
        height: 80px;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .header-title-margin-top {
        margin-top: -5rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,.title-2:hover,.title-3:hover,.title-4:hover, .home:hover {
        color: black;
        font-weight: 800;
    }

    .exam-prep-card {

        // background: #FFFFFF;
        // border-radius: 15px;
        border-radius: 18px;
        background: #DDF;
        box-shadow: 0px 4px 4px 0px rgba(194, 194, 194, 0.25);
        position: relative;
        // height: auto;
        // min-height: 4rem;
        width: 98.5%;

        .case-study-bg {
            width: 6rem;
            position: absolute;
            right: 1.2rem;
            // bottom: 0;
            top: 1.75rem;
            // height: 6rem;
        }

        .divider {
            border: 0.5px solid #C9C9C9;
            width: initial;
            // margin-left: -25px;

        }

        .arrow-size-for-case-study {
            width: 2.9rem;
            position: relative;
            z-index: 99999;
            margin-right: 1.2rem;
        }

        .book-with-apple {
            width: 3rem;
        }

        .heading {
            font-size: 20px;
            font-weight: 600;
        }
    

        .view-more-icon {
            width: 1.2rem;
        }

        .view-more-text {
            color: #009AFF;
            font-size: $font-size-15px;
            font-weight: 600;
        }

        .case-study-item {
            border-radius: 20px;
            background: #FFF;
            width: 100%;
            padding: 1.5rem;
        }

        .case-study {
            border: 1px solid rgb(234, 230, 230);
            border-radius: 10px;
            padding: 1rem;
            padding-left: 0 !important;
        }

        .case-study-title {
            font-size: $font-size-20px;
            font-weight: 700;
            color: #353535;
        }



        // .chapter-activity-arrow {
        //     img {
        //         min-width: 16px;
        //         max-width: 16px;
        //     }
        // }

        .case-study-image {
            width: 40rem;
            margin-left: 0rem;
        }

        .column-max-width {
            max-width: 0.2% !important;
            // margin-top: -0.2rem;
        }

        .sub-heading {
            font-size: $font-size-16px;
            font-weight: 800;
        }

        .sr-no {
            font-weight: 800 !important;
            font-size: 16px !important;
            padding: 0px 0 0px 0px !important;
            margin-bottom: 0px !important;
            // padding-top: -1rem;
        }

        p {
            font-weight: 500 !important;
            font-size: 14px !important;
            padding: 0px 0 0px 0px !important;
            margin-bottom: 0px !important;
            //   display: inline;
            //   align-items: center;
            //   justify-content: space-evenly;
            //   flex-wrap: wrap;
            // display: flex;
            // align-items: flex-end;
            // justify-content: flex-end;
        }

        // img {
        //     min-width: 8rem;

        //     max-width: 50rem;
        // }

        .watch-video-text {
            font-size: 11.5px;
            font-weight: 600;
            color: #FFFFFF;
            align-self: center;
            background-color: #21B8C7;
            border-radius: 8px;
        }

        .learning-resources-text {
            background-color: #1D919C !important;
        }

        .chapter-activity-download {
            img {
                min-width: 20px !important;
                max-width: 20px !important;
            }
        }



        .case_study_title {
            font-size: $font-size-16px !important;
            font-weight: 800 !important;
        }


    }
}