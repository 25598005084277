@import "../../../styles/style-variables.scss";

.cw-header {
  height: 5rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
   background: #F8FAFC !important;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

  .bell-icon {
    height: 1.5rem;
    width: 1.2rem;
  }

  .user-name-icon-size {
    width: 1.8rem;
  }

  .user-name-text {
    font-size: $font-size-17px;
    font-weight: 700;
  }

  .logout-button-wrapper {
    border: 1.5px solid #FF0000;
    border-radius: 20px;
    font-size: $font-size-16px;
    font-weight: 700;
    color: #FF0000;
    background-color: #FFF3F3;
    padding: 0.1rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  }

  .logout-text {
    margin-right: 10px;
    padding-top: 1.8px;
  }

  .logout-icon {
    height: 2.2rem;
  }

  .home-icon {
    width: 2.5rem;
  }

  .school-container {
    padding-right: 2.6rem;
  }

  .reward-wrapper {
    display: flex !important;
    align-items: center;
    color: black;

    .reward-text {
      font-weight: 600;
    }

    .reward-point {
      font-weight: 900;
      font-size: $font-size-20px;
    }
  }

  .school-name {
    font-weight: bold;
    font-size: $font-size-15px;
  }

  .school-address {
    font-size: $font-size-11px;
  }

  .logo-container {
    display: flex;
    align-items: center;
    padding-left: 2rem !important;
  }

  .header-right-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 3rem !important;
  }

  .logo {
    height: 2.5rem;
  }

  .text {
    margin: 0.2rem;
    font-size: 0.7rem;
    letter-spacing: 0.01;
    color: black;
    cursor: pointer;
    padding-right: 0.5rem;
  }

  .text-icon {
    height: 2.2rem;
    cursor: pointer;
  }

  .header-bg {
    background: $red !important;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
  }

  .logout-text {
    font-size: $font-size-15px;
  }
}


