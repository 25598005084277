@import '../../../styles/style-variables.scss';

.select-days-wrapper {
    // margin-left: 2.2rem;
    min-height: 24.5rem;

    .select-days-image {
        width: 10rem;
        // background-color: antiquewhite;
        // background: red;
    }

   

}

.days-text {
    font-size: $font-size-26px;
    font-weight: 800;
}

.week-text {
    font-size: $font-size-16px;
    font-weight: 500;
}