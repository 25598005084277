@import '../../../styles/style-variables.scss';

.str-questionset-list {
    .title {
        font-size: $font-size-28px;
        font-weight: 700;
    }

    .image-size {
        width: 900px;
        height: 80px;
    }

    .question-divider-image-width {
        width: 100%;
        object-fit: contain;
        height: 0.12rem;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .total-questions-text {
        font-size: $font-size-16px;
        font-weight: 500;
    }

    .competency-questions-text {
        font-size: $font-size-15px;
        font-weight: 400 !important;
    }

    .descriptive-text {
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .header-title-margin-top {
        margin-top: -4rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .question-set {
        width: 98%;
    }

    .question-border-bottom {
        border-bottom: 1px solid #E5E5E5;
    }

    .start-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.4rem 0rem !important;
        width: 8rem !important;
        font-size: $font-size-16px;
        font-weight: 500;
        // margin-top: 1.2rem;
    }

    .learning-gap-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.4rem 0rem !important;
        width: 13.5rem !important;
        font-size: $font-size-16px;
        font-weight: 500;
        // margin-top: 1.2rem;
    }

    .insights-button {
        // background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // border-radius: 30px;
        // color: white;
        // padding: 0.7rem 0rem;
        width: 8rem;
        font-size: $font-size-16px;
        font-weight: 500;
        // margin-top: 1.2rem;

        // width: 13.75rem;
        // height: 3rem;
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        border: 1px solid transparent;
        position: relative;
        padding-top: 0.32rem;
        padding-bottom: 0.32rem;
        display: inline-block;
        font-size: $font-size-18px;
        // font-weight: 600;
        color: #69C7C1;
        //  margin-top: 0.5rem;

    }


    .insights-button::before {
        content: "";
        width: 8rem;
        height: 2.4rem;
        position: absolute;
        inset: 0;
        border-radius: 30px;
        padding: 2px;
        /* control the border thickness */
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }

    .clock-icon-size {
        width: 1rem;
    }

    .set-text {
        font-size: $font-size-18px;
        font-weight: 700;
    }

    .approximate-text {
        color: #878787;
        font-size: $font-size-16px;
        font-weight: 500;
    }

}