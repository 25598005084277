@import '../../../styles/style-variables.scss';

.clicker-assessment-end-screen {
    // margin-left: 6rem;
    // width: 95%;

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .header-text {
        font-size: $font-size-32px;
        font-weight: 700;
    }

    .image-wrapper {
        gap: 1rem;
    }

    .sub-header-text {
        font-size: $font-size-32px;
        font-weight: 700;
    }

    .tick-mark-image-size {
        width: 2rem;
    }

    .percentage-text-symblol {
        font-size: $font-size-26px;
        margin-left: 4rem;
    }

    .student-count-image {
        width: 2rem;
    }

    .question-wrapper {
        // box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        padding: 3rem;
        // border-radius: 20px;
        padding-bottom: 0 !important;
        padding-top: 0.3rem !important;
    }

    .question-divider-image-width {
        max-width: 100%;
        object-fit: contain;
        height: 0.35rem;
      }

    .question-number-text {
        font-size: $font-size-32px;
        font-weight: 600;
    }

    .correct-count-text {
        font-size: $font-size-32px;
        font-weight: 700;
    }

    .divider {
        font-size: $font-size-32px;
        font-weight: 700;
        // background-color: darkcyan;
        color: darkcyan;
    }

    .question-number-circle {
        width: 5rem;
        height: 30px;
        justify-content: center;
        align-items: center;
        display: flex;
        // border: 1px solid black;
        border-radius: 50px;
        margin-right: 0.5rem;
        padding: 1.5rem 2.8rem;
        margin-top: 0.2rem;
        background-color: #007bff;
        color: white;
    }

    .question-number {
        font-size: $font-size-32px;
        font-weight: 500;
    }

    .column-max-width {
        max-width: 25%;
        margin-right: 1rem;
    }

    .sub-column-width {
        max-width: 10%;
    }

    .second-column-wrapper {
        margin-left: 9.5rem;
    }

   

    .right-border {
        border-right: 1px solid lightgray;
        padding-right: 2.2rem;
        // width: -10rem;
    }

    .info-wrapper {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid lightgray;
        // max-width: 40%;
    }


    .second-wraper {
        margin-left: 10rem;
    }

}