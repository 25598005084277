@import '../../../styles/style-variables.scss';

.otp-input-box {

    position: relative;

    .passcode-boxes {
        input {
            height: 3.2rem;
            width: 4.3rem;
            font-size: $font-size-22px;
            text-align: center;
            background: #F6F7F5;
            // border: 1px solid #C4C4C4;
            box-sizing: border-box;
            // border-radius: 5px;
            margin: 12px;
            border: 1px solid rgba(69, 91, 100, 0.5);
            border-radius: 10px;

        }

        .input-one-margin-left {
            margin-left: 0 !important;
        }

        .cw-input-label-1 {
            font-size: $font-size-16px;
            font-weight: 600;
        }

    }

    .forgot-passcode {
        margin-left: 0.9rem;
        font-size: $font-size-15px;
        color: #11BFD7;
        font-weight: 600;
    }


    .resend-otp {
        font-size: $font-size-14px;
        font-weight: 500;
        color: #455B64;
        margin-left: 16.5rem;
    }

    .otp-seconds {
        margin-left: 14.4rem !important;
    }


}