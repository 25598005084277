@import '../../../../../styles/style-variables.scss';

.multi-select {
    .arrow-size {
        width: 1.8rem;
    }

    .expand-icon {
        width: 1.6rem;
    }

    .view-answer-button {
        border-radius: 50px;
        border: 2px solid #6B52C8;
        background: #FFF;
        padding: 0.6rem;
        width: 18rem;
        text-align: center;
        font-size: $font-size-19px;
        font-weight: 500;
        color: #6B52C8;
    }
    // .view-answer-button {
    //     border-radius: 10px;
    //     // border: 2px solid #6B52C8;
    //     background: #FFF;
    //     padding: 0.6rem 4rem;
    //     // width: 15rem;
    //     text-align: center;
    //     font-size: $font-size-22px;
    //     font-weight: 600;
    //     color: #6B52C8;
    // }
}