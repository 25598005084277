@import '../../../styles/style-variables.scss';

.view-detailed-report {
    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        // margin-top: -4rem;
        // position: absolute;
        top: 5.5rem;
        background: transparent !important;
        padding: 0 !important;
        // z-index: 12;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .divider-image-width {
        // width: 1160px;
        width: 99%;
        height: 1.5px;
    }

    .title {
        font-size: $font-size-20px;
        font-weight: 800;
        font-style: 'Mont';
        font-family: 'Mont';
    }

    .event-image {
        width: 11rem;
    }

    .performance-image {
        width: 20rem;
    }

    .marks-card {
        width: 37.5rem;
        min-height: 16rem;
        max-height: 16rem;
        // background: #FFFFFF;
        /* Theme colours/TC 1 Purple */

        border: 1px solid #69C7C1;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        position: relative;
        z-index: 99999;
    }

    .marks-card-bg {
        position: absolute;
        width: 37.5rem;
        min-height: 16rem;
    }

    .vertical-line {
        width: 0.25rem;
        height: 20rem;
    }

    .assessment-end-screen-footer {
        width: 75.9rem;
        height: 7rem;
        position: absolute;
        bottom: 0;
        left: 20rem;
    }

    .mark-scored {
        font-size: $font-size-24px;
        font-weight: 500;
        color: $sub-text-color;
    }

    .time-taken {
        font-size: $font-size-24px;
        font-weight: 700;
        color: $sub-text-color;
    }

    .exam-pad-icon {
        width: 1.3rem;
        margin-top: -0.2rem;
        margin-right: 0.5rem;
    }

    .mark-scored-text {
        font-size: $font-size-19px;
        font-weight: 600;
        color: rgba(69, 91, 100, 0.5);
        ;
    }

    .correct-bg {
        background: #CBFDF5;
        border-radius: 20px;
        font-size: $font-size-16px;
        font-weight: 600;
        color: $text-black-color;

    }

    .incorrect-bg {
        background: #F7DBDC;
        border-radius: 20px;
        font-size: $font-size-16px;
        font-weight: 600;
        color: $text-black-color;
    }

    .not-answered-bg {
        background: #EDEDED;
        border-radius: 20px;
        font-size: $font-size-16px;
        font-weight: 600;
        color: $text-black-color;
    }

    .greetings-card {
        border-radius: 20px;
        border: 1px solid #69C7C1;
        background: $white;
        padding: 1rem;
        max-width: 98%;
    }

    .greetings-text {
        font-size: $font-size-13px;
    }

    .performance-card-wrapper {
        gap: 1.5rem;
    }

    .performance-card {
        width: 31.25rem;
        min-height: 14.625rem;
        border-radius: 20px;
        
        background: #FFF;
    }

    .failed-skill-border {
        border: 1px solid #FF7C79;
    }

    .passed-skill-border {
        border: 1px solid #85CB9E;
    }

    .card-header-image {
        border-radius: 20px;
        // height: 6.05rem;
        max-width: 100%;
        object-fit: contain;
    }

    .skill-image {
        width: 3rem;
    }

    .skill-text {
        font-size: $font-size-19px;
        font-weight: 700;
    }

    .skill-title {
        font-size: $font-size-16px;
        font-weight: 700;
        position: relative;
        z-index: 10;
    }

    .skill-message {
        margin-top: 4.5rem;
    }

    .button-wrapper {
        gap: 5rem;
    }

    .button-text {
        font-size: $font-size-15px;
        font-weight: 500;
        border-radius: 30px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.8rem 1.5rem;
        color: $white;
        min-width: 15rem !important;
        cursor: pointer;
    }

    .download-pdf-button {
        background-color: #85CB9E;
    }

    .share-button {
        background-color: #4E79F6;
    }

    .download-icon {
        width: 1rem;
    }

    .link-tag {
        color: $white;
        text-decoration: none;
    }



}