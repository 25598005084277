@import '../../styles/style-variables.scss';

.my-assessment-result {

    .image-size {
        position: absolute;
        width: 900px;
        height: 80px;
        top :5rem;
        left: 39.75rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        // margin-top: -4rem;
        // position: absolute;
        top: 5.5rem;
        background: transparent !important;
        padding: 0 !important;
        // z-index: 12;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .header-text {
        font-size: $font-size-32px;
        font-weight: 800;
        color: #263238;
    }

    .row-max-width {
        width: 58rem;
        background: #FFFFFF;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        height: 64px;
    }

    .answer-status-text {
        font-size: $font-size-22px;
        font-weight: 500;
        color: #455B64;
        cursor: pointer;
    }

    .border-top-left-radius {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .border-top-right-radius {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .verticleLine {
        width: 0.5px !important;
        background-color: #909090;
         height: inherit;
      }

    .divider-image-width {
        // width: 1170px;
        width: 98.6%;
        height: 1.5px;
    }

    .question-info {
        margin-left: -2rem;
    }

    .tick-icon {
        width: 1.8rem;
    }

    .flag-icon {
        width: 2rem;
    }

    .exam-pad-icon {
        width: 1.4rem;
        padding-bottom: 0.35rem;
        margin-right: 0.4rem;
    }

    .header-wrapper {
        margin-left: -2rem;
    }

    .question-number-wrapper {
        // margin-right: 6rem;
        
        font-size: $font-size-19px;
        font-weight: 600;
        // width: 20px !important;
        // height: 20px !important;
        // flex-basis: 5%;
    }

    .question-number-text {
            color: white !important;
            background: #6B52C8;
            border-radius: 50px !important;
            width: 40px;
            height: 40px;
            padding-top: 0.35rem;
            
    }

    .question-status-text {
        font-size: $font-size-19px;
        font-weight: 600;

    }

    .question-not-attempted {
        color: #455B6480;
    }

    .question-correct {
        color: #85CB9E;
    }

    .question-incorrect {
        color: #FF7C79;
    }

    .marks {
        font-size: $font-size-20px;
        font-weight: 500;
        color:$text-black-color;
    }

    .content-separator-size {
        // width: 73rem;
        width: 100%;
    }

    .done-button {
        border-radius: 50px;
        border: 2px solid #6B52C8;
        // background: #FFF;
        padding: 0.6rem;
        width: 10rem;
        text-align: center;
        font-size: $font-size-19px;
        font-weight: 500;
        color: #FFFFFF;
        background-color: #6B52C8;
    }


}