@import '../../../styles/style-variables.scss';

.header-image-wrapper {
    .header-bg-image-size {
        width: calc(100vw - 226px);
        //width: calc(100vw - 72px);
        height: 6rem;
        margin-left: -3rem;
    }

    .change-image-width {
        width: calc(100vw - 87px);
    }
}

.assessment-pagination {

    margin-top: 1.4rem;

    



    .pagination-wrapper {
        width: 65rem;
        overflow-x: scroll;
        gap: 3.68rem;
        // flex-grow: ;

        .question-number {
            // margin-right: 6rem;
            
            font-size: $font-size-24px;
            font-weight: 600;
            width: 50px;
            height: 50px;
        }

        .active-page-circle {
            color: white !important;
            background: #6B52C8;
            border-radius: 50px;
        }

        .flagged-circle {
            border: 2px solid #E98A24;
            color: #263238;
            border-radius: 50px;
        }

        .answered-color {
            color: #263238;
        }

        .not-answered-color {
            color: rgba(69, 91, 100, 0.5);
        }


    }

    .arrow-size {
        width: 0.9rem;
    }



    div::-webkit-scrollbar {
        display: none;
    }
}