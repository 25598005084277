@import '../../../styles/style-variables.scss';

.bar-wrapper {
  // border: 1px solid gray;
  width: 50%;
  // padding: 1rem 2rem;
  // border-radius: 20px;

  .question-score-text {
    font-size: $font-size-32px;
    font-weight: 700;
  }

  .chart-container {
    width: 85%;
    margin: 2rem 6rem;
    text-align: center;
    position: relative;
    /* To position y-axis label */

    h2 {
      margin-bottom: 20px;
      font-size: 24px;
    }

    .bar-chart {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      height: 40vh;
      // background-color: #f9f9f9;
      // border: 1px solid #ccc;
      padding: 20px;
      margin-top: 20px;
      position: relative;
      /* To place axis lines */
      padding-bottom: 0px !important;
    }

    /* X-axis label */
    .x-axis-label {
      margin-top: 10px;
      font-size: 14px;
    }

    /* Y-axis label */
    .y-axis {
      position: absolute;
      left: -70px;
      // top: 0;
      top: 0px;
      height: 40vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // padding-bottom: 20px;
    }

    .y-axis-label {
      font-size: $font-size-24px;
      // text-align: right;
      padding-right: 10px;
      // margin-bottom: 6px;
      font-weight: 600;
    }

    .y-axis-label:nth-of-type(1) {
      padding-top: 0;
    }

    .y-axis-label:nth-of-type(6) {
      padding-bottom: 0;
    }

    .y-axis-label {
      flex: 1;
    }

    /* Bar styling */
    .bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 70px;
      height: 100%;
      position: relative;
    }

    .bar-inner {
      width: 100%;
      // background-color: rgba(75, 192, 192, 0.6);
      // border: 1px solid rgba(75, 192, 192, 1);
      transition: height 0.3s ease;
      height: 0;
      position: relative;
    }

    .bar-percentage {
      position: absolute;
      top: -50px;
      /* Position the percentage above the bar */
      font-size: $font-size-30px;
      color: #000;
      right: 0px;
      font-weight: 600;
    }


    .label {
      margin-top: 10px;
      font-size: $font-size-28px;
      font-weight: 600;
    }

    .x-axis-header {
      // margin-top: 10px;
      font-size: $font-size-30px;
      font-weight: 600;
      margin-top: -1.5rem;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 40%;
        left: 62%;
        width: 10%; // Full width of the x-axis
        height: 0; // No vertical height since it's a horizontal line
        border-bottom: 2px solid black; // Line along the x-axis
      }
    
      // Arrow on the right end of x-axis
      &::after {
        content: '';
        // width: 60%;
        position: absolute;
        bottom: 32%;
        left: 72%;
        margin-bottom: -5px; // Adjust positioning for arrow alignment
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 32px solid black; // Arrowhead pointing to the right
      }
    }

    /* Styling for the x-axis and y-axis */
    .bar-chart {
      border-left: 2px dashed #000;
      /* y-axis */
      border-bottom: 2px solid #000;
      /* x-axis */
      position: relative;
    }

    .bar {
      position: relative;
    }

    .bar-inner {
      border-radius: 5px;
    }

    .y-axis-header {
      transform: rotate(-90deg);
      transform-origin: left bottom;
      font-size: $font-size-30px;
      font-weight: 600;
      // margin-right: 10rem;
      margin-left: -4.5rem;
      // margin-bottom: 5rem;
      width: 71%;

      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 79%;
        width: 0;
        height: 100%;
        border-left: 2px solid black;
        transform: rotate(90deg);
      }
    
      // Arrow on top of y-axis
      &::after {
        content: "";
        position: absolute;
        top: 9px;
        left: 86%;
        margin-left: -5px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 32px solid black;
        transform: rotate(90deg);
      }
    }

  }
}