@import '../../../styles/style-variables.scss';

.start-assessment-for-clicker-practice {
    .image-size {
        width: 40rem;
        // height: 80px;
    }

    .sub-header-text {
        font-size: $font-size-32px;
        font-weight: 600;
    }

    .desktop-image-size {
        width: 15rem;
    }

    .chapter-card {
        width: 97%;
        border-bottom: 1px solid #f7ebeb;
        // background-color: rgb(246, 245, 245);
    }

    .arrow-image-size {
        width: 3rem;
    }

    .mock-test-header {
        font-size: $font-size-20px;
        font-weight: 700;
    }


    .header-for-practice-question {
        margin-top: -3.7rem;
    }

    // .breadcrumb {
    //     font-size: $font-size-16px;
    //     color: #212529;
    //     font-weight: 600;

    //     background: transparent !important;
    //     padding: 0 !important;
    //     margin-bottom: 0 !important;

    //     .active {
    //         color: #1C1C1C;
    //     }
    // }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;
        
        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,.title-2:hover,.title-3:hover,.title-4:hover, .home:hover {
        color: black;
        font-weight: 800;
    }
    .arrow-size {
        width: 0.6rem;
        margin-bottom: 0.2rem;
    }

    

    


    .chapter-title {
        font-size: $font-size-32px;
        font-weight: 600;
        font-style: 'Mont';
        font-family: 'Mont';
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .vertical-line {
        width: 0.15rem;
        height: 3.4rem;
        // margin-left: 2rem;
    }

    .clock-image-size {
        width: 1rem;
    }

    .min-text {
        font-size: $font-size-22px;
        font-weight: 700;
        margin-top: 1.5px;
    }

    .duration-text {
        font-size: $font-size-20px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
    }


    .assessment-detail-card {
        width: 33rem;
        min-height: 6.5rem;
        background: #F8FAFC;
        border: 2px solid rgba(69, 91, 100, 0.2);
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
    }

    .pro-tips {

        background: rgba(69, 91, 100, 0.05);
        border-radius: 10px;
        max-width: 73rem;

        .bulb-image-size {
            width: 2rem;
        }

        .pro-title {
            font-size: $font-size-18px;
            font-weight: 600;
        }

        .hide-list {
            display: none;
        }

        #show-more {
            width: 8rem;
            height: 1.5rem;
            background: #F8FAFC;
            /* Text 1 */

            border: 1px solid #263238;
            border-radius: 30px;
            // padding: 0.1rem;
            text-align: center;
            font-size: $font-size-14px;
            font-weight: 600;
        }
    }

    .take-challenge {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: $font-size-17px;
        color: #FFFFFF;
        width: 150px;
        height: 45px;
        padding-top: 0.6rem;
        background: linear-gradient(90deg, #0AC6D6 0%, #5CD492 100%);
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25) !important;
        border-radius: 40px;
        position: relative;
        //   top: 1rem;
        z-index: 10;
    }

    .continue-button {
        background: #6B52C8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.7rem 0rem;
        width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
    }

    .back-button {
        background: #FFFFFF;
        /* Theme colours/TC 1 Purple */

        border: 2px solid #6B52C8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: #6B52C8;
        padding: 0.7rem 0rem;
        width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
    }

    .tips-text {
        color: #455B64;
        font-size: $font-size-17px;
        font-weight: 500;
    }

   







    .question-divider-image-width {
        width: 70rem;
        height: 0.12rem;
    }

    .react-image-width {
        width: 18px;
        margin-left: -0.5rem;
    }

    .selected-complexity-bg-color {
        background-color: #6B52C8;
        color: #FFFFFF;
    }

    .row-max-width {
        max-width: 718px;
        background: #FFFFFF;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        height: 58px;
    }

    .border-top-left-radius {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .border-top-right-radius {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .selected-question-bg {
        background: rgba(107, 82, 200, 0.1);
    }

    .react-box {
        max-width: 18px;
        // height: 15px;
        border: 2px solid #263238;
        margin-left: 2.8rem;
    }

    .practice-wrapper {
        width: 50rem;
    }

    .rect-active-bg {
        background: #6B52C8 !important;
    }

    .practiced-question {
        color: #455B64 !important;
        font-size: $font-size-19px !important;
    }

}