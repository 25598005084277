@import'../../../../styles//style-variables.scss';

.single-select-for-practice-flow {
  div::-webkit-scrollbar {
    display: none;
  }

  // .blur-wrapper {
  //   position: absolute;
  //   z-index: 99999999;
  //   display: flex;
  //   justify-content: center;
  // }

  .blur-image-size {
    max-width: 100%;
    object-fit: contain;
    height: auto;
  }

  .question-image-size {
    max-width: 100%;
    object-fit: contain;
    height: 45rem;
    margin-left: 0.5rem;
    // width: 95%;
  }

  .question-image-size-for-bottom-image {
    max-width: 100%;
    object-fit: contain;
    height: 36rem;
    margin-left: 0.5rem;
  }

  .student-response-toggle-button {
    position: absolute;
    z-index: 99999;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background-color: #304096;
    top: calc(100vh / 2);
    left: -1.8rem;
    // right: -0.95rem;

    .arrow-image-size {
      width: 1.5rem;
    }

  }



  .single-select-option-min-height {
    // min-height: 33rem;
  }

  .single-select-option-min-height-2 {
    // min-height: 24rem;
  }

  .option-margin-bottom-new {
    margin-left: -1.5rem;
    margin-top: -2rem;
  }

  .option-margin {
    margin-left: 6rem;
  }

  .student-answered-board {
    position: fixed;
    left: 0rem;
    top: 5rem;
    z-index: 9999999999;
    // color: white;
  }

  .image-size {
    width: 1.6rem;
  }

  .question-image-wrapper {
    margin-left: 0rem;
    width: 100%;
    overflow: scroll;
    // flex-wrap: wrap;
  }

  .solution-answer-border {
    border: 1px solid #B1B1B1;
    // height: 1rem;
  }

  .correct-answer-bg {
    background-color: #0D5F07;
    color: #FFFFFF;
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
  }

  .incorrect-answer-bg {
    background-color: #8B0300;
    color: #FFFFFF;
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
  }

  .view-answer-button-for-solution {
    border-radius: 10px;
    // border: 2px solid #6B52C8;
    background: #FFF;
    padding: 0.6rem 4rem;
    // width: 15rem;
    text-align: center;
    font-size: $font-size-22px;
    font-weight: 600;
    color: #6B52C8;
  }

  .name-header {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-20px;
    line-height: 27px;
    // text-align: center;
    // color: #263238;
  }

  .name-header-correct {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-20px;
    line-height: 27px;
    // text-align: center;
    // color: #263238;
  }

  .view-button-for-solution {
    font-size: $font-size-22px;
    font-weight: 700;
    color: #455B64;
    font-family: 'Mont';
    font-style: normal;
    border-radius: 10px;
    padding-top: 0 !important;
  }

  .row-width-for-solution {
    width: 99%;
    // max-width: inherit;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: auto !important;
    padding: 0.7rem;
    margin-left: -0.3rem;
    border: 1.5px solid #C5B6FF;

  }


  .question-separator {

    // .divider-wrapper {
    //     max-width: 67rem;
    // }

    .question-divider-image-width {
      width: 60rem;
      height: 0.12rem;
    }

    .marks-image-size {
      width: 1.5rem;
      margin-right: 0.3rem;
    }

    .marks-container {
      border: 2.45238px solid #A99ADF;
      width: 60px;
      height: 60px;
      border-radius: 50px;
      // padding: 2rem;
    }

    .mark-text {
      font-size: $font-size-32px;
    }

  }

  .timer-wrapper-in-question-screen {
    position: fixed;
    bottom: 1.2rem;
    right: 1.2rem;

    // z-index: -1;
    .timer-text {
      font-size: $font-size-32px;
    }

    .pause-button-image-size {
      width: 1.4rem;
    }
  }

  .active-tab {
    background-color: #6B52C8;
    color: white !important;
    // border-top-left-radius: 10px;
    // border-bottom-left-radius: 10px;
    border-radius: 50px;
    padding: 0.5rem 3rem !important;
    font-size: $font-size-28px;
  }

  .active-my-answer-tab {
    background-color: #6B52C8;
    color: white !important;
    border-radius: 50px;
    padding: 0.5rem 3rem !important;
  }

  .elo-title {
    font-size: 38px;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2px;
    font-style: italic;
    margin-top: 5rem;
  }

  .learning-outcome-text {
    font-size: 38px;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2px;
    margin-top: 5rem;
    // font-style: italic;
  }

  .summary-solution-wrapper {
    span {
      font-size: $font-size-36px !important;
    }
  }


  .answer-tab-for-practice-flow {
    .row-max-width-for-answer {
      // width: 35rem;
      background: none !important;
      // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      // border-radius: 10px;
      // height: 46px !important;
    }

    .only-correct-answer-width {
      width: 17.5rem !important;
    }

    .only-correct-answer {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .answer-status-text {
      font-size: $font-size-30px;
      font-weight: 600;
      color: #455B64;
      cursor: pointer;
    }

    .active-tab {
      background-color: #6B52C8;
      color: white !important;
      // border-top-left-radius: 10px;
      // border-bottom-left-radius: 10px;
      border-radius: 50px;
      padding: 0.5rem 3rem !important;
    }

    .active-my-answer-tab {
      background-color: #6B52C8;
      color: white !important;
      border-radius: 50px;
      padding: 0.5rem 3rem !important;
    }

    .active-solution-tab {
      background-color: #6B52C8;
      color: white !important;
      border-radius: 50px;
      padding: 0.5rem 3rem !important;
    }

    .in-active-tab {
      background-color: #E0E0E0;
      color: #A1A1A1 !important;
      border-radius: 50px;
      padding: 0.5rem 3rem !important;
    }

    .border-right {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .border-left {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .no-border-radius {
      border-radius: 0px;
    }



  }


}