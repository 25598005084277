@import "../../../styles/style-variables.scss";

.cw-common-custom-modal-for-city {
    font-size: $font-size-12px;
    margin-top: 10rem !important;
    float: center;
    max-width: 37.5rem !important;
    // height: 40rem;


    .select-country {
        position: absolute;
        top: -1.3rem;
        left: 1.5rem;
        font-size: $font-size-17px;
        font-weight: 700;
    }

    .country-search-div {
        position: absolute;
        z-index: 9999996;
        // top: 5rem;

        .country-search {
            width: 25px;
            margin-left: 2rem;
            // margin-top: -0.2rem;
            margin-top: 0.8rem;
        }

    }


    .search {
        position: sticky;
        //  top: -rem;
        z-index: 999999;
        left: 30px;
        width: 90%;
        margin-top: 1.1rem;

        input {
            background: #F6F7F5;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            min-height: 3rem;
            // background: #F6F7F5;
            border: 1px solid #F6F7F5;
            // border-radius: 50px;
            font-size: $font-size-14px;
            width: -webkit-fill-available;
            text-indent: 35px;
            margin-left: 1rem;
        }



        input:focus {
            background: #F6F7F5;
            border: 1px solid #F6F7F5;
        }

        label {
            margin: 0 !important;
            font-size: 14px;
        }

    }

    .dropdown-scroll {
        overflow-y: auto;
        height: 15.625rem;
        min-height: 7.5rem;
        position: relative;
        z-index: 1;
    }

    .dropdown-list {
        .country-name {
            font-size: $font-size-15px;
        }

        .country-name:hover {
            font-weight: 700;
        }
    }



    .no-data {
        label {
            font-size: 14px;
        }
    }


    .modal-content {
        padding: 1rem;
        background: #F8FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        min-height: 27rem;
    }


    .position {
        position: relative;
    }

    .challenge-header {
        width: 600px;
        height: 290px;
        position: absolute;
        left: 0rem;
        top: 0rem;
        border-radius: 20px;
    }

    .challenge-footer {
        width: 600px;
        height: 70px;
        position: absolute;
        left: -1rem;
        top: -3.4rem;
        border-radius: 20px;
        
    }

    .badge-image {
        width: 3.7rem;
        position: absolute;
        left: 17rem;
        top: 0.5rem;
    }

    .time-limit-image {
        width: 5.5rem;
        position: absolute;
        left: 17rem;
        top: 0rem;
    }


    .header-text {
        margin-top: 2.6rem;
        text-align: center;
        font-size: $font-size-20px;
        font-weight: 700;
    }

    .close-icon {
        width: 28px;
        float: right;
        position: relative;
        top: -1.6rem;
        right: 0.5rem;
    }

    .sub-text {
        font-size: 0.9rem;
        font-weight: 500;
        margin-left: 2rem;
    }

    .image-width {
        width: 180px;
    }

    .name-header {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-18px;
        //   line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .challege-yourself {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 800;
        font-size: $font-size-18px;
        line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .take-challenge {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: $font-size-17px;
        color: #FFFFFF;
        width: 200px;
        height: 45px;
        padding-top: 0.7rem;
        background: linear-gradient(90deg, #0AC6D6 0%, #5CD492 100%);
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25) !important;
        border-radius: 40px;
        position: relative;
        //   top: 1rem;
        z-index: 10;
    }

    .continue-button {
        width: 200px;
        height: 45px;
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        // border: 2px solid transparent;
        position: relative;
        z-index: 10;
        padding-top: 0.6rem;
        display: inline-block;
        font-size: $font-size-18px;
        font-weight: 600;
        color: #69C7C1;
        background: #FFFFFF !important;
        // margin-top: 1rem;
    }

    .continue-button::before {
        content: "";
        width: 200px;
        height: 45px;
        position: absolute;
        inset: 0;
        border-radius: 30px;


        padding: 2px;
        /* control the border thickness */
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }

    .pratice-container {
        margin-top: -2rem;
    }

    .margin-left {
        margin-left: 5.5rem;
        margin-bottom: 1rem;
        font-weight: 800;
        font-size: 1rem;
    }

    .active-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.7rem 0rem;
        width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
        position: relative;
        z-index: 99;
    }


    .save-button-disabled {
        background: #DFDFDF !important;
        color: #9D9D9D !important;
    }

}