.question-image-v1 {
    // background-color: antiquewhite;
    overflow-y: scroll;
    overflow-y: hidden;
    padding: 1rem;
    border-radius: 10px;

    .image-width {
        // width: 25rem;
        // height: 250px;
        // max-height: 10rem;
        // max-width: 100%;
        object-fit: contain;
        width: auto;
        height: auto;
        // maxHeight: `10rem`,
        //                 maxWidth : "100%",
        //                 objectFit : 'contain',
    }

    .expand-icon {
        width: 1.6rem;
        height: 1.6rem;
    }
   
}


.container-img {
    width: 27rem !important;
}

.container-img-2 {
    width: 53rem !important;
}

.container-img-3 {
    width: 98% !important;
}