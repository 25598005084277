@import '../../../../styles/style-variables.scss';
.custom-image-renderer {

    .play-again-button-wrapper {
        background: #dfe3e7;
        // width: 18.3rem;
        padding: 0.5rem;
        // padding-top: 0px !important;
        border-radius: 10px;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .reload-image-size {
        width: 1.5rem;
    }

    .play-again-text {
        font-size: $font-size-18px;
        font-weight: 500;
    }

    .loding-image-size {
        width: 6rem;
        background-color: #353535;
    }

    .loading-text {
        color: #000000;
        font-weight: 600;
        font-size: $font-size-18px;

    }

}