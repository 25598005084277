@import '../../styles/style-variables.scss';

.watch-video {

    // border-radius: 18px 0px;
    background: #E8F0FF !important;
    box-shadow: 0px 4px 4px 0px rgba(194, 194, 194, 0.25);
    

    .book-with-apple {
        width: 4.375rem;
    }

    .logo {
        padding-left: 2rem !important;
        margin-top: 0.5rem;
    }


    .header-text {
        font-size: 22px;
        font-weight: 700;
    }

    .close-icon {
        width: 1.5rem;
        float: right;
        z-index: 10rem;
        position: relative;
    }

    .heading {
        // background: #21B8C7 !important;
        border-radius: 9px 9px 0px 0px;
        // border-radius: 9px;
        color: #303030;
        font-size: 18px;
        font-weight: 700;
        padding: 1.5rem 0.5rem 1.5rem 1.8rem;
        border-bottom: 1px solid rgba(201, 201, 201, 0.93);
    }

    .display-none {
        display: none !important;
    }


    .video-detail-polygon {
        width: 5rem;
        position: absolute;
        right: 0rem;
        border-top-right-radius: 18px;
    }

    .card-footer {
        border-radius: 18px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        background: #E8F0FF !important;
        box-shadow: 0px 4px 4px 0px rgba(194, 194, 194, 0.25) !important;
    }

    .read-more-wrapper {
        border-radius: 10px;
        border: 1.5px solid #009AFF;
        padding: 0.4rem 0.2rem;
        // height: 2.2rem;


        .read-more {
            border-radius: 6.19px;
            background: #009AFF;
            color: $white;
            font-size: $font-size-14px;
            padding: 0.3rem;
            font-weight: 600;
            height: 2rem !important;
        }
    }

    .heading-subtext {
        font-size: 14px;
        font-weight: 600;
        color: #8E8E8E;
    }

    .elo-title {
        font-size: $font-size-18px;
        font-weight: 700;
        color: #353535;
        // position: relative;

    }

    .video-detail-divider {
        border: 0.5px solid #C9C9C9;
    }

    .video-detail-header-image {
        position: absolute;
        right: 1.5rem;
        height: 4rem;
        border-top-right-radius: 18px;
    }

    .video-detail-wrapper {
        border-radius: 20px;
        border: 0.5px solid #21B8C7;
        background: linear-gradient(165deg, #FFF 0%, #E9FDFF 100%);
    }



    .border-bottom {
        border-bottom: 1px solid #D2D2D2;
    }

    .arrow-gray {
        width: 10px;
        margin-right: 15px;
    }

    .close-icon-position {
        // margin-top: -35px !important;
        margin-right: 1rem;
    }

    .video-content-alignment {
        // margin-left: 37px !important;
        // padding-left: 13rem;
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 70%;


        video {
            width: 50% !important;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 12px;
            padding: 1px;


            //   position: relative;
            //   float: left;
            //   top : -23rem;
        }
    }

    .video-learning-content-wrapper {
        border-radius: 12px;
        border: 0.5px solid #21B8C7;
        background: linear-gradient(165deg, #FFF 0%, #E9FDFF 100%);
    }



    .video-learning-content {
        // display: flex;
        // align-items: end;
        // justify-content: flex-end;
        // max-width: 80%;


        .content-alignment {
            margin-left: 2rem;
        }

        p,
        .sr-no {
            font-weight: 800 !important;
            font-size: 13px !important;
            padding: 0px 0 0px 0px !important;
            margin-bottom: 0px !important;
            //   display: inline;
            //   align-items: center;
            //   justify-content: space-evenly;
            //   flex-wrap: wrap;
            // display: flex;
            // align-items: flex-end;
            // justify-content: flex-end;
        }



        video {
            width: 50%;
            border-radius: 8px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 12px;
            padding: 1px;

            //   position: relative;
            //   float: left;
            //   top : -23rem;
        }

        // @counter-style custom-unordered {
        //   system: cyclic;
        //   symbols:  url("../assets/images/rectangle-10.png") ;
        //   suffix: ' ';
        // }

        ul {
            padding-left: 20px;
            margin-bottom: 3px !important;
            // list-style: custom-unordered !important;
            //   display: flex;
            // align-items: flex-end;
            // justify-content: space-around;
        }

        li {
            font-weight: 800 !important;
            font-size: 13px !important;
            padding: 0px 0 0px 2px !important;
        }

        .cw-step {
            background: #F0FBFF;
            border: 1.02326px solid #38C8FF;
            border-radius: 8px;
            width: 40%;
            // margin-left: 2rem;
            margin-bottom: 0.5rem !important;
            margin-top: 0.5rem !important;
        }

        .div {
            text-align: left !important;
        }

        img {
            height: auto !important;
            width: 380px !important;
            margin-left: 5px;
            // display: none;
        }

    }

    .video-content-wrapper {
        padding-left: 1rem;
    }



    .video-content-title {
        font-weight: 600 !important;
        font-size: 16px !important;
        padding: 4px 0 4px 0 !important;
        //border-bottom: 4px solid #21B8C7;
        // border-radius: 15px;
        // background-color: #F0FBFF;
    }

    .video-learning-content-title {

        // margin-left: 2rem;

        p,
        .video-content-title,
        .sr-no {
            font-weight: 700 !important;
            font-size: 17px !important;
            padding: 4px 0 4px 0 !important;
            //border-bottom: 4px solid #21B8C7;
            // border-radius: 15px;
            // background-color: #F0FBFF;
        }
    }







}