@import "../../../styles/style-variables.scss";

.assessment-notification {
  // background-image: url("../../../../assets/images/header-1.png");
  font-size: $font-size-15px;
  font-weight: 300;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;

  .cw-input {
    border-radius: 10px !important;
  }

  .message-wrapper {
    padding: 0 65px;
  }

  .country-code-text {
    font-size: $font-size-14px;
    font-weight: 600;
    color: #353535;
  }

  .country-code-margin-left {
    margin-left: -1.5rem;
  }

  .mobile-number-ml {
    margin-left: 4.2rem;
  }

  .red-indication {
    color: red;
  }

  .share-container {
    display: flex;
    justify-content: center;
  }

  .message {
    font-size: $font-size-14px;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: #f6f7f5;
    padding: 15px;
  }

  .note {
    align-items: "justify";
    color: #404040;
    font-weight: 600;
    font-size: $font-size-12px;
  }

  .mobilenumber-wrapper {
    background-color: #f6f7f5;
    border-radius: 10px;
  }

  .add {
    font-size: $font-size-10px;
    font-weight: 600;
  }

  .delete {
    position: absolute;
  }

  .share {
    background-color: #21b8c7 !important;
    border-radius: 10px;
    padding: 10px 40px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
  .modal-body {
    padding-bottom: 2rem;
  }

  .invite-teachers-container {
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .header-text {
    font-size: $font-size-20px;
  }

  .header-description {
    font-size: $font-size-12px;
    font-weight: 500;
    padding: 0 2rem;
  }

  .not-editable {
    cursor: not-allowed !important;
  }

  .input-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    .delete-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -25px;
    }
  }

  .share-container {
    display: flex;
    justify-content: center;
  }

  .share-left {
    flex: 1;
    .step {
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fcf0d1;
      border-radius: 50%;
      font-size: $font-size-22px;
      font-weight: 600;
    }
  }
  .share-right {
    flex: 11;
    margin-left: 0.5rem;
    .title {
      font-size: $font-size-13px;
      font-weight: 600;
    }
    .description {
      font-size: $font-size-11px;
      font-weight: 500;
    }
  }

  .copy-container {
    display: flex;
    flex-direction: row;
    background: #eafdff;
    margin-top: 1rem;
    padding: 0.5rem 2rem;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    .share-link {
      color: #1C1C1C;
      font-weight: 600;
      font-size: $font-size-13px;
    }
    .copy-button {
      color: #ffffff;
      background: #21b8c7;
      border-radius: 10px;
      padding: 0.5rem 1.5rem;
      font-size: $font-size-13px;
      font-weight: 600;
      span {
        padding-left: 0.2rem;
        vertical-align: middle;
      }
      img {
        width: 20px;
        vertical-align: middle;
      }
    }
  }

  .cw-button-primary {
    img {
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
    }
  }

  .button-disabled {
    background: #dfdfdf !important;
  }

  .cw-input-left {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    width: 100%;
    background: #f6f7f5;
    border: none;
    font-size: 0.75rem;
    color: #1C1C1C !important;
    height: 3rem;
    padding-left: 1rem;
    display: inline-block;
    outline: none;
  }

  .pencil-icn-container {
    display: inline-block;
    width: 15%;
    vertical-align: middle;
  }

  .pencil-icn {
    width: 20px;
  }

  .button-text {
    line-height: 3rem;
  }

  .input-box {
    width: 100%;
    vertical-align: middle;
    background: #f6f7f5;
    border-radius: 10px;
  }

  .input-button {
    width: 35%;
    vertical-align: middle;
  }

  .close-icon {
    width: 20px !important;
    opacity: 0.5;
    margin-left: 10px;
  }

  .add-teacher {
    font-size: $font-size-10px;
    margin-right: 0.5rem;
    color: #008fff;
    font-weight: 600;
    margin-top: 0.2rem;
  }

  .cw-country-code {
    width: 34%;
    vertical-align: middle;
  }

  .cw-mobile-number {
    width: 65%;
    vertical-align: middle;
  }

  .cw-separator {
    background: #bebab8;
    width: 1px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .dropdown-v2 {
    .dropdown-read-only {
      background: transparent;
    }
  }
  .cw-button-update {
    background: #008fff;
    padding: 10px 10px;
    font-size: $font-size-10px;
    color: #ffffff;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
  }
}
