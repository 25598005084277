@import '../../../styles/style-variables.scss';

.know-more-screens {
    .header-image-size {
        width: calc(100% + 48px);
        margin-left: -3rem;
        height: 5rem;
    }

    .footer-image-size {
        position: absolute;
        width: calc(100% - 258px);
        left: 16.1rem !important;
        bottom: 0;
        right: 0;
        height: 5rem;
        z-index: 10;
    }

    .question-title {
        font-size: $font-size-18px;
        font-weight: 700;
    }

    .image-size {
        width: 100%;
        height: 15rem;
        object-fit: contain;
    }

    .step-no-max-width {
        max-width: 4.333333% !important;
    }

    .step-cirlce {
        width: 40px;
        height: 40px;
        border: 1px solid black;
        background: $white;
        border-radius: 50px;
    }

    .step-title {
        font-size: $font-size-16px;
        font-weight: 500;
    }

    .step-description-wrapper {
        margin-left: 3.4rem;
    }

    .full-screen-image-size {
        max-width: 100%;
        height: 15rem;
        object-fit: contain;
    }

    .min-height {
        max-height: 30rem;
        // overflow: scroll;
    }

    .continue-button {
        font-size: $font-size-18px;
        font-weight: 500;
        color: $white;
        border-radius: 30px;
        background: #6B52C8;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem 3rem;
        position: relative;
        z-index: 11;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        // margin-top: -4rem;
        position: absolute;
        top: 5.5rem;
        background: transparent !important;
        padding: 0 !important;
        // z-index: 12;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .circle-wrapper {
        gap: 10px;
        margin-top: 1rem;
    }

    .circle {
        width: 15px;
        height: 15px;
        background: $white;
        border: 1px solid #6B52C8;
        border-radius: 50px;
        position: relative;
        z-index: 15;
    }

    .active-dot {
        background: #6B52C8;
    }



}