@import '../../../styles/style-variables.scss';

.event-leaderboard {
    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        // margin-top: -4rem;
        // position: absolute;
        top: 5.5rem;
        background: transparent !important;
        padding: 0 !important;
        // z-index: 12;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .header-text {
        font-size: $font-size-28px;
        font-weight: 600;
    }

    .sub-header-text {
        font-size: $font-size-18px;
        font-weight: 500;
    }

    .question-divider-image-width {
        // width: 60rem;
        width: 100%;
        height: 0.12rem;
    }

    .instruction-text {
        font-size: $font-size-18px;
        color: #455b64;
    }

    .info-icon {
        width: 2rem;
    }

    .exam-pad-icon {
        width: 1.5rem;
    }

    .row-wrapper {
        max-width: 50%;
        border-radius: 10px !important;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-top: 1rem;
        padding: 1rem 0.5rem;
        margin-left: 0.1rem;

        .circle {
            width: 3.8rem;
            height: 3.8rem;
            border-radius: 50px;
        }

        .circle-text {
            font-size: $font-size-20px;
            font-weight: 700;
        }

        .name-text {
            font-size: $font-size-20px;
            font-weight: 700;
        }

        .column-max-width {
            max-width: 14%;
        }

        .marks-text {
            font-size: $font-size-16px;
            font-weight: 600;
        }
    }
}