@import '../../../styles/style-variables.scss';

.cw-custom-modal-for-invalid-answer {
    font-size: $font-size-12px;
    margin-top: 12rem !important;
    margin-left: 35rem !important;
    float: center;
    max-width: 600px !important;
    min-height: 450px !important;

    .pratice-container {
        margin-top: -2rem;
    }

    .invalid-answer {
        width: 23rem;
    }

    .image-wrapper {
        margin-top: 4rem;
    }


    .modal-content {
        padding: 1rem;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        min-height: 23rem;
    }

    .challenge-header {
        width: 600px;
        height: 350px;
        position: absolute;

        left: 0rem;
        top: 0rem;
        border-radius: 20px;
    }

    .challenge-footer {
        width: 600px;
        height: 70px;
        position: absolute;
        left: -2rem;
        top: 17rem;
        border-radius: 20px;
        z-index: 1;
    }

    

    .position {
        position: relative;
    }

    .image-size {
        width: 2.5rem;
    }

    .header-text {
        font-size: 22px;
        font-weight: 700;
    }

    .close-icon {
        width: 30px;
        float: right;
        position: relative;
        top: -1.3rem;
        right: 0.5rem;
    }

    .sub-text {
        font-size: 0.9rem;
        font-weight: 500;
        margin-left: 2rem;
    }

    .image-width {
        width: 180px;
    }

    .name-header {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-20px;
        line-height: 27px;
        // text-align: center;
        color: #263238;
    }

    .sub-title {
        font-size: $font-size-15px;
        font-weight: 400;
        color: $sub-text-color;
    }

    .challege-yourself {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 800;
        font-size: $font-size-18px;
        line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .take-challenge {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        //   line-height: 26px;
        width: 180px;
        height: 50px;
        background: #6B52C8;
        border-radius: 30px;
        position: absolute;
        bottom: -2.5rem;
        z-index: 10;
        padding-top: 0.8rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

   


    .margin-left {
        margin-left: 5.5rem;
        margin-bottom: 1rem;
        font-weight: 800;
        font-size: 1rem;
    }

    .concepts-covered-wrapper {
        min-height: 10rem;
        overflow: scroll;
        flex-wrap: wrap;

        .concept-title {
            font-size: $font-size-19px;
            font-weight: 700;
            color: #263238;
        }

        .elo-title {
            font-size: $font-size-16px;
            font-weight: 600;
            color: $sub-text-color;
        }
    }


    div::-webkit-scrollbar {
        display: none;
    }


}