@import "../../../styles/style-variables.scss";

.cw-common-custom-modal-center-for-assessment {
    font-size: $font-size-12px;
    margin-top: 10rem !important;
    float: center;
    max-width: 37.5rem !important;
    // height: 28.125rem !important;

    .modal-content {
        padding: 1rem;
        background: #F8FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        height: 16rem !important;
    }

    .flag-size {
        width: 3rem;
    }

    .challenge-header {
        width: 600px;
        height: 290px;
        position: absolute;
        left: 0rem;
        top: 0rem;
        border-radius: 20px;
    }

    .challenge-footer {
        width: 600px;
        height: 70px;
        position: absolute;
        left: -2rem;
        top: -1.6rem;
        border-radius: 20px;
        z-index: 1;
    }

    .badge-image {
        width: 3.7rem;
        position: absolute;
        left: 17rem;
        top: 0.5rem;
    }

    .time-limit-image {
        width: 5.5rem;
        position: absolute;
        left: 17rem;
        top: 0rem;
    }

    .position {
        position: relative;
    }

    .header-text {
        // margin-top: 2.6rem;
        // text-align: center;
        font-size: $font-size-20px;
        font-weight: 700;
    }

    .close-icon {
        width: 28px;
        float: right;
        position: relative;
        top: -1.6rem;
        right: 0.5rem;
    }

    .sub-text {
        font-size: 0.9rem;
        font-weight: 500;
        margin-left: 2rem;
    }

    .image-width {
        width: 180px;
    }

    .name-header {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-16px;
        //   line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .challege-yourself {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 800;
        font-size: $font-size-18px;
        line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .take-challenge {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: $font-size-17px;
        color: #FFFFFF;
        width: 200px;
        height: 45px;
        padding-top: 0.7rem;
        background: #6B52C8;
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25) !important;
        border-radius: 40px;
        position: relative;
        //   top: 1rem;
        z-index: 10;
    }

    .button-wrapper {
        margin-top: 5.7rem;
    }

    .continue-button {
        width: 200px;
        height: 45px;
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        // border: 2px solid transparent;
        position: relative;
        z-index: 10;
        padding-top: 0.5rem;
        display: inline-block;
        font-size: $font-size-18px;
        font-weight: 600;
        color: #6B52C8;
        background: #FFFFFF !important;
        // margin-top: 1rem;
        border: 1px solid #6B52C8;
        
    }

    // .continue-button::before {
    //     content: "";
    //     width: 200px;
    //     height: 45px;
    //     position: absolute;
    //     inset: 0;
    //     border-radius: 30px;


    //     padding: 2px;
    //     /* control the border thickness */
    //     background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
    //     -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    //     -webkit-mask-composite: xor;
    //     mask-composite: exclude;
    //     pointer-events: none;
    // }

    .pratice-container {
        margin-top: -4rem;
    }

    .margin-left {
        margin-left: 5.5rem;
        margin-bottom: 1rem;
        font-weight: 800;
        font-size: 1rem;
    }
}