@import '../../../../../../styles/style-variables.scss';

.exam-prep-kit-header-pagination {
    max-width: 100%;
    .pagination-container {
        width: 100%;
        // overflow-x: scroll;
        margin-left: 0.5rem;
        min-height: 3rem;

        .index-container {
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .small-index-container {
            width: 1.3rem !important;
            height: 1.3rem !important;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #C4C4C4;
        }

        .index-container-1 {
            width: 2.3rem;
            height: 2.3rem;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .small-index-container-1 {
            width: 1.5rem !important;
            height: 1.5rem !important;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #C4C4C4;
        }

        .active-index {
            color: white;
            align-self: center;
            font-size: $font-size-18px;
        }

        .inactive-index {
            color: white;
            align-self: center;
            font-size: $font-size-12px;
        }

        .gradient-border-height {
            width: 3rem;
            height: 0.2rem;
            background-color: #C4C4C4;
        }


    }

    div::-webkit-scrollbar {
        display: none;
    }

    .progress-container {
        position: absolute;
        left: -0.6rem;
        top: -0.65rem;
        align-self: center;
        z-index: 99999999;
    }

    .CircularProgressbar .CircularProgressbar-path {
        stroke: #05C5D9 ;
    }
}

