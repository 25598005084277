@import '../../../styles/style-variables.scss';

.class-specific-my-assessments {

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color !important;
        font-weight: 600;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .mins-text {
        font-size: $font-size-14px;
        font-weight: 400;
    }

    .chapter-name {
        font-size: $font-size-15px;
        font-weight: 500;
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .title {
        font-size: $font-size-30px !important;
        font-weight: 700 !important;
    }

    .header-image-size {
        max-width: 100%;
        object-fit: contain;
        height: 5rem;
        // position: absolute;
    }

    .lock-icon-size {
        width: 1.5rem;
    }

    .learning-gap-button {
        // background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        background: #6B52C8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.4rem 1rem !important;
        width: 10rem !important;
        font-size: $font-size-16px;
        font-weight: 500;
        // margin-top: 1.2rem;
    }

    .question-set {
        width: 98%;
    }

    .question-border-bottom {
        border-bottom: 1px solid #E5E5E5;
    }

    .start-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.4rem 0rem !important;
        width: 8rem !important;
        font-size: $font-size-16px;
        font-weight: 500;
        // margin-top: 1.2rem;
    }

    .set-text {
        font-size: $font-size-18px;
        font-weight: 600;
    }

    .insights-button {
        // background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // border-radius: 30px;
        // color: white;
        // padding: 0.7rem 0rem;
        width: 9.5rem;
        font-size: $font-size-16px;
        font-weight: 500;
        // margin-top: 1.2rem;

        // width: 13.75rem;
        // height: 3rem;
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        border: 1px solid transparent;
        position: relative;
        padding: 0.3rem 0.8rem;
        display: inline-block;
        font-size: $font-size-18px;
        // font-weight: 600;
        color: #69C7C1;
        //  margin-top: 0.5rem;

    }


    .insights-button::before {
        content: "";
        width: 9.5rem;
        height: 2.4rem;
        position: absolute;
        inset: 0;
        border-radius: 30px;
        padding: 2px;
        /* control the border thickness */
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }



}

.cw-common-custom-modal-center-for-assessment-type-selection {
        font-size: $font-size-12px;
        margin-top: 10rem !important;
        float: center;
        max-width: 37.5rem !important;
        // height: 28.125rem !important;
    
        .modal-content {
            padding: 1rem;
            background: #F8FAFC;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
        }
    
        .chapter-lock-image-size {
            width: 4rem;
        }
    
        .challenge-header {
            width: 600px;
            height: 290px;
            position: absolute;
            left: 0rem;
            top: 0rem;
            border-radius: 20px;
        }
    
        .challenge-footer {
            width: 600px;
            height: 70px;
            position: absolute;
            left: -2rem;
            top: -2.4rem;
            border-radius: 20px;
            z-index: 1;
        }
    
        .badge-image {
            width: 3.7rem;
            position: absolute;
            left: 17rem;
            top: 0.5rem;
        }
    
        .time-limit-image {
            width: 5.5rem;
            position: absolute;
            left: 17rem;
            top: 0rem;
        }
    
        .position {
            position: relative;
        }
    
        .header-text {
            margin-top: 0rem;
            text-align: center;
            font-size: $font-size-20px;
            font-weight: 700;
        }
    
        .chapter-image-size {
            width: 4rem;
            margin-top: -3rem;
        }
    
        .sub-text {
            margin-top: 2.6rem;
            // text-align: center;
            font-size: $font-size-20px;
            font-weight: 500;
        }
    
        .close-icon {
            width: 28px;
            float: right;
            position: relative;
            top: -1.6rem;
            right: 0.5rem;
        }
    
        // .sub-text {
        //     font-size: 0.9rem;
        //     font-weight: 500;
        //     margin-left: 2rem;
        // }
    
        .image-width {
            width: 180px;
        }
    
        .name-header {
            font-family: 'Mont';
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-18px;
            //   line-height: 27px;
            text-align: center;
            color: #000000;
        }
    
        .challege-yourself {
            font-family: 'Mont';
            font-style: normal;
            font-weight: 800;
            font-size: $font-size-18px;
            line-height: 27px;
            text-align: center;
            color: #000000;
        }
    
        .take-challenge {
            font-family: 'Mont';
            font-style: normal;
            font-weight: 700;
            font-size: $font-size-17px;
            color: #FFFFFF;
            width: 200px;
            height: 45px;
            padding-top: 0.7rem;
            background: linear-gradient(90deg, #0AC6D6 0%, #5CD492 100%);
            box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25) !important;
            border-radius: 40px;
            position: relative;
            //   top: 1rem;
            z-index: 10;
        }
    
        .continue-button {
            width: 200px;
            height: 45px;
            box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            // border: 2px solid transparent;
            position: relative;
            z-index: 10;
            padding-top: 0.6rem;
            display: inline-block;
            font-size: $font-size-18px;
            font-weight: 600;
            color: #69C7C1;
            background: #FFFFFF !important;
            // margin-top: 1rem;
        }
    
        .continue-button::before {
            content: "";
            width: 200px;
            height: 45px;
            position: absolute;
            inset: 0;
            border-radius: 30px;
    
    
            padding: 2px;
            /* control the border thickness */
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            pointer-events: none;
        }
    
        .pratice-container {
            margin-top: -2rem;
        }
    
        .margin-left {
            margin-left: 5.5rem;
            margin-bottom: 1rem;
            font-weight: 800;
            font-size: 1rem;
        }
    
}