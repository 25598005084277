@import '../../styles/style-variables.scss';
.event-list {
    .event-list-header {
            .title {
                font-size: $font-size-32px;
                font-weight: bold;
            }
        
            .filter-wrapper {
                margin-right: 5rem;
            }
        
            .filter-size {
                width: 1.8rem;
            }
        
            .image-size {
                width: 900px;
                height: 80px;
            }
        
            .divider-image-width {
                // width: 1160px;
                width: 99%;
                height: 1.5px;
            }
        
            .search-icon-width {
                width: 35px;
                position: relative;
                left: 2.5rem;
            }
        
            .input-box {
                width: 200px;
                height: 48px;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 12px;
                border: none;
                text-indent: 40px;
            }
        
            .header-title-margin-top {
                margin-top: -4rem;
            }
        
            .breadcrumb {
                font-size: $font-size-16px;
                color: $sub-text-color;
                font-weight: 600;
        
                background: transparent !important;
                padding: 0 !important;
        
                .active {
                    color: #1C1C1C;
                }
            }
        
            .title-1:hover, .home:hover {
                color: black;
                font-weight: 800;
            }
        
        
        
    }

    .row-max-width {
        width: 35rem;
        background: #FFFFFF;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        height: 46px !important;
    }

    .no-homework-image-size {
        width: 20rem;
    }

    .no-homework-text {
        font-size: $font-size-19px;
        font-weight: 600;
        color: $report-filter-label
    }

    .answer-status-text {
        font-size: $font-size-20px;
        font-weight: 500;
        color: #455B64;
        cursor: pointer;
    }

    .completed-tab {
        background-color: #69C7C1;
        color: white !important;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .not-completed-tab {
        background-color: #69C7C1;
        color: white !important;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }


    .no-event-found-image {
        width: 20rem;
    }

    .no-event-text {
        font-size: $font-size-19px;
        font-weight: 600;
        color: $report-filter-label
    }
}