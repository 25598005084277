@import "../../../../styles/style-variables.scss";

.question-title-for-clicker-practice {

    // gap: 1rem;
    // max-width: 67rem;
    // max-width: 60%;
    margin-left: 6rem;
    // width: 90%;
    margin-right: 1rem;

    .flag-assessment-image {
        width: 3rem;
    }

    .arrow-size {
        width: 3.2rem !important;
        margin-top: -0.3rem;
    }

    .timer-wrapper {
        position: absolute;
        top: 0rem;
        right: 1.2rem;
    }

    .timer-text {
        font-size: $font-size-32px;
    }

    .question-number-circle {
        width: 80px;
        height: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
        // border: 1px solid black;
        border-radius: 50px;
        margin-right: 0.5rem;
        padding: 1.5rem;
        margin-top: 0.2rem;
        background-color: #007bff;
        color: white;
    }

    .question-number {
        font-size: $font-size-32px;
        font-weight: 500;
    }

    .quiz-button {
        background-color: #06C5D9;
        color: white;
        font-size: $font-size-32px;
        border-radius: 50px;
        padding: 0.2rem 1rem;
    }

    .subject-text {
        padding-right: 10px;
        border-right: 2px solid darkgray;
        font-size: $font-size-32px;
    }

    .view-case-study-button-wrapper {
        border: 1px solid #6B52C8;
        background-color: white;
        padding: 0.5rem 1rem;
        border-radius: 10px;

        .view-case-study-button {
            color: #6B52C8;
            font-size: 17px;
            font-weight: 600;
        }
    }

    .case-study-icon {
        width: 1.5rem;
    }
}


.cw-case-study-custom-modal-center {
    font-size: $font-size-12px;
    margin-top: 8rem !important;
    float: center;
    // max-width: 37.5rem !important;
    // height: 28.125rem !important;
    width: 100%;

    .modal-content {
        padding: 1rem;
        background: #F8FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        min-height: 20rem;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .challenge-header {
        width: 600px;
        height: 290px;
        position: absolute;
        left: 0rem;
        top: 0rem;
        border-radius: 20px;
    }

    .case-study-title {
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .case-study-description {
        font-size: $font-size-16px;
        font-weight: 500;
    }

    .challenge-footer {
        width: 600px;
        height: 70px;
        position: absolute;
        left: -2rem;
        top: -2.4rem;
        border-radius: 20px;
        z-index: 1;
    }

    .badge-image {
        width: 3.7rem;
        position: absolute;
        left: 17rem;
        top: 0.5rem;
    }

    .time-limit-image {
        width: 5.5rem;
        position: absolute;
        left: 17rem;
        top: 0rem;
    }

    .position {
        position: relative;
    }

    .header-text {
        margin-top: 2.6rem;
        text-align: center;
        font-size: $font-size-20px;
        font-weight: 700;
    }

    .close-icon {
        width: 28px;
        float: right;
        position: relative;
        top: -1rem;
        right: -0.8rem;
    }

    .sub-text {
        font-size: 0.9rem;
        font-weight: 500;
        margin-left: 2rem;
    }

    .image-width {
        width: 180px;
    }

    .name-header {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-18px;
        //   line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .challege-yourself {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 800;
        font-size: $font-size-18px;
        line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .take-challenge {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: $font-size-17px;
        color: #FFFFFF;
        width: 200px;
        height: 45px;
        padding-top: 0.7rem;
        background: linear-gradient(90deg, #0AC6D6 0%, #5CD492 100%);
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25) !important;
        border-radius: 40px;
        position: relative;
        //   top: 1rem;
        z-index: 10;
    }

    .continue-button {
        width: 200px;
        height: 45px;
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        // border: 2px solid transparent;
        position: relative;
        z-index: 10;
        padding-top: 0.6rem;
        display: inline-block;
        font-size: $font-size-18px;
        font-weight: 600;
        color: #69C7C1;
        background: #FFFFFF !important;
        // margin-top: 1rem;
    }

    .continue-button::before {
        content: "";
        width: 200px;
        height: 45px;
        position: absolute;
        inset: 0;
        border-radius: 30px;


        padding: 2px;
        /* control the border thickness */
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }

    .pratice-container {
        margin-top: -2rem;
    }

    .margin-left {
        margin-left: 5.5rem;
        margin-bottom: 1rem;
        font-weight: 800;
        font-size: 1rem;
    }
}