@import "./style-variables.scss";


.min-height {
  min-height: fit-content;
}

.option-margin-bottom {
  margin-bottom: 7.5rem !important;
}


// to not show the video Progress bar in all videos
video::-webkit-media-controls-timeline {
  display: none;
}


.cw-card {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 1.25rem;
  background: white;

  .cw-card-header {
    color: $white;
    height: 3.375rem;
    background: #21b8c7;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .cw-card-body {
    padding: 30px 30px 30px 30px;
  }

  .cw-card-footer {
    min-height: 3.375rem;
    background: $linear-gradiant-cw-card-footer;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .footer-text {
      color: $white;
      font-size: $font-size-18px;
      font-weight: 500;
    }
  }
}

.disable-button {
  background-color: #d0d2d2 !important;
}

.cw-gradiant-card {
  background: linear-gradient(180deg,
      #21b8c7 0%,
      rgba(27, 156, 168, 0.253) 100%) !important;
  padding: 1px;
  border-radius: 15px;

  .cw-gradiant-card-content {
    border-radius: 15px;
    background: white;
    padding: 40px 40px 40px 40px;
  }
}

.cw-input {
  // border-radius: 1.875rem;
  width: 100%;
  height: 4rem;
  // background: $gray;
  border: none;
  font-size: $font-size-12px;
  padding: 1rem;
  padding-top: 0.9rem !important;
  color: $app-black !important;
  background: #F6F7F5;
  border-radius: 60px;
  position: relative;
}

.cw-input-for-app-link {
  // border-radius: 1.875rem;
  width: 100%;
  height: 1.7375rem;
  // background: $gray;
  border: none;
  font-size: $font-size-12px;
  padding: 1rem;
  padding-top: 0.9rem !important;
  color: $app-black !important;
  background: #F6F7F5;
  border-radius: 60px;
  position: relative;
}



.cw-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b2aeab;
  opacity: 1;
  /* Firefox */
}

.cw-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b2aeab;
}

.cw-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b2aeab;
}

.cw-input:focus {
  outline: none;
}

.cw-input-for-app-link::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b2aeab;
  opacity: 1;
  /* Firefox */
}

.cw-input-for-app-link:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b2aeab;
}

.cw-input-for-app-link::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b2aeab;
}

.cw-input-for-app-link:focus {
  outline: none;
}


.cursor-pointer {
  cursor: pointer;
}

.cw-input-label {
  font-weight: 600;
  font-size: $font-size-12px;
  line-height: 0.8rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0 !important;
  color: $label-black;
}

.invalid {
  border: 1px solid $red !important;
}

.invalid-text {
  font-size: $font-size-12px;
  color: $red;
}

.bw-spaces-input {
  padding-top: 1.3rem;
}

.cw-background-plain {
  background-image: url("../assets/images/background.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

.cw-background {
  background-image: url("../assets/images/login-background.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

.cw-button {
  border: none;
  color: white;
  background-color: $green;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  border-radius: 1.875rem;
  padding: 0.5rem 2rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
}

.cw-button-disable {
  background-color: $dark-gray;
  cursor: auto;
}

.cw-button:hover {
  opacity: 0.8;
}

.cw-button:focus {
  outline: none;
}

.cw-loader {
  border: 5px solid #f3f3f3;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.right-padding {
  padding-right: 4rem;
}

.left-padding {
  padding-left: 4.4rem;
}

.cw-mt-8 {
  margin-top: 8rem;
}

.cw-mt-4 {
  margin-top: 4rem;
}

.cw-mt-2 {
  margin-top: 2rem;
}

.cw-mb-4 {
  margin-bottom: 4rem;
}

.cw-mb-2 {
  margin-bottom: 2rem;
}

.cw-mb-1 {
  margin-bottom: 1rem;
}

.star {
  color: $green;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cw-plain-card {
  /** Used in initial dashboard screen */
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 15px;
}

.cw-button-sm {
  border: none;
  max-width: 15rem;
  color: white;
  background: #21b8c7;
  border-radius: 10px;
  font-size: $font-size-16px;
  font-weight: bold;
  text-align: center;
  padding: 0.4rem 2.5rem;
  outline: none;
  display: inline-block;
  cursor: pointer;
}

.cw-button-md {
  border: none;
  max-width: 15rem;
  color: white;
  background: #21b8c7;
  border-radius: 10px;
  font-size: $font-size-16px;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem 2.5rem;
  outline: none;
  display: inline-block;
  min-width: 12rem;
  cursor: pointer;
}

.cw-btn-sm {
  border: none;
  max-width: 15rem;
  padding: 0.4rem 2.5rem;
  outline: none;
  display: inline-block;
  cursor: pointer;
}

.cw-btn-md {
  border: none;
  max-width: 20rem;
  padding: 0.8rem 2.5rem;
  outline: none;
  display: inline-block;
  cursor: pointer;
}

.cw-btn-md-v1 {
  border: none;
  padding: 0.8rem 2.5rem;
  outline: none;
  display: inline-block;
  cursor: pointer;
}

.cw-btn-primary {
  color: white;
  background: #21b8c7;
  border-radius: 10px;
  font-size: $font-size-16px;
  font-weight: bold;
}

.cw-button-disable {
  color: white;
  background: #21b8c7;
  border-radius: 10px;
  font-size: $font-size-16px;
  font-weight: bold;
}

.cw-btn-secondary {
  color: white;
  background: #1d919c;
  border-radius: 10px;
  font-size: $font-size-16px;
  font-weight: bold;
  position: relative;
  z-index: 10;
}

.cw-btn-md2 {
  background-color: #1d919c !important;
}

.cw-btn-success {
  background: #87ca9e;
  color: white;
  border-radius: 10px;
  font-size: $font-size-16px;
  font-weight: bold;
}

.cw-btn-blue {
  background: #008fff;
  color: white;
  border-radius: 10px;
  font-size: $font-size-16px;
  font-weight: bold;
}

.loader-icn {
  margin: 0 auto;
}

.icon-15 {
  width: 15px;
}

.icon-20 {
  width: 20px;
}

.icon-30 {
  width: 30px;
}

.icon-40 {
  width: 40px;
}

.screen-title {
  font-size: $font-size-29px;
  font-weight: bold;
  color: #1C1C1C;
}

.cw-custom-modal-center {
  font-size: $font-size-12px;
  margin-top: 10rem;
  float: center;
  max-width: 600px !important;
  height: 450px !important;

  .modal-content {

    padding: 1rem;
    background: #F8FAFC;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .challenge-header {
    width: 600px;
    height: 350px;
    position: absolute;

    left: 0rem;
    top: 0rem;
    border-radius: 20px;
  }

  .challenge-footer {
    width: 600px;
    height: 89px;
    position: absolute;

    left: -2rem;
    top: -3.5rem;
    border-radius: 20px;
    z-index: 1;
  }

  .position {
    position: relative;
  }

  .header-text {
    font-size: 22px;
    font-weight: 700;
  }

  .close-icon {
    width: 24px;
    float: right;
    position: relative;
    top: -1.3rem;
    right: 0.5rem;
  }

  .sub-text {
    font-size: 0.9rem;
    font-weight: 500;
    margin-left: 2rem;
  }

  .image-width {
    width: 180px;
  }

  .name-header {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-18px;
    line-height: 27px;
    text-align: center;
    color: #000000;
  }

  .challege-yourself {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-size: $font-size-18px;
    line-height: 27px;
    text-align: center;
    color: #000000;
  }

  .take-challenge {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 26px;
    width: 240px;
    height: 60px;
    background: #6B52C8;
    border-radius: 30px;
    position: relative;
    z-index: 10;
  }

  .pratice-container {
    margin-top: -2rem;
  }

  .margin-left {
    margin-left: 5.5rem;
    margin-bottom: 1rem;
    font-weight: 800;
    font-size: 1rem;
  }
}



.cw-custom-modal {
  font-size: $font-size-12px;
  margin-top: 10rem;

  .modal-body {
    padding: 0 !important;
  }

  .modal-content {
    border-radius: 10px;
  }

  .header-text {
    font-size: 22px;
    font-weight: 700;
  }

  .close-icon {
    width: 20px;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
  }

  .heading {
    background: #21B8C7 !important;
    border-radius: 9px 9px 0px 0px;
    // border-radius: 9px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    padding: 1.3rem;
  }

  .heading-subtext {
    font-size: 12px;
    font-weight: 400;
  }

  .no-bg-color {
    background: 0 !important;
    border-radius: 0 !important;
    color: #353535 !important;
  }

  .border-bottom {
    border-bottom: 1px solid #D2D2D2;
  }

  .arrow-gray {
    width: 10px;
    margin-right: 15px;
  }

  .close-icon-position {
    margin-top: -24px !important;
  }



}

.cw-custom-modal-for-video {
  font-size: $font-size-12px;
  margin-top: 10rem !important;

  .modal-body {
    padding: 0 !important;
  }

  .modal-content {
    border-radius: 10px;
  }

  .header-text {
    font-size: 22px;
    font-weight: 700;
  }

  .close-icon {
    width: 20px;
    float: right;
  }

  .heading {
    // background: #21B8C7 !important;
    border-radius: 9px 9px 0px 0px;
    // border-radius: 9px;
    color: #303030;
    font-size: 18px;
    font-weight: 700;
    padding: 1.5rem 0.5rem 1.5rem 1.8rem;
  }

  .heading-subtext {
    font-size: 14px;
    font-weight: 600;
  }



  .border-bottom {
    border-bottom: 1px solid #D2D2D2;
  }

  .arrow-gray {
    width: 10px;
    margin-right: 15px;
  }

  .close-icon-position {
    margin-top: -35px !important;
    margin-right: 4px;
  }

  .video-content-alignment {
    margin-left: 37px !important;
    padding-left: 13rem;



    video {
      width: 70% !important;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      padding: 1px;


      //   position: relative;
      //   float: left;
      //   top : -23rem;
    }
  }


  // .video-learning-content {

  //   p,
  //   .sr-no {
  //     font-weight: 800 !important;
  //     font-size: 13px !important;
  //     padding: 0px 0 0px 32px !important;
  //   }

  //   video {
  //     width: 45%;
  //     border-radius: 15px;
  //   }
  //   // @counter-style custom-unordered {
  //   //   system: cyclic;
  //   //   symbols:  url("../assets/images/rectangle-10.png") ;
  //   //   suffix: ' ';
  //   // }

  //   ul {
  //     padding-left: 60px;
  //     // list-style: custom-unordered !important;
  //   }

  //   li {
  //     font-weight: 800 !important;
  //     font-size: 13px !important;
  //     padding: 0px 0 0px 5px !important;
  //   }

  //   .cw-step {
  //     background: #F0FBFF;
  //     border: 1.02326px solid #38C8FF;
  //     border-radius: 8px;
  //     width: 40%;
  //     margin-left: 2rem;
  //   }

  //   img {
  //     height: auto !important;
  //     width: 500px !important;
  //     margin-left: 20rem;
  //   }

  // }

  // .video-learning-content-title {

  //   margin-left: 2rem;

  //   p,
  //   .video-content-title,
  //   .sr-no {
  //     font-weight: 800 !important;
  //     font-size: 16px !important;
  //     padding: 4px 0 4px 0 !important;
  //     //border-bottom: 4px solid #21B8C7;
  //     // border-radius: 15px;
  //     // background-color: #F0FBFF;
  //   }



  // }

  .video-learning-content {
    // display: flex;
    // align-items: end;
    // justify-content: flex-end;
    // max-width: 50%;

    .content-alignment {
      margin-left: 2rem;
    }

    p,
    .sr-no {
      font-weight: 800 !important;
      font-size: 13px !important;
      padding: 0px 0 0px 0px !important;
      margin-bottom: 0px !important;
      //   display: inline;
      //   align-items: center;
      //   justify-content: space-evenly;
      //   flex-wrap: wrap;
      // display: flex;
      // align-items: flex-end;
      // justify-content: flex-end;
    }



    video {
      width: 50%;
      border-radius: 8px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 12px;
      padding: 1px;

      //   position: relative;
      //   float: left;
      //   top : -23rem;
    }

    // @counter-style custom-unordered {
    //   system: cyclic;
    //   symbols:  url("../assets/images/rectangle-10.png") ;
    //   suffix: ' ';
    // }

    ul {
      padding-left: 20px;
      margin-bottom: 3px !important;
      // list-style: custom-unordered !important;
      //   display: flex;
      // align-items: flex-end;
      // justify-content: space-around;
    }

    li {
      font-weight: 800 !important;
      font-size: 13px !important;
      padding: 0px 0 0px 2px !important;
    }

    .cw-step {
      background: #F0FBFF;
      border: 1.02326px solid #38C8FF;
      border-radius: 8px;
      width: 40%;
      // margin-left: 2rem;
      margin-bottom: 0.5rem !important;
      margin-top: 0.5rem !important;
    }

    .div {
      text-align: left !important;
    }

    img {
      height: auto !important;
      width: 380px !important;
      margin-left: 5px;
      // display: none;
    }

  }



  .video-content-title {
    font-weight: 800 !important;
    font-size: 16px !important;
    padding: 4px 0 4px 0 !important;
    //border-bottom: 4px solid #21B8C7;
    // border-radius: 15px;
    // background-color: #F0FBFF;
  }

  .video-learning-content-title {

    // margin-left: 2rem;

    p,
    .video-content-title,
    .sr-no {
      font-weight: 800 !important;
      font-size: 18px !important;
      padding: 4px 0 4px 0 !important;
      //border-bottom: 4px solid #21B8C7;
      // border-radius: 15px;
      // background-color: #F0FBFF;
    }
  }






}


.position-relative {
  position: relative !important;
}

.cw-custom-modal-right-for-solution {
  margin: 0 !important;
  float: right;
  min-width: 850px !important;
  font-size: $font-size-12px;

  .modal-body {
    padding: 0 !important;
  }

  .header-text {
    font-size: 22px;
    font-weight: 700;
  }

  .close-icon {
    width: 20px;
    float: right;
  }

//   .scroll-bar {
//     // max-height: 100vh;
//     // overflow: scroll !important;
//     // padding: 10rem;
//     min-height: 100vh;
// padding-left: 5rem;
// padding-right: 3rem;
// padding-bottom: 5rem;
// }
  
}

.cw-button-primary {
  border: none;
  color: white;
  background: #21b8c7;
  border-radius: 10px;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem 2.5rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
}

.cw-button-disabled {
  border: none;
  color: white;
  background: #c4c4c4;
  border-radius: 10px;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem 2.5rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
}

.cw-button-primary:hover {
  opacity: 0.8;
}

.vector-image-top-right {
  position: absolute;
  z-index: 999;
  width: 270px;
  height: 170px;
  right: 0;
  top: 5rem;
}

.vector-image-bottom-right {
  position: absolute;
  z-index: 999;
  width: 150px;
  right: 0;
  top: 70%;
}

.cw-table {
  border-collapse: separate;
  border-spacing: 0px 5px;

  tr {
    background-color: transparent;
    border: none !important;
    font-size: $font-size-15px;
  }

  td {
    vertical-align: inherit !important;
    border: none !important;
    font-size: $font-size-13px;
    font-weight: 500;
  }

  th {
    background-color: #21b8c7;
    color: white;
    font-size: $font-size-16px;
    height: 50px;
    vertical-align: inherit !important;
    border: none !important;
  }

  .th-left-radius {
    border-top-left-radius: 15px;
  }

  .th-right-radius {
    border-top-right-radius: 15px;
  }

  #table-scoll::-webkit-scrollbar {
    width: 0;
    background-color: #f5f5f5;
  }

  #table-scoll::-webkit-scrollbar-thumb {
    background-color: #999999;
  }

  .cw-button-primary-rounded {
    background: #ffffff;
    color: #009aff;
    border: 1px solid #009aff;
    border-radius: 40px;
  }
}

.not-editable {
  cursor: no-drop;
}

.ad-banner {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.add-teachers-modal {
  .header-text {
    span:first-child {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .invite-link {
    color: #21b8c7;
    font-size: $font-size-16px;
    display: inline-block;
    vertical-align: middle;
  }
}

.export-xls {
  width: 10px;
  vertical-align: text-top;
  margin-right: 5px;
}

.app-input-label {
  color: #7d7d7d;
  font-weight: 800;
  line-height: 23px;
}

.chapter-list {
  .title {
      font-size: $font-size-40px;
      font-weight: bold;
  }

  .image-size {
      width: 900px;
      height: 80px;
  }

  .divider-image-width {
      width: 98%;
      height: 1.5px;
  }

  .search-icon-width {
      width: 35px;
      position: relative;
      left: 2.5rem;
  }

  .input-box {
      width: 200px;
      height: 48px;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      border: none;
      text-indent: 40px;
  }

  .header-title-margin-top {
      margin-top: -4rem;
  }

  .breadcrumb {
      font-size: $font-size-16px;
      color: #212529;
      font-weight: 600;

      background: transparent !important;
      padding: 0 !important;

      .active {
          color: #1C1C1C;
      }
  }
}


.cw-modal-center {
  font-size: $font-size-12px;
  margin-top: 2rem !important;
  float: center;
  max-width: 60rem !important;
  

  .modal-content {
      // padding: 1rem;
      // background: #F8FAFC;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      height: 40rem;
      overflow: auto;
      
  }

  .content-wrapper {
    padding: 1rem;
  }

  .overview {
    color: #000000 !important;
    font-size: $font-size-20px !important;
    font-weight: 800 !important;
  }


  .close-icon-size {
      width: 1.8rem;
  }

  .header-divider {
    height: 0.2rem;
    width: 58.9rem;
    width: calc(100% + 32px);
    margin-left: -1rem;
    // overflow: hidden;
  }

  .terms {
    font-size: $font-size-24px;
    font-weight: 800;
  }

  .content-size {
    color: #455B64;
    font-size: $font-size-18px;
    font-weight: 500;
  }

  .continue-button {
    background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: white;
    padding: 0.7rem 0rem;
    width: 12rem;
    font-size: $font-size-18px;
    font-weight: 500;
    margin-top: 1.2rem;
}
}


.border {
  border: 1.5px solid #BABABA !important;
  border-radius: 10px !important;
  color: #008fff !important;
  font-weight: 700 !important;
  font-size: $font-size-15px !important;
}


.arrow {
  border: 1px solid #C4C4C4;
}
