@import '../../../styles/style-variables.scss';

.cw-custom-popup-center {
    font-size: $font-size-12px;
    margin-top: 10rem !important;
    // margin-left: 30rem !important;
    margin: auto auto;
    // float: center;
    // min-width: 800px !important;
    max-width: 500px !important;
    min-height: 400px !important;

    .pratice-container {
        margin-top: -2rem;
    }


    .modal-content {
        padding: 1rem;
        background: #F8FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        // min-height: 13.5rem !important;
        height: 250px !important;
    }

    .challenge-header {
        width: 500px;
        // height: 350px;
        position: absolute;

        left: 0rem;
        top: 0rem;
        border-radius: 20px;
    }

    .challenge-footer {
        width: 500px;
        height: 70px;
        position: absolute;
        left: -2rem;
        top: 0.2rem;
        // bottom: 3rem;
        border-radius: 20px;
        z-index: 1;
    }

    .white-tick-icon {
        width: 5rem;
        margin-left: 3.5rem;
        margin-top: 2rem;
    }

    .red-cross-icon {
        width: 5rem;
        margin-left: 3.5rem;
        margin-top: 1rem;
    }

    .success-bg-stars {
        position: absolute;
        width: 500px;
        height: 250px !important;
        border-radius: 20px;
        top: -0.1rem;
    }

    .hand-icon {
        position: absolute;
        height: 15.5rem;
        // width: 12rem;
        left: -2rem;
        top: -10.95rem;
    }

    .for-wrong-answer {
        top: 0.1rem !important;
    }

    .position {
        position: relative;
    }

    .image-size {
        width: 2rem;
    }

    .header-text {
        font-size: 22px;
        font-weight: 700;
    }

    .close-icon {
        width: 30px;
        float: right;
        position: relative;
        top: -1.3rem;
        right: 0.5rem;
    }

    .sub-text {
        font-size: 0.9rem;
        font-weight: 500;
        margin-left: 2rem;
    }

    .image-width {
        width: 180px;
    }

    .name-header {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: $font-size-22px;
        line-height: 27px;
        // text-align: center;
        color: #000000;
    }

    .sub-title {
        font-size: $font-size-15px; 
        font-weight: 400;
        color: $sub-text-color;
    }

    .challege-yourself {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 800;
        font-size: $font-size-18px;
        line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .take-challenge {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        //   line-height: 26px;
        width: 180px;
        height: 51px;
        background: #6B52C8;
        border-radius: 30px;
        position: relative;
        z-index: 10;
        padding-top: 0.85rem;
        padding-left: 1rem;
        padding-right: 1rem;
        top : 1.5rem;
    }

    .view-correct-answer {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-18px;
        color: #6B52C8;
        //   line-height: 26px;
        // width: 180px;
        height: 50px;
        background: white;
        border: 1px solid #6B52C8;
        border-radius: 30px;
        position: absolute;
        z-index: 10;
        padding-top: 0.65rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        top : 8.5rem;
        right: 0rem;
        // bottom: 0;
    }


    .margin-left {
        margin-left: 5.5rem;
        margin-bottom: 1rem;
        font-weight: 800;
        font-size: 1rem;
    }

    .concepts-covered-wrapper {
        min-height: 5rem;
        max-height: 26rem;
        // max-height: 19.5rem;
        overflow: scroll;
        flex-wrap: wrap;
        overflow-x: hidden;

        .concept-title {
            font-size: $font-size-19px;
            font-weight: 700;
            color: #263238;
        }

        .elo-title {
            font-size: $font-size-16px;
            font-weight: 600;
            color: $sub-text-color;
        }
    }


    // div::-webkit-scrollbar {
    //     display: none;
    // }
}