@import '../../../../styles/style-variables.scss';

.cw-custom-modal-right {
    margin: 0 !important;
    margin-top: 5rem !important;
    float: right;
    width: 660px !important;
    font-size: $font-size-12px;
    background: #FFFFFF;
    box-shadow: -4px 8px 8px 4px rgba(0, 0, 0, 0.25);
    min-height: 670px;


    .modal-content {
        border: none !important;
    }

    .abs-image {
        position: absolute;
        z-index: 1;

    }

    .header-image {
        width: 660px;
        height: 46px;
    }


    .bookmark {
        width: 25px;
    }

    // .abs-image {
    //     position: absolute;
    //     z-index: 1;
    //     height: 2px !important;

    // }

    .modal-body {
        padding: 1 !important;

        .row-width {
            max-width: 630px;
            background: #FFFFFF;
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            height: 46px;
        }

        .selected-bg {
            background-color: #6B52C8 !important;
            color: #FFFFFF !important;
        }
    }

    .header-text {
        font-size: 22px;
        font-weight: 700;
    }

    .close-icon {
        width: 25px;
        margin-top: -10px;
        margin-right: 15px;
        // float: right;
        position: relative;
        z-index: 50;
    }



    // .row-width {
    //     max-width: 448px;
    //     background: #FFFFFF;
    //     box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    //     border-radius: 10px;
    //     height: 56px;
    // }




    .view-button {
        font-size: $font-size-18px;
        font-weight: 600;
        color: #455B64;
        font-family: 'Mont';
        font-style: normal;
        border-radius: 10px;
        cursor: pointer;
    }

    .border-top-right-radius {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;

    }

    .border-top-left-radius {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;

    }



    .content-width {
        .sr-no {
            font-size: $font-size-20px;
            font-family: 'Mont';
            font-style: normal;
            font-weight: 700;
            margin-top: -3px;
        }

        p {
            margin-bottom: 0.15rem !important;
            font-size: $font-size-20px;
            font-family: 'Mont';
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
    }



    .view-solution {

        p {
            margin-bottom: 0.15rem !important;
            font-size: $font-size-18px;
            font-family: 'Mont';
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
        }

        img {
            max-width: 100% !important;
            height: 7rem !important;
            object-fit: contain;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }



        ul,
        li {
            font-size: $font-size-18px;
        }

    }


    .view-answer {

        width: 630px;
        min-height: 201px;
        background: #F2F0F9;
        border-radius: 10px;

        p {
            margin-bottom: 0.15rem !important;
            font-size: $font-size-18px;
            font-family: 'Mont';
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            padding-left:10px;
            padding-top:5px;
            // text-align: center;

        }

        img {
            width: 500px !important;
            height: 151px !important;
        }

        ul,
        li {
            font-size: $font-size-18px;
        }
    }

    .question-divider-image-width {
        width: 60rem;
        height: 0.12rem;
    }
}