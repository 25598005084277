@import '../../../styles/style-variables.scss';

.clicker-assessment-engine {
    margin-left: -1.8rem;
    position: relative;

    // .question-wrapper {
    //     max-width: 67rem;
    // }
   
}