@import '../../styles/style-variables.scss';

.elo-list-container {


    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: none !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .header-image {
        width: 900px;
        height: 80px;
        position: absolute;
        top: 4.2rem;
        right: 0;
    }

    .header-wrapper {
        .chapter-image-size {
            width: 3rem;
        }

        .header-text {
            font-size: $font-size-28px;
            font-weight: 800;
        }

        .percentage-text {
            font-size: $font-size-20px;
            font-weight: 700;
        }

        .completed-text {
            font-size: $font-size-20px;
            font-weight: 500;
        }

        .divider-for-title {
            // width: 1rem;
            height: 2.5rem;
            border: 1px solid #CECECE;
        }

        .header-arrow-size {
            width: 1.5rem;
            margin-right: 2rem;
            position: relative;
        }

        .divider-image-width {
            width: 100%;
            height: 1.5px;
        }

        .sub-header-text {
            color: #727272;
            font-weight: 600;
            font-size: $font-size-20px;
        }
    }

    .chapter-intro-container {

        .intro-text {
            font-size: $font-size-24px;
            font-weight: 600;
        }
        
        .chapter-button {
            width: 7rem;
            background-color:#6B52C8;
            color: white;
            border-radius: 20px;
            
        }

        .button-text {
            padding: 0.35rem 1.2rem;
        }
    }

    .concept-container {

        .levels-text {
            color: #A19F9F;
            font-size: $font-size-16px;
            font-weight: 600;
        }

        .concept-text {
            font-size: $font-size-24px;
            font-weight: 600;
        }

        .level-header-title {
            border-radius: 10px;
            padding: 0.8rem 1rem;
            font-size: $font-size-16px;
            font-weight: 600;
            color: #353535;
        }


        .concept-arrow-size {
            width: 1.8rem;
        }

        .concept-button {
            width: 7rem;
            background-color:#6B52C8;
            color: white;
            border-radius: 20px;
            
        }

        .button-text {
            padding: 0.35rem 1.2rem;
        }

        .lock-icon-size {
            width: 1rem;
        }

        .circle-width {
            max-width: 4% !important;
        }

        .outer-circle {
            width: 1rem;
            height: 1rem;
            padding: 0.8rem;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .inner-circle {
            width: 0.5rem;
            height: 0.8rem;
            padding: 0.5rem;
            border-radius: 50px;
        }

        .elo-title {
            font-size: $font-size-18px;
            font-weight: 600;
        }


    }



}