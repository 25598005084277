@import '../../../styles/style-variables.scss';
.student-answered-status-board {
    width: 15rem;
    min-height: 100vh;
    background-color: rgb(240, 248, 240);
    padding: 1rem;


    .timer-text {
        font-size: $font-size-18px !important;
        color: black;
    }

    .button-wrapper {
        max-width: 14rem;
        max-height: 10rem !important;
        flex-wrap: wrap;
    }

    .student-roll-number-button {
        // border: 1px solid darkgray;
        color: white;
        background-color:#20D2F6;
        // padding: 0.2rem 1rem;
        border-radius: 10px;
        width: 3.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-20px;
        font-weight: 600;

    }

    .enable-button {
        background-color: #000080 !important;
    }
}

.large-student-answered-status-board {
    width: 30rem;
    max-height: 100vh;
    background-color: rgb(240, 248, 240);
    padding: 1rem;
    overflow: scroll;
    // margin-bottom: 20rem;


    .timer-text {
        font-size: $font-size-36px !important;
        color: black;
        font-weight: 600;
    }

    .button-wrapper {
        max-width: 30rem;
        // max-height: 10rem !important;
        flex-wrap: wrap;
    }

    .pause-button-image-size {
        width: 1.4rem;
    }

    .student-roll-number-button {
        // border: 1px solid darkgray;
        color: white;
        background-color: #20D2F6;
        // padding: 0.2rem 1rem;
        border-radius: 10px;
        width: 5rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 42px;
        font-weight: 600;

    }

    .enable-button {
        background-color: #000080 !important;
    }

}