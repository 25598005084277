@import '../../../styles/style-variables.scss';
.cw-glossary {

    .left-border {
        width: 2rem;
        border-right: 8px solid #BFB9FF;
        // border-top-right-radius: 10px;
        // border-bottom-right-radius: 10px;
    }

    .formula-card {
        border: 1.5px solid #6B52C8;
        border-radius: 10px;
        padding: 1.5rem;
        padding-bottom: 0rem !important;
        background-color: #F9F9FF;
    }

    .statement-card {
        border: 1.5px solid #6B52C8;
        border-radius: 10px;
        padding: 1.5rem;
        padding-bottom: 0rem !important;
        background-color: #F0EFFF;
    }
}