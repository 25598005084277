@import '../../../../styles/style-variables.scss';
.quotes-title-for-practice-flow {
    margin-left: 6rem;
    width: 90%;
    .quote-text-wrapper {
        // border: 1px solid lightgray;
        width: 85%;
        height: 80%;
        padding: 1rem;
        border-radius: 10px;
        margin-top: 12rem;
        
    }

    

    .quote-text {
        font-size: $font-size-32px;
        font-weight: 800;
        font-style: italic;
    }

    .question-number {
        font-size: $font-size-32px;
        font-weight: 700;
    }

    .quiz-button {
        background-color: #06C5D9;
        color: white;
        font-size: $font-size-32px;
        border-radius: 50px;
        padding: 0.2rem 1rem;
    }

    .subject-text {
        padding-right: 10px;
        border-right: 2px solid darkgray;
        font-size: $font-size-32px;
    }
}