@import '../../../../styles/style-variables.scss';

.binary-question {

    // min-height: 12.5rem;

    .option-button {
        border: 1px solid #6f42c1;
        border-radius: 10px;
        margin-bottom: 2rem;
        margin-top: 1rem;
        padding: 0.8rem 4rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
        background: linear-gradient(180deg, #FFFFFF 0%, #D1C9ED 100%);
        font-size: $font-size-18px;
        font-weight: 600;

    }

    .selected-option-bg {
        background: #6f42c1 !important;
        color: #FFFFFF !important;
    }
}