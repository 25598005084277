@import '../../../styles/style-variables.scss';
.learning-header {
    .image-width {
        width: 2.5rem;
    }

    .title {
        font-size: $font-size-28px;
        font-weight: 700;
    }

    .bookmark-image-size {
        width: 2rem;
    }
}