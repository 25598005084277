@import '../../../../styles/style-variables.scss';
.practice-questions {

    .bookmark {
        width: 1.4rem;
        padding-top: 3.5px;
    }

    .title-width-on-selection {
        width: 32rem !important;
        background: rgba(107, 82, 200, 0.1);
    }

    // .title-bold-selection {

    //     .sr-no {
    //         font-weight: 800 !important;
    //     }

    //     p {
    //         font-weight: 800 !important;
    //     }
        
    // }

    .question-title-image {
        margin-left: 1.7rem;
    }

    .content-width {
        max-width: 70rem;

        .sr-no {
            font-size: $font-size-18px;
            font-family: 'Mont';
            font-style: normal;
            color: #FFFFFF;
            font-weight: 500;
            margin-top: -4px;
            padding-top: 2px;
            padding-left: 12px;
            background-color: #FF9501;
            min-width: 32px;
            height: 32px;
            border-radius: 20px;
        }
        .sr-no-padding {
            padding-left: 9.5px !important;
        }

        p {
            margin-bottom: 0.15rem !important;
            font-size: $font-size-18px;
            font-family: 'Mont';
            font-style: normal;
            font-weight: 500;
            color: #353535;;
            // line-height: 26px;
        }
    }

    .view-button {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-18px;
        // line-height: 26px;
        width: 12.125rem;
        height: 3rem;
        border: 2px solid #FF9501;
        /* Theme colour 1 */
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        padding: 0.5rem 1rem;
        cursor: pointer;
    }

    .view-answer {
        margin-left: 0.7rem;
        cursor: pointer;
    }

    .button-wrapper {
        margin-left: 0.8rem;
    }

   

}