@import '../../../../styles/style-variables.scss';
.answer-tab {
    .row-max-width-for-answer {
        // width: 35rem;
        background: none !important;
        // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        // border-radius: 10px;
        // height: 46px !important;
    }

    .only-correct-answer-width {
        width: 17.5rem !important;
    }

    .only-correct-answer {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .answer-status-text {
        font-size: $font-size-20px;
        font-weight: 500;
        color: #455B64;
        cursor: pointer;
    }

    .active-tab {
        background-color: #6B52C8;
        color: white !important;
        // border-top-left-radius: 10px;
        // border-bottom-left-radius: 10px;
        border-radius: 50px;
        padding: 0.5rem 3rem !important;
    }

    .active-my-answer-tab {
        background-color: #6B52C8;
        color: white !important;
        border-radius: 50px;
        padding: 0.5rem 3rem !important;
    }

    .in-active-tab {
        background-color: #E0E0E0;
        color: #A1A1A1 !important;
        border-radius: 50px;
        padding: 0.5rem 3rem !important;
    }
}