@import '../../../styles/style-variables.scss';

.home-chapter-list {
    // background-color: #F5F6FA;
    .menu-heading {
        font-size: 25px !important;
        font-weight: 800 !important;
    }

    .chapter-list-wrapper {
        // overflow-x: scroll;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: none !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }
    
    .enable {
        background-color: #e3f1f3 !important;
        border: 2px solid #1e9eab !important;
    }

    .locked-bg-colour {
        background :  #efeff3 !important;
    }

    .chapter-card {

        display: flex;
        background: #FFFFFF;
        border: 1.5px solid #eeeeee;
        padding: 1.5rem;
        justify-content: space-between;
        align-items: center;
        width: 99%;



        .chapter-icon {
            width: 50px;
        }

        .chapter-title {
            font-size: 18px;
            font-weight: 900;
        }

        .arrow-gray {
            width: 12px;
            margin-right: 15px;
        }

        .no-chapter {
            justify-content: center !important;
            align-items: center !important;
        }

        .lock-image-size {
            width: 1.2rem;
            margin-right: 15px;
        }
    }

    .chapter-card:hover {
        border: 1px solid #21B8C7;
        background-color: #FAFFFF;
    }
}