@import '../../../styles/style-variables.scss';

.select-user {
    padding: 0 !important;
    background: #F8FAFC;


    .container-height {
        height: 100vh;
    }

    .padding-for-content {
        padding-top: 4.5rem;
        padding-left: 10rem;
    }

    .continue-as {
        font-size: $font-size-28px;
        font-weight: 800;
        // margin-left: 10.2rem;
    }

    .sub-text {
        font-size: $font-size-18px;
        font-weight: 500;
        // margin-left: 4.1rem;
    }

    .header-abs-div {
        position: absolute;
        top: -1rem
    }

    .footer-abs-div {
        position: absolute;
        bottom: 0.1rem
    }


    .login-header {
        // width: 96rem;
        width: 100vw;
        height: 6.3rem;
    }

    .login-footer {
        // width: 96rem;
        width: 100vw;
        height: 5.5rem;
    }

    .student-container {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // min-width: 80rem;
        // min-height: calc(100vh - 240px);
        max-height: calc(100vh - 270px);
        overflow-y: auto;
        // margin-left: 5rem;
        display: flex;
        justify-content: start;





        .student-card {
            border: 4px solid #CDFFF8;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            width: 13.5rem;
            min-height: 13.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-right: 8rem;
            margin-bottom: 4.2637rem;
            position: relative;

            .std-image {
                width: 9rem;
            }

            .user-name {
                font-size: $font-size-20px;
                font-weight: 600;
            }

            .green-tick-mark {
                width: 2.125rem;
                position: absolute;
                top: 1px;
                right: 1px;
            }

            .profile-avatar {
                overflow: hidden;
                border-radius: 50%;
                height: 150px;
                width: 150px;

                img {
                    height: 150px;
                    width: 150px;
                }
            }


        }

        .selected-user-bg {
            background: #CDFFF8;
        }

        .std-card-margin-top {
            margin-top: 0.5rem;
        }

    }

    .continue-container {

        bottom: 2.8rem;
        // position: absolute;
        left: 42.5rem;
        // top:0.2rem;
        z-index: 10;
        margin-top: 1rem;

        .continue-button {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            width: 12rem;
            min-height: 2.8rem;
            text-align: center;
            font-size: $font-size-18px;
            font-weight: 600;

        }

        .continue-enabled {
            color: white;
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);

        }

        .continue-disabled {
            background: #DFDFDF;
            color: #9D9D9D;

        }

    }







}