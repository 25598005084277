@import '../../../styles/style-variables.scss';

.student-signup {
    padding: 0 !important;
    background: #FFFFFF;


    .container-height {
        height: 100vh;
        gap: 15rem;
    }

    .item-2 {
        margin-top: -2.2rem;
    }

    .cw-input-label-1 {
        font-size: $font-size-16px;
        font-weight: 600;
    }


    .header-abs-div {
        position: absolute;
        top: 0rem
    }

    .footer-abs-div {
        position: absolute;
        bottom: 0rem
    }

    .login-header {
        // width: 96rem;
        width: 100vw;
        height: 6.3rem;
    }

    .login-footer {
        // width: 96rem;
        width: 100vw;
        height: 6.5rem;
    }

    .signup-wrapper {
        padding-top: 5rem;
    }

    .welcome-screen {
        .welcome-text {
            font-size: $font-size-32px;
            font-weight: 800;
        }

        .favourite-text {
            font-size: $font-size-18px;
            font-weight: 500;
        }

        .already-wrapper {
            right: 5rem;
        }

        .login-button {
            color:  #21b8c7;
            font-weight: 600;
            font-size: $font-size-18px;
            border: 1px solid #21b8c7;
            border-radius: 20px;
            padding: 5px 15px;
        }
    }

    .poster-image-size {
        width: 28.5rem;
    }

    .react-player__preview {
        height: auto !important;
    }

    .student-learning-count {
        margin-left: 10rem;
    }



    .welcom-content-wrapper {
        min-height: 25.5rem;

        .video-container {

            .text-margin {
                margin-right: 6.3rem;
            }

            .image-size {
                width: 3rem;
            }


            .student-count {
                font-size: $font-size-18px;
                font-weight: 800;
                color: #535353;
            }

            .student-text {
                font-size: $font-size-14px;
                color: #535353;
                font-weight: 500;
            }

            .video-style {

                margin-left: 15rem;

                div {
                    width: fit-content !important;
                    height: auto !important;
                }

                video {
                    width: 28.5rem !important;
                    max-height: 14.5rem;
                    border-radius: 10px;
                }

                // video {
                //     object-fit:contain;
                // }

            }


        }


    }



    .feedback-container {

        .quote-image-size {
            width: 1.5rem;
        }

        .quote-margin {
            margin-left: -2.5rem;
        }

        .quote-down-margin {
            margin-left: 10rem;
        }

        .feedback-text {
            font-size: $font-size-20px;
            font-style: italic;
            font-weight: 600;
            color: #7E7E7E;
            text-align: justify;
            max-width: 28rem;
        }

        .student-name {
            font-size: $font-size-16px;
            font-weight: 800;
            color: #353535;
        }

        .quote-margin-top {
            margin-top: 6rem;
        }

    }

    .continue-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.7rem 0rem;
        width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
    }

    .continue-button-disabled {
        background: #DFDFDF !important;
        color: #9D9D9D !important;
    }

    .back-button {
        // background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // border-radius: 30px;
        // color: white;
        // padding: 0.7rem 0rem;
        width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;

        // width: 13.75rem;
        // height: 3rem;
        box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        border: 2px solid transparent;
        position: relative;
        padding-top: 0.7rem;
        padding-bottom: 0.6rem;
        display: inline-block;
        font-size: $font-size-18px;
        // font-weight: 600;
        color: #69C7C1;
        //  margin-top: 0.5rem;


    }


    .back-button::before {
        content: "";
        width: 12rem;
        height: 3.2rem;
        position: absolute;
        inset: 0;
        border-radius: 30px;
        padding: 2px;
        /* control the border thickness */
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }





    .heading {
        font-size: $font-size-32px;
        font-weight: 800;
        margin-left: 10rem;
    }

    .sub-heading {
        color: #979797;
        font-size: $font-size-18px;
        font-weight: 600;
        margin-left: 10rem;
    }

    .country-code {
        min-width: 3.2rem !important;
        font-size: $font-size-22px;
        font-weight: 600;
        color: rgba(69, 91, 100, 0.5);

    }

    .country-code-min-width {
        min-width: 4.8rem !important;
    }

    .country-code-color {
        color: black !important;
    }

    .country-code-down-arrow {
        width: 1.5rem;
        margin-top: -0.1rem;
        margin-left: 0.4rem;
    }

    .country-code-down-arrow-margin {
        margin-left: -0.5rem !important;
    }

    .country-code-separator {
        height: 2.5rem;
        margin-top: -0.1rem;
        // margin-left: 0.5rem;
    }

    .student-signup-wrapper {
        margin-left: 9.2rem;


        .student-gender-image {
            // width: 6rem;
            height: 9rem;
        }

        .student-signup-text {
            font-size: $font-size-16px;
            font-weight: 600;
        }

        .cw-input-1 {

            width: 80%;
            height: 3.5rem;
            background: $gray;
            border: none;
            font-size: $font-size-20px;
            padding: 1rem;
            padding-top: 0.9rem !important;
            color: $app-black !important;
            // background: #F6F7F5;
            border-radius: 60px;
            position: relative;
            font-weight: 500;
            position: relative;

        }

        .cw-input-wrapper {
            width: 76% !important;
            height: 3.5rem;
            // background: $gray;
            border: none;
            font-size: $font-size-22px;
            padding: 1rem;
            padding-top: 0.6rem !important;
            color: $app-black !important;
            background: #F6F7F5;
            border-radius: 60px;
            position: relative;
            margin-left: 12.5px;

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                appearance: none;
                margin: 0;
                /* Optional: You can also adjust the margin if needed */
            }
        }

        .cw-input-1-for-mobileNumber {
            width: 70%;
            // height: 3.5rem;
            background: $gray;
            border: none;
            font-size: $font-size-22px;
            // padding: 1rem;
            // padding-top: 0.9rem !important;
            color: $app-black !important;
            // background: #F6F7F5;
            border-radius: 60px;
            position: relative;
            font-weight: 600;
            margin-left: 5px;
        }



        .cw-input-1::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #b2aeab;
            opacity: 1;
            font-weight: 600;
            /* Firefox */
        }

        .cw-input-1:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #b2aeab;
        }

        .cw-input-1::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #b2aeab;
        }



        .cw-input-1:focus {
            outline: none;
        }



        .cw-input-1-for-mobileNumber::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #b2aeab;
            opacity: 1;
            font-weight: 600;
            /* Firefox */
        }

        .cw-input-1-for-mobileNumber:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #b2aeab;
        }

        .cw-input-1-for-mobileNumber::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #b2aeab;
        }



        .cw-input-1-for-mobileNumber:focus {
            outline: none;
        }




        .input-subtext {
            color: #979797;
            font-size: $font-size-15px;
            font-weight: 500;
        }

        .optional-text {
            // position: absolute;
            // right: 10.5rem;
            // bottom: 2.1rem;
            color: #b2aeab;
            opacity: 1;
            margin-left: -5.5rem;
            position: relative;
        }

        .invalid-message {
            color: red;
            font-size: $font-size-15px;
        }

        .country-code-drop-down {
            width: 31rem;
            height: 410px;
            min-height: 120px;
            // overflow-y: scroll;
            background: white;
            border-radius: 20px;
            border: 1px solid #d7d8d6;
            position: relative;
            z-index: 10;

            .country-header-div {
                position: absolute;
                top: 0;
                //  z-index: 9999;


                .country-header-img {
                    width: 31rem;
                    height: 14rem;
                    // height: 10rem;
                    border-radius: 20px;

                }
            }

            .country-footer-div {
                position: absolute;
                bottom: 0;
                //  z-index: 9999;


                .country-header-img {
                    width: 31rem;
                    // height: 1rem;
                    // height: 10rem;
                    border-radius: 20px;
                }
            }

            .country-close-icon {
                position: absolute;
                z-index: 99999;
                top: 0.6rem;
                right: 0.8rem;

                .close-icon {
                    width: 1.5rem;
                }
            }

            .select-country {
                position: absolute;
                top: 1.8rem;
                left: 1.5rem;
                font-size: $font-size-17px;
                font-weight: 700;
            }

            .country-search-div {
                position: absolute;
                z-index: 9999996;
                top: 5rem;

                .country-search {
                    width: 25px;
                    margin-left: 2rem;
                    // margin-top: -0.2rem;
                    margin-top: -1.7rem;
                }

            }


            .search {
                position: sticky;
                top: 3rem;
                z-index: 999999;
                left: 30px;
                width: 90%;
                margin-top: 3.3rem;

                input {
                    background: #F6F7F5;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 30px;
                    min-height: 3rem;
                    // background: #F6F7F5;
                    border: 1px solid #F6F7F5;
                    // border-radius: 50px;
                    font-size: $font-size-14px;
                    width: -webkit-fill-available;
                    text-indent: 35px;
                    margin-left: 1rem;
                }



                input:focus {
                    background: #F6F7F5;
                    border: 1px solid #F6F7F5;
                }

                label {
                    margin: 0 !important;
                    font-size: 14px;
                }

            }

            .dropdown-scroll {
                overflow-y: auto;
                max-height: 15.625rem;
                min-height: 7.5rem;
            }

            .dropdown-list {
                .country-name {
                    font-size: $font-size-15px;
                }

                .country-name:hover {
                    font-weight: 700;
                }
            }


            .no-data {
                label {
                    font-size: 14px;
                }
            }

        }

    }

    .dash-wrapper {
        // position: absolute;
        // left: 24rem;
        // top: 2.5rem;
        // z-index: 999999;
        position: relative;
        top: 2rem;
    }


    .dash-colour {
        // color: #06C5D9;
        width: 5.25rem;
        // height: 5px;
        border: 5px solid #E0E0E0;
        // background: #E0E0E0;
        border-radius: 10px;
        margin-right: 2rem;
    }

    .enable-dash {
        border: 5px solid #06C5D9;
    }


    .otp-wrapper {
        margin-left: 10.2rem;
        min-height: 27rem;

        .custom-toast-for-invalid-otp {
            position: absolute;
            left: 14rem;
        }
    }

    .info-size {
        margin-left: 10.2rem;
        width: 2rem;
    }

    .info-text {
        width: 40rem;
        color: #455B64;
        font-size: $font-size-18px;
        font-weight: 700;
    }

    .pass-code-wrapper {
        margin-left: 10.2rem;
        min-height: 21rem;


        .custom-toast-for-invalid-passcode {
            position: absolute;
            left: 14rem;
        }

    }

    .agree-text {
        font-size: $font-size-17px;
        font-weight: 500;
        color: #979797;

        .conditions-text {
            color: #69C7C1 !important;
        }
    }

    .school-confirmation-wrapper {
        // margin-left: 10.2rem;
        min-height: 26rem;
        margin-top: 5rem;

        .from-text {
            font-size: $font-size-19px;
            font-weight: 600;
        }

        .school-name {
            font-size: $font-size-22px;
            font-weight: 700;
        }


        .school-branch {
            font-size: $font-size-19px;
            font-weight: 600;
        }

        .button-wrapper {
            margin-top: 5rem;
        }

        .yes-button {
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            color: white;
            padding: 0.7rem 0rem;
            width: 15rem;
            font-size: $font-size-18px;
            font-weight: 500;
            margin-top: 1.2rem;
        }


        .no-button {
            // background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            // border-radius: 30px;
            // color: white;
            // padding: 0.7rem 0rem;
            width: 17rem;
            font-size: $font-size-18px;
            font-weight: 500;
            margin-top: 1.2rem;
    
            // width: 13.75rem;
            // height: 3rem;
            box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            border: 2px solid transparent;
            position: relative;
            padding-top: 0.7rem;
            padding-bottom: 0.6rem;
            display: inline-block;
            font-size: $font-size-18px;
            // font-weight: 600;
            color: #69C7C1;
            //  margin-top: 0.5rem;
    
    
        }
    
    
        .no-button::before {
            content: "";
            width: 17rem;
            height: 3.2rem;
            position: absolute;
            inset: 0;
            border-radius: 30px;
            padding: 2px;
            /* control the border thickness */
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            pointer-events: none;
        }


    }




}