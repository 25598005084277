@import '../../styles/style-variables.scss';

.chapter-assessment-performance-report {

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .school-name {
        font-size: $font-size-24px;
        font-weight: 700;
    }

    .school-name-new {
        font-size: $font-size-22px;
        font-weight: 600;
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .export-button {
        background: #21b8c7;
        border-radius: 10px;
        font-size: 0.9rem;
        font-weight: 600;
        color: white;
        padding: 0.5rem 1rem;
    }

    .school-name {
        font-size: $font-size-24px;
        font-weight: 700;
    }

    .correct-wrapper {
        margin-right: 2rem;
    }

    .correct-text {
        background-color: #CFFFDD;
        border-radius: 10px;
        padding: 0.2rem 1rem;
        font-size: $font-size-16px;
    }

    .incorrect-text {
        background-color: #FFCCCC;
        border-radius: 10px;
        padding: 0.2rem 1rem;
        font-size: $font-size-16px;
    }

    .student-detail-header {
        color: white;
        font-size: $font-size-18px;
        font-weight: 500;
        background-color: #21B8C7;
        padding: 0.4rem 1rem;
        max-width: 98%;
        border-radius: 8px;
    }



    .student-basic-details {
        .student-details-wrapper {
            margin-left: 0.15rem;
        }

        .student-name {
            font-size: $font-size-17px;
            font-weight: 600;
        }

        .student-name-border {
            border-right: 1.2px solid #DCDCDC;
        }

        .total-score {
            font-size: $font-size-17px;
            font-weight: 600;
        }

        .score-circle {
            width: 3.8rem;
            height: 3.8rem;
            border-radius: 50%;
            border: 3px solid #21B8C7;

            .mark-text {
                font-size: $font-size-16px;
                font-weight: bold;
            }

            .divider {
                border-top: 1.8px solid #DDDDDD;
                width: 2.5rem;
            }

        }

        .class-average-indication {
            margin-right: 2rem;
            font-size: $font-size-17px;
            font-weight: 600;
        }

        .class-average-indicator {
            background-color: #DA5307;
            width: 0.25rem;
            height: 1.5rem;
        }

        .competency-table {
            .table {
                width: 100% !important;
                margin-bottom: 0px !important;
            }

            .first-header-bg {
                background-color: #C8F6FF !important;
                border-top-left-radius: 20px !important;
                border-top-right-radius: 20px !important;
                border: none;
            }




            .second-header-bg {
                background-color: #DDFFE9 !important;
                color: #343434;
                font-size: $font-size-12px;
                font-weight: 600;
            }

            .suit-case-image-size {
                width: 1.1rem;
            }

            .class-engagement-title {
                font-size: $font-size-16px;
                font-weight: 600;
                color: #FFFFFF;
            }

            .class-header th {
                height: 12px;
                line-height: 12px;
            }

            .class-body td {
                height: 12px;
                line-height: 12px;
                background-color: #FFFFFF;
            }

            .more-button-wrapper {
                background-color: white;
                border-radius: 6px;
                padding: 0.2rem 0.5rem;

                .more-button {
                    color: #407BFF;
                    font-size: $font-size-11px;
                    font-weight: 600;
                }
            }

            .class-text {
                font-size: $font-size-14px;
                font-weight: 500;
                // color: #3888FF;
            }

            .assignment-count {
                font-size: $font-size-14px;
                font-weight: 500;
            }

            .border-bottom-left-radius {
                // border-bottom-left-radius: 10px;
                // border-radius: 10px;
            }

            .image-size {
                width: 1.1rem;
            }



        }
    }

    .performance-overview {
        .total-score {
            font-size: $font-size-17px;
            font-weight: 600;
        }

        .score-circle {
            width: 3.8rem;
            height: 3.8rem;
            border-radius: 50%;
            border: 3px solid #21B8C7;

            .mark-text {
                font-size: $font-size-16px;
                font-weight: bold;
            }

            .divider {
                border-top: 1.8px solid #DDDDDD;
                width: 2.5rem;
            }

        }

        .class-average-indication {
            margin-right: 2rem;
            font-size: $font-size-17px;
            font-weight: 600;
        }

        .class-average-indicator {
            background-color: #DA5307;
            width: 0.3rem;
            height: 1.5rem;
        }

        .row-max-width {
            max-width: 100%;
            // gap: 5rem;
        }

        .column-max-width {
            // max-width: 100% !important;
        }

        .question-type-border-container {
            position: relative;
            // text-align: center;
            border: 1px solid #6D8DFF;
            // margin: 20px;
            // width: inherit;
            // max-width: 35rem;
            min-height: 4rem;
            border-radius: 10px;
            padding: 1.5rem;

            .bordered-text {
                background-color: white;
                position: absolute;
                top: -0.75em;
                /* Adjust this value as needed */
                left: 2.5%;
                // transform: translateX(-50%);
                padding: 0 3px;
                font-size: $font-size-18px;
                font-weight: 600;
                /* Adjust padding as needed */
            }

            .question-type-name {
                color: #000000;
                font-size: $font-size-16px;
                font-weight: 500;
                margin-left: 0.3rem;
            }

            .mark-border {
                border-right: 1.2px solid #DCDCDC;
            }

            .exam-pad-icon {
                width: 1rem;

            }

            .mark-scored {
                color: #077CE5;
                font-size: $font-size-16px;
                font-weight: 500;
            }

            .question-type-wrapper {
                max-width: 100%;
                background-color: #DAF4FF;
                height: 1.5rem;
                border-radius: 20px;
                position: relative;
                // padding: 1rem;

                .inner-progress-bar {
                    // position: relative;
                    height: 1.5rem;
                    border-radius: 20px;
                }

                .average-indicator {
                    background-color: #DA5307;
                    position: absolute;
                    width: 0.25rem;
                    height: 1.5rem;
                    z-index: 0;
                    left: 0%;
                }

                .average-number-absolute {
                    position: absolute;
                    top: -1.2rem;
                }



                // .question-name-absolute {
                //     position: absolute;
                // }




                .border-right-radius {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }

            .percentage-text {
                font-size: $font-size-18px;
                font-weight: 700;
            }

        }

        .case-study-skill-border-container {
            position: relative;
            // text-align: center;
            border: 1px solid #6FB7FF;
            // margin: 20px;
            // width: inherit;
            max-width: 35rem;
            min-height: 5rem;
            border-radius: 10px;
            padding: 1.5rem;

            .card {
                border-radius: 10px !important;
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            }


            .skill-table {
                .table {
                    width: 100% !important;
                    margin-bottom: 0px !important;
                }

                .first-header-bg {
                    background-color: #C8F6FF !important;
                    border-top-left-radius: 20px !important;
                    border-top-right-radius: 20px !important;
                    border: none;
                }




                .second-header-bg {
                    background-color: #DDFFE9 !important;
                    color: #343434;
                    font-size: $font-size-12px;
                    font-weight: 600;
                }

                .suit-case-image-size {
                    width: 1.1rem;
                }

                .class-engagement-title {
                    font-size: $font-size-16px;
                    font-weight: 600;
                    color: #FFFFFF;
                }

                .class-header th {
                    height: 12px;
                    line-height: 12px;
                }

                .class-body td {
                    height: 16px;
                    line-height: 16px;
                    background-color: #FFFFFF;
                }

                .more-button-wrapper {
                    background-color: white;
                    border-radius: 6px;
                    padding: 0.2rem 0.5rem;

                    .more-button {
                        color: #407BFF;
                        font-size: $font-size-11px;
                        font-weight: 600;
                    }
                }

                .class-text {
                    font-size: $font-size-14px;
                    font-weight: 500;
                    // color: #3888FF;
                }

                .assignment-count {
                    font-size: $font-size-14px;
                    font-weight: 500;
                }

                .border-bottom-left-radius {
                    // border-bottom-left-radius: 10px;
                    // border-radius: 10px;
                }

                .image-size {
                    width: 1.1rem;
                }



            }


            .bordered-text {
                background-color: white;
                position: absolute;
                top: -0.75em;
                /* Adjust this value as needed */
                left: 2.5%;
                // transform: translateX(-50%);
                padding: 0 3px;
                font-size: $font-size-18px;
                font-weight: 600;
                /* Adjust padding as needed */
            }

            .question-type-wrapper {
                max-width: 100%;
                background-color: #E0EFFF;
                height: 2.3rem;
                border-radius: 20px;
                // padding: 1rem;

                .inner-progress-bar {
                    height: 2.3rem;
                    border-radius: 20px;
                }

                .question-type-name {
                    color: #000000;
                    font-size: $font-size-16px;
                    font-weight: 500;
                }

                .average-indicator {
                    background-color: #DA5307;
                    position: absolute;
                    width: 0.3rem;
                    height: 2.25rem;
                    z-index: 0;
                    left: 0%;
                    // border-radius: 20px !important;
                }

                .average-number-absolute {
                    position: absolute;
                    top: -1.4rem;
                }

                .question-name-absolute {
                    position: absolute;
                }





                .border-right-radius {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }

            .skill-status-image {
                width: 1.3rem !important;
            }

            .percentage-text {
                font-size: $font-size-18px;
                font-weight: 700;
            }

        }

        .chapter-list-border-container {
            position: relative;
            // text-align: center;
            border: 1px solid #FFB876;
            // margin: 20px;
            // width: inherit;
            width: 98%;
            min-height: 5rem;
            border-radius: 10px;
            padding: 1.5rem;

            .bordered-text {
                background-color: white;
                position: absolute;
                top: -0.75em;
                /* Adjust this value as needed */
                left: 1.45%;
                // transform: translateX(-50%);
                padding: 0 3px;
                font-size: $font-size-18px;
                font-weight: 600;
                /* Adjust padding as needed */
            }

            .chapter-title {
                font-size: $font-size-16px;
                font-weight: 600;
                margin-left: 0.6rem;
            }

            .question-type-name {
                color: #000000;
                font-size: $font-size-16px;
                font-weight: 500;
                margin-left: 0.6rem;
            }

            .mark-border {
                border-right: 1.2px solid #DCDCDC;
            }

            .exam-pad-icon {
                width: 1rem;

            }

            .mark-scored {
                color: #077CE5;
                font-size: $font-size-16px;
                font-weight: 500;
            }

            .question-type-wrapper {
                max-width: 100%;
                background-color: #FFEDD4;
                height: 1.5rem;
                border-radius: 20px;
                // padding: 1rem;

                .inner-progress-bar {
                    height: 1.5rem;
                    border-radius: 20px;
                }

                .average-indicator {
                    background-color: #DA5307;
                    position: absolute;
                    width: 0.25rem;
                    height: 1.5rem;
                    z-index: 0;
                    left: 0%;
                    // border-radius: 20px !important;
                }

                .average-number-absolute {
                    position: absolute;
                    top: -1.4rem;
                }


                .border-right-radius {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }

            .percentage-text {
                font-size: $font-size-18px;
                font-weight: 700;
            }
        }

        .competency-list-border-container {
            position: relative;
            // text-align: center;
            border: 1px solid #6EE7FF;
            // margin: 20px;
            // width: inherit;
            width: 97.5%;
            min-height: 5rem;
            border-radius: 10px;
            padding: 1.5rem;

            .bordered-text {
                background-color: white;
                position: absolute;
                top: -0.75em;
                /* Adjust this value as needed */
                left: 1.45%;
                // transform: translateX(-50%);
                padding: 0 3px;
                font-size: $font-size-18px;
                font-weight: 600;
                /* Adjust padding as needed */
            }

            .chapter-title {
                font-size: $font-size-16px;
                font-weight: 600;
            }

            .question-type-wrapper {
                max-width: 100%;
                background-color: #E5FAFF;
                height: 1.5rem;
                border-radius: 20px;
                // padding: 1rem;

                .inner-progress-bar {
                    height: 1.5rem;
                    border-radius: 20px;
                }

                .question-type-name {
                    color: #000000;
                    font-size: $font-size-16px;
                    font-weight: 500;
                }

                .average-indicator {
                    background-color: #DA5307;
                    position: absolute;
                    width: 0.25rem;
                    height: 1.5rem;
                    z-index: 0;
                    left: 0%;
                    // border-radius: 20px !important;
                }

                .average-number-absolute {
                    position: absolute;
                    top: -1.4rem;
                }

                .question-name-absolute {
                    position: absolute;
                }



                .border-right-radius {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }

            .percentage-text {
                font-size: $font-size-18px;
                font-weight: 700;
            }


            .card {
                border-radius: 10px !important;
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            }


            .competency-table {
                .table {
                    width: 100% !important;
                    margin-bottom: 0px !important;
                }

                .first-header-bg {
                    background-color: #C8F6FF !important;
                    border-top-left-radius: 20px !important;
                    border-top-right-radius: 20px !important;
                    border: none;
                }




                .second-header-bg {
                    background-color: #DDFFE9 !important;
                    color: #343434;
                    font-size: $font-size-12px;
                    font-weight: 600;
                }

                .suit-case-image-size {
                    width: 1.1rem;
                }

                .class-engagement-title {
                    font-size: $font-size-16px;
                    font-weight: 600;
                    color: #FFFFFF;
                }

                .class-header th {
                    height: 12px;
                    line-height: 12px;
                }

                .class-body td {
                    height: 12px;
                    line-height: 12px;
                    background-color: #FFFFFF;
                }

                .more-button-wrapper {
                    background-color: white;
                    border-radius: 6px;
                    padding: 0.2rem 0.5rem;

                    .more-button {
                        color: #407BFF;
                        font-size: $font-size-11px;
                        font-weight: 600;
                    }
                }

                .class-text {
                    font-size: $font-size-14px;
                    font-weight: 500;
                    // color: #3888FF;
                }

                .assignment-count {
                    font-size: $font-size-14px;
                    font-weight: 500;
                }

                .border-bottom-left-radius {
                    // border-bottom-left-radius: 10px;
                    // border-radius: 10px;
                }

                .image-size {
                    width: 1.1rem;
                }



            }

        }
    }

    .recommendation {
        .student-detail-header {
            color: white;
            font-size: $font-size-18px;
            font-weight: 500;
            background-color: #21B8C7;
            padding: 0.4rem 1rem;
            max-width: 98%;
            border-radius: 8px;
        }

        .recommendation-header {
            font-size: $font-size-16px;
            font-weight: 600;
        }

        .circle {
            width: 0.4rem;
            height: 0.4rem;
            background-color: #000000;
            border-radius: 50px;
        }

        .recommendation-text {
            font-size: $font-size-15px;
            font-weight: 500;
        }

    }

}

// @media print {
//     // body * {
//     //     display: none; /* Hide everything by default */
//     // }
//     #chapter_assessment, #chapter_assessment * {
//         display: block; /* Show only the specific element and its children */
//     }
// }