@import '../../../styles/style-variables.scss';

.paid-event-pay-screen {

    .header-image-size {
        width: calc(100% + 48px);
        margin-left: -3rem;
        height: 5rem;
    }

    .footer-image-size {
        position: absolute;
        width: calc(100% - 258px);
        left: 16.1rem !important;
        bottom: 0;
        right: 0;
        height: 5rem;
        z-index: 10;
    }


    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        // margin-top: -4rem;
        position: absolute;
        top: 5.5rem;
        background: transparent !important;
        padding: 0 !important;
        // z-index: 12;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .credit-card-image-size {
        width: 100%;
        height: 15rem;
        object-fit: contain;
    }

    .bage-image-size {
        width: 1.8rem;
    }

    .title {
        font-size: $font-size-20px;
        font-weight: 700;
    }

    .paid-event-content {
        
    }

    .description-text {
        min-height: 5rem !important;
        // overflow: scroll;
        font-size: $font-size-16px;
        font-weight: 600;
        color: #455B64;
        margin-top: 1rem;
    }

    .amount-text {
        font-size: $font-size-26px;
        font-weight: 700;
        color: $app-black;
    }

    .amount-subtext {
        font-size: $font-size-15px;
        font-weight: 600;
        color: rgba(69, 91, 100, 0.50);
    }

    .continue-button {
        font-size: $font-size-18px;
        font-weight: 500;
        color: $white;
        border-radius: 30px;
        background: #6B52C8;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem 3rem;
        position: relative;
        z-index: 11;
    }
}