@import '../../../styles/style-variables.scss';

.scholarship-feedback {
    .header-image-size {
        width: calc(100% + 48px);
        margin-left: -3rem;
        height: 5rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        // margin-top: -4rem;
        position: absolute;
        top: 5.5rem;
        background: transparent !important;
        padding: 0 !important;
        // z-index: 12;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .review-answers-image {
        width: 10rem;
    }

    .header-text {
        font-size: $font-size-20px;
        font-weight: 700;
    }

    .divider-image {
        width: 100%;
    }

    .question-separator-size {
        width: 100%;
    }

    .question-title {
        font-size: $font-size-16px;
        font-weight: 600;
    }

    .option {
        border-radius: 14.261px;
        border: 1.783px solid #B1ACE9;
        background: #FFF;
        box-shadow: 0px 7.130428791046143px 7.130428791046143px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem 1rem;
        // min-width: 30rem;
        max-width: 32rem;
    }

    .radio-circle {
        width: 22px;
        height: 22px;
        border: 1px solid #B1ACE9;
        border-radius: 50px;
    }

    .inner-selected {
        width: 100%;
        height: 100%;
        background-color: #6B52C8;
        border-radius: 50px;
        // padding: 0.2rem;
        border: 3px solid white;
    }

    .option-title {
        font-size: $font-size-15px;
        font-weight: 500;
    }

    .button-wrapper {
        position: relative;
        top: 22px;
        // left: 10rem;
        z-index: 1000;
    }

    .next-button {
        background: #6B52C8;
        border: 2px solid #6B52C8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        // padding: 1.1rem 0rem;
        // padding: 10px 31px;
        // padding: 18px 39px;
        width: 16rem;
        font-size: $font-size-16px;
        font-weight: 500;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-top: 1.2rem;
        // position: relative;
        // margin-left: 23rem;
        // top : -5rem;
        // z-index: -1;
    }

    .feedback-footer {

        // position: absolute;
        width: calc(100% + 48px);
        margin-left: -3rem !important;
        bottom: 0;
        right: 0;
        // top : 5rem;
        // left: 0;
        // height: 5rem;
    
        // bottom: 0;
        // z-index: -1;
        // min-height: 7rem;
    
        .footer-image-size {
            // position: absolute;
            // bottom: 0;
            // right: 0;
            // width: 78.1vw !important;
            width: 100%;
            height: 5rem;
            // object-fit: contain;
            // margin-left: -3rem !important;
    
        }
    }

}