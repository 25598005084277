@import '../../home/styles/style-variables.scss';

.side-bar-v3{

    padding-top: 5rem;

    .container{
        background : $sidebar-bg;
        height: 100vh;
        overflow: scroll;
        // padding: 0;
        overflow-x: hidden !important;
        padding-bottom: 6rem;
    }

    .side-bar-change-button {
        position: absolute;
        z-index: 99999;
        width: 35px;
        height: 35px;
        border-radius: 50px;
        background-color: #304096;
        top: 6rem;
        right: -0.95rem;
    }

    .arrow-image-size {
        width: 1.5rem;
    }

    .menu-border{
        border-bottom: .5px solid #6c6c6c;
    }

    .menu-container {
        position: relative;
        .sub-menu {
            position: absolute;
            left: 100%;
            top: 0;
            width: 225px;
            z-index: 9999999;
            display: none;
            .menu-border {
                border-left: 1px solid #FFFAFA;
                border-bottom: 1px solid #FFFAFA;
            }
            .route-wrapper {
                // background: #135960;
                background: #2F4244;    
            }
            .route-wrapper-disabled {
                background: #adafaf;    
            }
            .menu-border:first-child {
                border-top: none;
                .route-wrapper {
                    border-top-right-radius: 10px;
                }
                &.active-menu-bg {
                    .route-wrapper {
                        border-top: 1px solid #135960;
                    }
                }
            }
            .menu-border:last-child {
                border-bottom: none;
                .route-wrapper {
                    border-bottom-right-radius: 10px;
                }
                &.active-menu-bg {
                    .route-wrapper {
                        border-bottom: 1px solid #135960;
                    }
                }
            }
            .active-menu-bg {
                background-color: transparent;
                .route-wrapper {
                    border-right: 1px solid #135960;
                }
            }
        }
        .white-wrrow {
            max-width: 100%;
            object-fit: contain;
            height: 0.7rem;
        }
        &:hover {
            .route-wrapper.has-submenu {
                background: #135960 !important;
                .route-title {
                    color: #FFFFFF;
                }
            }
            .sub-menu {
                display: block;
            }
        }
    }

    .profile-container{
        padding-top: 2rem;
        border-bottom: .5px solid #6c6c6c;
        padding-bottom: 0.5rem;
        // cursor: pointer !important;
    }

    .profile-image{
        border-radius: 10rem;
        height: 6.31rem;
        width: 6.31rem;
        background: white;
        overflow: hidden;
        border: 2px solid white;
    }

    .profile-image-2{
        border-radius: 10rem;
        height: 3.31rem;
        width: 3.31rem;
        background: white;
        overflow: hidden;
        border: 2px solid white;
    }

    .profile-image-size{
        height: 6.31rem;
        width: 6.31rem;
        border-radius: 50%;
    }

    .profile-image-size-2{
        height: 3.31rem;
        width: 3.31rem;
        border-radius: 50%;
    }


    .profile-title{
        color: white;
        font-weight: 400;
        font-size:$font-size-18px;
        font-family: 'Mont';
    }

    .profile-image-wrapper{
        display: flex;
        justify-content: center;
    }

    .route-icon{
        width: 1.6rem;
        height: 1.7rem;
    }

    .route-title-wrapper{
        // padding-top: 1rem;
    }

    .route-title{
        color: white;
        font-size:$font-size-18px;
        font-weight: 500 !important;
        font-family: 'Mont';
    }

    .route-title-disabled{
        color: #7D7D7D;;
    }

    .route-sub-title{
        color: white;
        font-size:$font-size-18px;
    }

    .routes-container{
        background-color:$screen-bg;
        // margin-left: 2rem;
        // text-align: center;
    }

    .border-bottom-right-radius{
        border-bottom-right-radius: 3rem;
    }

    .active-menu{
        background: #3A4168 !important;
        // border-top-left-radius: 1.6rem;
        // border-bottom-left-radius: 1.6rem;
        .route-sub-title{
            color: #000000;
        }
    
        .route-title{
            font-weight: bold !important;
            color: white;
        }
    }

    .border-top-right-radius{
        border-top-right-radius: 3rem;
    }

    .route-wrapper{
        padding-bottom: 1rem;
        padding-top: 1rem;
        padding-right: 0rem;
        padding-left: 1rem;
        background : $sidebar-bg;
    }

    .route-sub-menu-bg {
        padding-bottom: 0.9rem;
        padding-top: 0.8rem;
        padding-right: 2rem;
        padding-left: 1.5rem;
        background: #2F4244;
    }

    .padding-bottom-for-last-child{
        padding-bottom: 5rem;
    }

    .active-menu-bg{
        background : $sidebar-bg;
    }

    .menu-item-padding{
        padding-top : 3.75rem;
    }

    #scroll-1::-webkit-scrollbar {
        width: 0.3rem;
        // background-color: #F5F5F5;
      }

    #scroll-1::-webkit-scrollbar-thumb {
        // background-color: #F5F5F5;
        background-color: #999999;

      }
    
}

// @media print {
//     .side-bar-v2 {
//         display: none;
//     }
// }