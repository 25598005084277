@import '../../../styles/style-variables.scss';

.dot-pagination-container {
    .dot-connector {
        width: 3rem;
        height: 0.1rem;
        background-color: #000000;
    }

    .dot {
        width: 2.5rem;
        height: 2.5rem;
        background-color: #6B52C8; // margin: wp(1.5),
            border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .inactive-dot {
        width: 1.8rem;
        height: 1.8rem;
        background-color: lightgray;
        // margin: wp(1.5),
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .active-index {
       font-size: $font-size-18px;
       font-weight: 700;
       color: white;
    }

    .inactive-index{
        font-size: $font-size-14px;
        font-weight: 600;
        color: #000000;
    }

}