@import '../../../styles/style-variables.scss';

.Toastify__toast-body {
  text-align: center !important;
}
.custom-toaster-modal {
  .Toastify__toast {
    border-radius: 10px;
  }
  .Toastify__toast--default {
    background: #272828 !important;
    justify-content: center;
    min-height: auto !important;
  }

  .Toastify__toast--info {
    background: #272828;
  }

  .Toastify__toast-body {
    font-size: $font-size-13px;
    color: #FFFFFF;
    font-weight: 600;
    padding: 5px 0;
  }
  .Toastify__toast-container--bottom-center {
    bottom: 5rem;
  }
}