@import '../../../styles/style-variables.scss';
.exam-prep-kit-intro-screen {
    
    .title {
        font-size: $font-size-26px;
        font-weight: 700;
    }

    .prep-kit-bg-image {
        width: 20%;
        // object-fit: contain;
        // height: 7rem;
    }

    .description-text {
        width: 21rem !important;
        font-size: $font-size-18px;
        font-weight: 600;
    }

    .learning-gap-button {
        background: #6B52C8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        // padding: 0.4rem 1.7rem !important;
        padding: 0.5rem;
        width: 10rem !important;
        font-size: $font-size-16px;
        font-weight: 500;
        // margin-top: 1.2rem;
    }
}