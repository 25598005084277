@import '../../../../styles/style-variables.scss';

.case-study-end-screen {
    // margin-left: -1.8rem;

    .header-image-size {
        // width: 76rem;
        width: 100%;
        height: 4rem;
        position: absolute;
        left: 0;
        top: 5rem;
    }

    .great-message {
        font-size: $font-size-28px;
        font-weight: 800;
        color: #263238;
        position: relative;
    }

    .screen-bg {
        position: absolute;
        top: 13rem;
        height: 27rem;
        width: 50rem;
        right: 10rem;
        // z-index: 1;
    }

    .footer-image-size {

        width: 100%;
        height: 4rem;
        position: absolute;
        bottom: 0;
        right: 0;
        // left: 16rem;
    }

    .breadcrumb {
        position: absolute;
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        top: 4.8rem;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .screen-bg {
        position: absolute;
        top: 13rem;
        height: 28rem;
        width: 60rem;
        right: 10rem;
    }

    .header-text {
        font-size: $font-size-32px;
        font-weight: 800;
        color: #263238;
    }

    .chapter-title {
        font-size: $font-size-18px;
        font-weight: 600;
    }

    .revise-text {
        font-size: $font-size-18px;
        font-weight: 500;
    }

    .lets-text {
        color: #455B64;
        font-size: $font-size-16px;
        font-weight: 600;
    }

    .workout-image-size {
        width: 11rem;
    }

    .button {
        border-radius: 30px;
        background: #FFFFFF;
        width: 10rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: $font-size-19px;
        padding: 0.5rem 1rem;
        font-weight: 600;
        color: #6B52C8;
        border: 1px solid #6B52C8;
    }

    .learning-gap-button {
        border-radius: 30px;
        background: #6B52C8;
        width: 18rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: $font-size-19px;
        padding: 0.5rem 1rem;
        font-weight: 600;
        color: white;
    }



    .arrow-size {
        width: 0.6rem;
        margin-bottom: 0.2rem;
    }

    .look-text {
        font-size: $font-size-18px;
        font-weight: 500;
        color: $sub-text-color;
        margin-left: 17rem;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .card-wrapper {
        position: relative;
        z-index: 1;
    }



    .marks-card {
        width: 37.5rem;
        min-height: 16rem;
        max-height: 16rem;
        // background: #FFFFFF;
        /* Theme colours/TC 1 Purple */

        border: 1px solid #6B52C8;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        position: relative;
        z-index: 1049;
    }

    .marks-card-bg {
        position: absolute;
        width: 37.5rem;
        min-height: 16rem;
    }

    .vertical-line {
        width: 0.2rem;
        height: 20rem;
        margin-top: 3.5rem;
    }

    .assessment-end-screen-footer {
        width: calc(100% - 320px);
        height: 7rem;
        position: absolute;
        bottom: 0;
        left: 20rem;
    }

    .mark-scored {
        font-size: $font-size-24px;
        font-weight: 500;
        color: $sub-text-color;
    }

    .time-taken {
        font-size: $font-size-24px;
        font-weight: 500;
        color: $sub-text-color;
    }

    .exam-pad-icon {
        width: 1.3rem;
        margin-top: -0.2rem;
        margin-right: 0.5rem;
    }

    .mark-scored-text {
        font-size: $font-size-19px;
        font-weight: 600;
        color: rgba(69, 91, 100, 0.5);
        ;
    }

    .correct-bg {
        background: #CBFDF5;
        border-radius: 20px;
        font-size: $font-size-16px;
        font-weight: 600;
        color: $text-black-color;

    }

    .incorrect-bg {
        background: #F7DBDC;
        border-radius: 20px;
        font-size: $font-size-16px;
        font-weight: 600;
        color: $text-black-color;
    }

    .not-answered-bg {
        background: #EDEDED;
        border-radius: 20px;
        font-size: $font-size-16px;
        font-weight: 600;
        color: $text-black-color;
    }

    .revise-concept {
        font-family: 'Mont';
        font-style: normal;
        // max-width: 20rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 6.8rem;
    }

    .workout-male-image {
        margin-top: 0.5rem;
        width: 10.9rem;
    }

    .button-wrapper {
        margin-left: -6rem;
        margin-top: 1rem;
    }

    .view-answers-button {
        background: #6B52C8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.7rem 0rem;
        width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
    }

    .insights-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.5rem 1rem;
        // width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
        // border: 1px solid #6B52C8;
    }



}