@import '../../../../../styles/style-variables.scss';

.view-answer-multi-step-questions {
    .tick-icon-in-multi-step {
        width: 1.5rem !important;
    }

    .multi-step-question-info-container {
        border-radius: 20px;
        background: rgba(255, 124, 121, 0.20);
        padding: 1rem;
        max-width: 30rem;
        margin-top: 0.5rem;
    }

    .multi-step-question-info-title {
        font-size: $font-size-16px;
        font-weight: 600;
    }

    .danger-text {
        font-size: $font-size-16px;
        font-weight: 600;
        color: #FF7C79;
    }

    .multi-step-question-part-text {
        font-size: $font-size-16px;
        font-weight: 600;
    }

    .multi-step-question-part-text-1 {
        font-size: $font-size-22px;
        font-weight: 600;
    }

    .correct-bg-color {
        background-color: rgba(133, 203, 158, 0.20);
        
    }

    .correct-text {
        font-size: $font-size-16px;
        font-weight: 600;
        // color: #FF7C79;
    }

    .step-separator {
        width: 10rem;
        height: 0.1rem;
        background-color: red;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

}