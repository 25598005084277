@import '../../../../styles/style-variables.scss';
.header-container-wrapper {
    .title-text {
        font-size: $font-size-24px;
        font-weight: 700;
    }

    .sub-title-text {
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .header-divider-image {
        width: 99%;
    }

    .breadcrumb-for-classwork {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,.title-2:hover,.title-3:hover,.title-4:hover, .home:hover {
        color: black;
        font-weight: 800;
    }

}