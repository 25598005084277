@import '../../../../styles/style-variables.scss';

.radio-container-for-practice {

    .radio-circle {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        border: 1.7px solid black;
        margin-right: 0.5rem;
        padding: 1rem;
        font-size: $font-size-32px;
        font-weight: 500 !important;
        background-color: rgb(86, 52, 221);
        color: white;
    }

    .inner-selected {
        width: 100%;
        height: 100%;
        // padding: 0.6rem;
        background-color: rgb(86, 52, 221);
        border: 4px solid white;
        border-radius: 50%;
    }

    .radio-max-width {
        max-width: 3.5rem !important;
    }

    .radio-max-width-for-read-only {
        max-width: 6.5rem !important
    }

    .radio-circle-for-readonly {
        width: 80px;
    height: 48px;
    border-radius: 50px;
    /* border: 1.7px solid black; */
    margin-right: 0.5rem;
    /* padding: 1rem; */
    font-size: 2rem;
    font-weight: 500 !important;
    }
}

.radio-container-for-full-screen {

}