@import '../../styles/style-variables.scss';

.refresher-course-experience {
    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: none !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }
}

.refresher-course {
    .progress-text {
        font-size: $font-size-18px;
        font-weight: 600;
    }

    .status-bar {
        width: 99%;
        height: 1.3rem;
        border-radius: 10px;
        background-color: #E4E4E4;
        overflow: hidden;
    }

    .chapter-icon {
        width: 3rem;
    }

    .chapter-title {
        font-size: $font-size-22px;
        font-weight: 700;
    }

    .concept-button {
        width: 7rem;
        background-color: #6B52C8;
        color: white;
        border-radius: 20px;

    }

    .button-text {
        padding: 0.35rem 1.2rem;
    }

    .lock-icon-size {
        width: 1rem;
    }

    .elo-title {
        font-size: $font-size-18px;
        font-weight: 600;
    }

    .number-width {
        max-width: 2.8% !important;
        font-size: $font-size-18px;
        font-weight: 600;
    }

    .elo-title-wrapper {
        border-bottom: 0.5px solid #B6B6B6;
        padding: 1rem 0rem;
        width: 99%;
    }

    .last-elo {
        border-bottom: none !important;
        padding: 1rem 0rem;
        width: 99%;
    }



}