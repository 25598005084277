@import '../../../../../styles/style-variables.scss';

.matching {
    .arrow-size {
        width: 1.8rem;
    }

    .view-answer-button {
        border-radius: 50px;
        border: 2px solid #6B52C8;
        background: #FFF;
        padding: 0.6rem;
        width: 18rem;
        text-align: center;
        font-size: $font-size-19px;
        font-weight: 500;
        color: #6B52C8;
    }

    .matching-question-container {

       

        .matching-question {
            gap: 1rem;
            flex-wrap: wrap;
        }

        .question-option-wrapper {
            flex-wrap: nowrap;
            // overflow: scroll;
        }


        .question-card {
            min-width: 16.5rem;
            min-height: 7.5rem;
            padding: 1rem;
            // background: #F2F0F9;
            border: 2px solid #B1ACE9;
            box-shadow: 0px 7.13043px 7.13043px rgba(0, 0, 0, 0.25);
            border-radius: 14.2609px;
            
        }

        .width-for-text-box {
            width: 13.5rem !important;
        }

        .question-card-dashed {
            border: 2px dashed rgba(69, 91, 100, 0.5);
        }

        .close-icon-wrapper {
            position: absolute;
            right: 0rem;
            top: -0.9rem;
            cursor: pointer;
        }

        .close-icon {
            width: 1.5rem;
        }

        .arrow-image {
            width: 2.5rem;
        }
    }
}