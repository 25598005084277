@import '../../../styles/style-variables.scss';

.one-step-event-assessment-end-screen {

    .screen-bg {
        position: absolute;
        top: 13rem;
        height: 16rem;
        // width: 62rem;
        width: calc(100% - 225px);
        object-fit: contain;
        // right: 10rem;
        left: 14rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        // margin-top: -4rem;
        // position: absolute;
        top: 5.5rem;
        background: transparent !important;
        padding: 0 !important;
        // z-index: 12;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .done-text {
        font-size: $font-size-22px;
        font-weight: 800;
        font-style: 'Mont';
        font-family: 'Mont';
    }

    .chapter-title {
        font-size: $font-size-20px;
        font-weight: 800;
        font-style: 'Mont';
        font-family: 'Mont';
    }


    .marks-card {
        width: 37.5rem;
        min-height: 16rem;
        max-height: 16rem;
        // background: #FFFFFF;
        /* Theme colours/TC 1 Purple */

        border: 1px solid #6B52C8;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        position: relative;
        z-index: 99999;
    }

    .marks-card-bg {
        position: absolute;
        width: 37.5rem;
        min-height: 16rem;
    }

    .vertical-line {
        width: 0.25rem;
        height: 20rem;
    }

    .assessment-end-screen-footer {
        width: 75.9rem;
        height: 7rem;
        position: absolute;
        bottom: 0;
        left: 20rem;
    }

    .mark-scored {
        font-size: $font-size-24px;
        font-weight: 500;
        color: $sub-text-color;
    }

    .time-taken {
        font-size: $font-size-24px;
        font-weight: 700;
        color: $sub-text-color;
    }

    .exam-pad-icon {
        width: 1.3rem;
        margin-top: -0.2rem;
        margin-right: 0.5rem;
    }

    .mark-scored-text {
        font-size: $font-size-19px;
        font-weight: 600;
        color: rgba(69, 91, 100, 0.5);
        ;
    }

    .correct-bg {
        background: #CBFDF5;
        border-radius: 20px;
        font-size: $font-size-16px;
        font-weight: 600;
        color: $text-black-color;

    }

    .incorrect-bg {
        background: #F7DBDC;
        border-radius: 20px;
        font-size: $font-size-16px;
        font-weight: 600;
        color: $text-black-color;
    }

    .not-answered-bg {
        background: #EDEDED;
        border-radius: 20px;
        font-size: $font-size-16px;
        font-weight: 600;
        color: $text-black-color;
    }

    .description-text {
        font-family: "Mont";
        font-size: $font-size-16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #263238;
        width: 98%;
    }

    .button-text {
        font-size: $font-size-15px;
        font-weight: 500;
        border-radius: 30px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem 1.5rem;
        color: $white;
    }

    .view-answers-button {
        background-color: #6B52C8;
    }

    .view-detail-report-button {
        background-color: #EC9A3B;
    }

    .social-media-button {
        background: #4E79F6;
    }

    .share-icon {
        width: 1rem;
    }

    .exam-pad-icon {
        width: 1.5rem;
    }

    .result-card {
        padding: 1rem;
        border-radius: 10px;
        background: #F0EDFD;
        width: 98%;
    }




}