@import '../../styles/style-variables.scss';

.clicker-quiz {
    .sub-header-text {
        font-size: 44px;
        font-weight: 700;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .header-for-practice-question {
        margin-top: -3.7rem;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .image-size {
        width: 40rem;
        // height: 80px;
    }

    .home-chapter-card {
        width: 28rem;
        height: 15rem;
        border-radius: 10px;
        padding: 1rem;
        position: relative;


        .chapter-image-size {
            width: 2.5rem;
        }

        .chapter-title {
            font-size: $font-size-36px;
            font-weight: 700;
        }

        .chapter-progress-bar {
            width: 90%;
            height: 0.9rem;
            background-color: #E4E4E4;
            border-radius: 10px;

            .chapter-inner-progress {
                background-color: #4C95F3 !important;
                height: 0.9rem;
                border-radius: 10px;
            }
        }

        .progress-value {
            font-size: $font-size-13px;
            font-weight: 600;
        }

        .lock-icon-size {
            width: 2rem;
        }

        .blue-arrow-icon-size {
            width: 4rem;
            position: absolute;
            right: 1rem;
            bottom: 1rem;
        }
    }
}