@import '../../../styles/style-variables.scss';
.dot-pagination {

    .active-step {
        width: 45.45px;
        height: 45.45px;
        background-color: #6B52C8;
        border-radius: 50px;
        font-weight: 600;
        font-size: $font-size-24px;
    }

    .active-step-text {
        color: white;
    }

   
    .attempted-question {
        width: 36.36px;
        height: 36.36px;
        background-color: #6B52C8;
        border-radius: 50px;
    }

    .steps {
        width: 36.36px;
        height: 36.36px;
        background-color: #D6D5FD;
        border-radius: 50px;
    }

    .line {
        width: 1.2rem;
        height: 0.2rem;
        background-color: #6B52C8;
    }

    .inactive-steps-text {
        // color: white;
        font-weight: 500;
        font-size: $font-size-18px;
    }

    .steps-attempted-text {
        color: white;
        font-weight: 500;
        font-size: $font-size-18px;
    }

}