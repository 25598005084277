@import '../../../../styles/style-variables.scss';
.exam-prep-kit-learning-content-wrapper {
    .image-size {
        width: 2.5rem;
    }

    .arrow-size {
        width: 3.5rem;
    }

    .card-border-bottom {
        border-bottom: 1.5px solid #E5E5E5;
        padding-bottom: 1rem;
        padding-top: 1rem;
        width: 98%;
    }

    .learn-more-text {
        font-size: $font-size-20px;
        font-weight: 700;
    }

    .notes {
        border-radius: 8px;
        background-color: #d2c2f1;
        width: 98%;
        padding: 1rem;
    }

    .info-icon {
        width: 1.5rem;
    }

    .notes-text {
        font-size: $font-size-18px;
        font-weight: 600;
        
    }
}