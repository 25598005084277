@import '../../../styles/style-variables.scss';

.custom-edit-popup {
    font-size: $font-size-12px;
    margin-top: 10rem !important;
    float: center;
    max-width: 32.5rem !important;
    // height: 28.125rem !important;


    .modal-body {
        padding: 0 !important;
    }

    .modal-content {
        // padding: 1rem;
        // background: #F8FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        min-height: 19rem !important;
    }


    .challenge-header {
        width: 32.5rem;
        height: 290px;
        position: absolute;
        left: 0rem;
        top: 0rem;
        border-radius: 10px;
    }

    .challenge-footer {
        width: 32.5rem;
        height: 70px;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        z-index: 1;
    }

    div::-webkit-scrollbar {
        display: none;
    }

    .close-icon {
        width: 28px;
        float: right;
        position: relative;
        top: 0.5rem;
        right: 0.5rem;
    }

    .title {
        padding-top: 1.5rem;
        padding-left: 2rem;
        color: #263238;
        font-size: $font-size-18px;
        font-weight: 700;
    }

    .cw-input-1 {

        width: 90%;
        height: 3.5rem;
        background: $gray;
        border: none;
        font-size: $font-size-18px;
        padding: 1rem;
        padding-top: 0.9rem !important;
        color: $app-black !important;
        // background: #F6F7F5;
        border-radius: 60px;
        position: relative;
        font-weight: 600;
        position: relative;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    }

    .cw-input-1::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b2aeab;
        opacity: 1;
        font-weight: 600;
        /* Firefox */
    }

    .cw-input-1:focus {
        outline: none;
    }

    .active-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.7rem 0rem;
        width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
        position: relative;
        z-index: 99;
    }


    .save-button-disabled {
        background: #DFDFDF !important;
        color: #9D9D9D !important;
    }


    


    .country-code {
        min-width: 3.2rem !important;
        font-size: $font-size-20px;
        font-weight: 700;
    }

    .country-code-down-arrow {
        width: 1.5rem;
        margin-top: -0.4rem;
        margin-left: 0.4rem;
    }

    .country-code-down-arrow-margin {
        margin-left: -0.5rem !important;
    }

    .country-code-separator {
        height: 3.2rem;
        margin-top: -0.4rem;
        margin-left: 0.5rem;
    }

    .cw-input-wrapper {
        width: 90% !important;
        height: 3.5rem;
        background: $gray;
        border: none;
        font-size: $font-size-20px;
        padding: 1rem;
        padding-top: 0.6rem !important;
        color: $app-black !important;
        // background: #F6F7F5;
        border-radius: 60px;
        position: relative;
        // margin-left: 12.5px;
    }

    .cw-input-1-for-mobileNumber {
        width: 70%;
        // height: 3.5rem;
        background: $gray;
        border: none;
        font-size: $font-size-20px;
        // padding: 1rem;
        // padding-top: 0.9rem !important;
        color: $app-black !important;
        // background: #F6F7F5;
        border-radius: 60px;
        position: relative;
        font-weight: 600;
        margin-left: 10px;
    }

    .cw-input-1:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b2aeab;
    }



    .cw-input-1-for-mobileNumber::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b2aeab;
        opacity: 1;
        font-weight: 600;
        /* Firefox */
    }

    .cw-input-1-for-mobileNumber:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b2aeab;
    }

    .cw-input-1-for-mobileNumber::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b2aeab;
    }



    .cw-input-1-for-mobileNumber:focus {
        outline: none;
    }

    .mobile-number-text {
        font-size: $font-size-16px;
        font-weight: 500;
        color:$sub-text-color ;
        max-width: 90%;
        margin-top: 0.5rem;
    }


    .abs-div {
        position: absolute;
        z-index: 99999;
        top: 65px;
        left: -0.5px;
        // width: 100%;
    }




    .country-code-drop-down {
        width: 27rem;
        height: 410px;
        min-height: 120px;
        // overflow-y: scroll;
        background: white;
        border-radius: 5px;
        border: 1px solid #d7d8d6;
        position: relative;
        margin-left: 0.5rem;

        .country-header-div {
            position: absolute;
            top: 0;
            //  z-index: 9999;


            .country-header-img {
                width: 27rem;
                height: 14rem;
                // height: 10rem;
            }
        }

        .country-footer-div {
            position: absolute;
            bottom: 0;
            //  z-index: 9999;


            .country-header-img {
                width: 27rem;
                // height: 1rem;
                // height: 10rem;
            }
        }

        .country-close-icon {
            position: absolute;
            z-index: 99999;
            top: 0.6rem;
            right: 0.8rem;

            .close-icon {
                width: 1.5rem;
            }
        }

        .select-country {
            position: absolute;
            top: 1.8rem;
            left: 1.5rem;
            font-size: $font-size-17px;
            font-weight: 700;
        }

        .country-search-div {
            position: absolute;
            z-index: 9999996;
            top: 5rem;

            .country-search {
                width: 25px;
                margin-left: 2rem;
                // margin-top: -0.2rem;
                margin-top: -1.7rem;
            }

        }


        .search {
            position: sticky;
            top: 3rem;
            z-index: 999999;
            left: 30px;
            width: 90%;
            margin-top: 3.3rem;

            input {
                background: #F6F7F5;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 30px;
                min-height: 3rem;
                // background: #F6F7F5;
                border: 1px solid #F6F7F5;
                // border-radius: 50px;
                font-size: $font-size-14px;
                width: -webkit-fill-available;
                text-indent: 35px;
                margin-left: 1rem;
            }



            input:focus {
                background: #F6F7F5;
                border: 1px solid #F6F7F5;
            }

            label {
                margin: 0 !important;
                font-size: 14px;
            }

        }

        .dropdown-scroll {
            overflow-y: auto;
            max-height: 15.625rem;
            min-height: 7.5rem;
        }

        .dropdown-list {
            .country-name {
                font-size: $font-size-15px;
            }

            .country-name:hover {
                font-weight: 700;
            }
        }


        .no-data {
            label {
                font-size: 14px;
            }
        }

    }






}