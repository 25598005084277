@import '../../../styles/style-variables.scss';

.edit-profile-screen {
    .image-size {
        width: 900px;
        height: 80px;
    }

    .title {
        font-size: $font-size-28px;
        font-weight: 700;
    }

    .image-size {
        width: 900px;
        height: 80px;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .search-icon-width {
        width: 35px;
        position: relative;
        left: 2.5rem;
    }

    .input-box {
        width: 200px;
        height: 48px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        border: none;
        text-indent: 40px;
    }

    .header-title-margin-top {
        margin-top: -4.5rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: none !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }


    .edit-profile-info {
        border-radius: 20px;
        border: 2px solid #BDEBEF;
        width: 98%;
        min-height: 10rem;
        margin-top: 1rem;

        .profile-info-header {
            padding: 1rem 1.5rem;
            background: #BDEBEF;
            border-radius: 18px 18px 0 0;
        }

        .info-text {
            color: #455B64;
            font-size: $font-size-18px;
            font-weight: 700;
        }

        .input-field-wrapper {
            margin-left: 0.7rem;
        }

        .cw-input-label-1 {
            font-size: $font-size-16px;
            font-weight: 600;
            color: #455B64;
        }

        .cw-input-1 {

            width: 80%;
            height: 3.5rem;
            background: $gray;
            border: none;
            font-size: $font-size-18px;
            padding: 1rem;
            padding-top: 0.9rem !important;
            color: $app-black !important;
            // background: #F6F7F5;
            border-radius: 60px;
            position: relative;
            font-weight: 600;
            position: relative;

        }

        .cw-input-wrapper {
            width: 76% !important;
            height: 3.5rem;
            // background: $gray;
            border: none;
            font-size: $font-size-22px;
            padding: 1rem;
            padding-top: 0.6rem !important;
            color: $app-black !important;
            background: #F6F7F5;
            border-radius: 60px;
            position: relative;
            margin-left: 12.5px;
            
        }

        .cw-input-1-for-mobileNumber {
            // width: 70%;
            // height: 3.5rem;
            background: $gray;
            border: none;
            font-size: $font-size-18px;
            // padding: 1rem;
            // padding-top: 0.9rem !important;
            color: $app-black !important;
            // background: #F6F7F5;
            border-radius: 60px;
            position: relative;
            font-weight: 600;
            margin-left: 10px;
        }

        .disabled {
            color: #455B6480 !important;
        }

        .info-icon-size {
            width: 1.5rem;
            margin-left: 15px;
        }

        .mobile-number-change-text {
            font-size: $font-size-14px;
            font-weight: 600;
            color: #455B64;
        }

        .country-code {
            min-width: 3.2rem !important;
            font-size: $font-size-18px;
            font-weight: 600;
            // color: rgba(69, 91, 100, 0.5);

        }


        .country-code-separator {
            height: 2.5rem;
            margin-top: -0.1rem;
            // margin-left: 0.5rem;
        }

        .place-holder-text {
            font-size: $font-size-15px;
            font-weight: 400;
            width: 80%;
            height: 3.5rem;
            background: $gray;
            border: none;
            // font-size: $font-size-20px;
            padding: 1rem;
            // padding-top: 1rem !important;
            color: $app-black !important;
            // background: #F6F7F5;
            border-radius: 60px;
            position: relative;
            // font-weight: 600;
            position: relative;
        }

        .school-code {
            font-size: $font-size-12px;
            font-weight: 500;
            color: #455B64;
        }

        .city-margin-top {
            margin-top: 2.5rem;
        }




    }
}