@import '../../../styles/style-variables.scss';

.custom-add-profile-popup {
    font-size: $font-size-12px;
    margin-top: 10rem !important;
    float: center;
    max-width: 30.5rem !important;
    // min-height: 20.125rem !important;

    div::-webkit-scrollbar {
        display: none;
    }

    .modal-content {
        // padding: 1rem;
        background: #F8FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        height: 23rem !important;
    }

    .modal-body {
        padding: 0 !important;
    }

    .header {
        border-radius: 10px 10px 0px 0px;
        background: #BDEBEF;
        min-height: 4.5rem !important;
    }

    .close-icon {
        width: 1.8rem;
    }

    .edit-title {
        font-size: $font-size-22px;
        font-weight: 700;
        color: #263238;
    }

    .profile-circle {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        background-color: #F0F0F0;
        // overflow: hidden;
        border: 2px solid #BDEBEF;
        // object-fit: contain;
        position: relative;
    }

    .std-image {
        // max-width: 100%;
        width: 10rem;
        height: 10rem;
        // height: 9.2rem;
        border-radius: 50%;
        // object-fit: contain;
    }

    .upload-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.7rem 0rem;
        width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
    }

}