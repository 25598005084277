@import '../../../styles/style-variables.scss';

.assessment-solution {
    .arrow-size {
        width: 1.8rem;
    }

    .view-solution-button {
        border-radius: 50px;
        border: 2px solid #6B52C8;
        background: #FFF;
        padding: 0.6rem;
        width: 18rem;
        text-align: center;
        font-size: $font-size-19px;
        font-weight: 500;
        color: #6B52C8;
    }

    .steps {
        font-size: $font-size-19px;
        font-weight: 600;
    }
}
