@import '../../../styles/style-variables.scss';

.cw-classwork-modal-right {
    margin: 0 !important;
    float: right;
    min-width: 500px !important;


    .modal-content {
        border-radius: 0 !important;

    }

    .classwork-filter {
        min-height: 95.5vh;

    }

    .filter-header-image {
        position: absolute;
        width: 100%;
        top: 0rem;
        right: 0.05rem;
        // z-index: 10;
        // margin-right: 2rem !important;
    }

    .filter-footer-image {
        position: absolute;
        width: 100%;
        bottom: 0rem;
        right: 0rem;
    }

    .filter-header {
        position: relative;
        z-index: 10;
    }

    .close-icon-size {
        width: 1.5rem;
    }

    .clear-all-text {
        background-color: #85CB9E;
        font-size: $font-size-14px;
        font-weight: 700;
        border-radius: 20px;
        color: $white;
    }

    .filter-text {
        font-size: $font-size-19px;
        font-weight: 800;
    }

    .tab-bg-1 {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.30);
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        padding: 0.5rem;
        font-size: $font-size-18px;
        font-weight: 600;
        color: #455B64;
    }

    .tab-bg-2 {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.30);
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        padding: 0.5rem;
        font-size: $font-size-18px;
        font-weight: 600;
        color: #455B64;

    }

    .active-tab {
        background-color: #69C7C1;
        color: white;
    }

    .check-box {
        width: 22px;
        height: 22px;
        border: 1.5px solid #535353;
    }

    .chapter-title {
        font-size: $font-size-16px;
        font-weight: 600;
        color: #263238;
    }

    .active-check-box {
        width: 22px;
        height: 22px;
        border: 1.5px solid #6AE5D3;
        position: relative;
    }

    .green-tick-icon {
        position: absolute;
        width: 1rem;
        top: 3px;
        left: 1px;
    }

    .apply-button {
        border-radius: 30px;
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: $font-size-18px;
        font-weight: 600;
        padding: 0.5rem 3rem;
        color: $white;
        position: relative;
        z-index: 11;
    }


}