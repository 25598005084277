.loading-screen{
    position: fixed;
    height: 100%;
    width: 100%;
    // background-color: #00000040;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left:0 !important;
    padding-right: 200px;
    background-color: rgba(128, 128, 128, 0.062);

    .loading{
        width: 14rem;
        height: 13.5rem;
        border-radius: 22px;
        background-color: #ffffff;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        top : -100px;

        .loading-header{
            position: absolute;
            top: 0;
        }
        .loading-footer{
            position: absolute;
            bottom: 0;
        }
        .loading-gif{
            width: 3.56rem;
            height: 3.313rem;
            object-fit: contain;
            
        }
    }
}