@import '../../../styles/style-variables.scss';

.classwork-header {
    .title {
        font-size: $font-size-32px;
        font-weight: bold;
    }

    .filter-wrapper {
        margin-right: 5rem;
    }

    .filter-size {
        width: 1.8rem;
    }

    .image-size {
        width: 900px;
        height: 80px;
    }

    .divider-image-width {
        // width: 1160px;
        width: 99%;
        height: 1.5px;
    }

    .search-icon-width {
        width: 35px;
        position: relative;
        left: 2.5rem;
    }

    .input-box {
        width: 200px;
        height: 48px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        border: none;
        text-indent: 40px;
    }

    .header-title-margin-top {
        margin-top: -4rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover, .home:hover {
        color: black;
        font-weight: 800;
    }


}