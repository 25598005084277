@import '../../../styles/style-variables.scss';

.content-list-screen {

    .first-detail-container {
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #B6B6B6;
        padding: 1.5rem 0rem;
        width: 99%;
    }

    .elo-detail-container {
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #B6B6B6;
        padding: 1.5rem 0rem;
        width: 99%;
    }

    .active-content-number {
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .active-title {
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .inactive-content-number {
        color: #A8A8A8;
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .inactive-title {
        color: #A8A8A8;
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .navigation-icon {
        width: 5.5rem;
    }

    .first-content-gif-container {
        position: absolute;
        right: 0;
        top: 12.52rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .arrow-icon {
        width: 0.6rem;
        position: absolute;
        align-self: center;
    }
}