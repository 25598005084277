@import "../../../../styles/style-variables.scss";

.practice {
    .chapter-list {
        .title {
            font-size: $font-size-28px;
            font-weight: 700;
        }

        .image-size {
            width: 900px;
            height: 80px;
        }

        .divider-image-width {
            width: 98%;
            height: 1.5px;
        }

        .search-icon-width {
            width: 35px;
            position: relative;
            left: 2.5rem;
        }

        .input-box {
            width: 200px;
            height: 48px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            border: none;
            text-indent: 40px;
        }

        .header-title-margin-top {
            margin-top: -4rem;
        }

        .breadcrumb {
            font-size: $font-size-16px;
            color: $sub-text-color;
            font-weight: 600;

            background: transparent !important;
            padding: 0 !important;

            .active {
                color: #1C1C1C;
            }
        }

        .title-1:hover,
        .home:hover {
            color: black;
            font-weight: 800;
        }
    }

    .practice-question-list {

        .image-size {
            width: 900px;
            height: 80px;
        }

        .sub-header-text {
            font-size: $font-size-20px;
            font-weight: 500;
            color: #353535;
        }

        .choose-complexity {
            font-size: $font-size-20px;
            font-weight: 500;
            color: #455B64;
        }

        .qustion-wrapper {
            padding-top: 24px;
        }

        .complexity {
            font-size: $font-size-18px;
            font-weight: 400;
            color: #455B64;
            cursor: pointer;
        }

        .header-for-practice-question {
            margin-top: -4rem;
        }

        .breadcrumb {
            font-size: $font-size-16px;
            color: $sub-text-color;
            font-weight: 600;

            background: transparent !important;
            padding: 0 !important;

            .active {
                color: #1C1C1C;
            }
        }

        .title-1:hover,
        .home:hover {
            color: black;
            font-weight: 800;
        }

        .chapter-icon {
            width: 2.5rem;
            // margin-top: -1rem;
        }

        .chapter-title {
            font-size: $font-size-26px;
            font-weight: 700;
            font-style: 'Mont';
            font-family: 'Mont';
        }

        .divider-image-width {
            width: 98%;
            height: 1.5px;
        }


        .verticleLine {
            width: 0.5px !important;
            background-color: #909090;
            height: inherit;
        }

        .question-divider-image-width {
            width: 70rem;
            height: 0.12rem;
        }



        .selected-complexity-bg-color {
            background-color: #6B52C8;
            color: #FFFFFF;
        }

        .row-max-width {
            max-width: 718px;
            background: #FFFFFF;
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            height: 46px;
            margin-left: 0.1rem;
        }

        .border-top-left-radius {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        .border-top-right-radius {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .selected-question-bg {
            background: rgba(107, 82, 200, 0.1);
        }

        .react-box {
            width: 24px;
            height: 24px;
            border: 2px solid #263238;
            // margin-left: 2.8rem;
            border-radius: 3px;
        }

        .react-image-width {
            width: 16px;
            margin-left: 0.1rem;
            margin-top: -0.3rem;
        }

        .practice-wrapper {
            width: 50rem;
            margin-left: 0.8rem;
        }

        .rect-active-bg {
            background: #6B52C8 !important;
        }

        .practiced-question {
            color: #455B64 !important;
            font-size: $font-size-16px !important;
            font-weight: 500;
        }

        .box-wrapper {
            max-width: 3rem;
        }


    }

    .mcq-activity-card {
        // background: #FFFFFF;
        // border-radius: 15px;
        border-radius: 18px;
        background: #DDF;
        box-shadow: 0px 4px 4px 0px rgba(194, 194, 194, 0.25);
        position: relative;
        // height: auto;
        // min-height: 4rem;
        width: 98.5%;

        .book-with-apple {
            width: 2.8rem;
        }

        .heading {
            font-size: 18px;
            font-weight: 600;
        }

        .case-study-bg {
            width: 6rem;
            position: absolute;
            right: 1.2rem;
            // bottom: 0;
            top: 1.75rem;
            // height: 6rem;
        }

        .divider {
            border: 0.5px solid #C9C9C9;
            width: initial;
            // margin-left: -25px;

        }

        .arrow-size-for-case-study {
            width: 0.6rem;
            position: relative;
            z-index: 99999;
            margin-right: 1.2rem;
        }


        .view-more-icon {
            width: 1.2rem;
        }

        .view-more-text {
            color: #009AFF;
            font-size: $font-size-15px;
            font-weight: 600;
        }

        .case-study-item {
            border-radius: 20px;
            background: #FFF;
            width: 100%;
            padding: 1.5rem;
        }

        .case-study {
            border: 1px solid rgb(234, 230, 230);
            border-radius: 10px;
            padding: 1rem;
            padding-left: 0 !important;
        }

        .case-study-title {
            font-size: $font-size-20px;
            font-weight: 700;
            color: #353535;
        }



        // .chapter-activity-arrow {
        //     img {
        //         min-width: 16px;
        //         max-width: 16px;
        //     }
        // }

        .case-study-image {
            width: 40rem;
            margin-left: 0rem;
        }

        .column-max-width {
            max-width: 0.2% !important;
            // margin-top: -0.2rem;
        }

        .sub-heading {
            font-size: $font-size-16px;
            font-weight: 800;
        }

        .sr-no {
            font-weight: 800 !important;
            font-size: 16px !important;
            padding: 0px 0 0px 0px !important;
            margin-bottom: 0px !important;
            // padding-top: -1rem;
        }

        p {
            font-weight: 500 !important;
            font-size: 14px !important;
            padding: 0px 0 0px 0px !important;
            margin-bottom: 0px !important;
            //   display: inline;
            //   align-items: center;
            //   justify-content: space-evenly;
            //   flex-wrap: wrap;
            // display: flex;
            // align-items: flex-end;
            // justify-content: flex-end;
        }

        // img {
        //     min-width: 8rem;

        //     max-width: 50rem;
        // }

        .watch-video-text {
            font-size: 11.5px;
            font-weight: 600;
            color: #FFFFFF;
            align-self: center;
            background-color: #21B8C7;
            border-radius: 8px;
        }

        .learning-resources-text {
            background-color: #1D919C !important;
        }

        .chapter-activity-download {
            img {
                min-width: 20px !important;
                max-width: 20px !important;
            }
        }



        .case_study_title {
            font-size: $font-size-16px !important;
            font-weight: 800 !important;
        }





    }


    // .cw-custom-modal-center {
    //     font-size: $font-size-12px;
    //     margin-top: 10rem;

    //     .modal-content {
    //       border-radius: 20px;
    //       padding: 1rem;
    //     }

    //     .header-text {
    //       font-size: 22px;
    //       font-weight: 700;
    //     }

    //     .close-icon {
    //       width: 20px;
    //       float: right;

    //     }

    //     .sub-text {
    //       font-size: 0.9rem;
    //       font-weight: 500;
    //       margin-left: 2rem;
    //     }

    //     .margin-left {
    //       margin-left: 5.5rem;
    //       margin-bottom: 1rem;
    //       font-weight: 800;
    //       font-size: 1rem;
    //     }
    //   }



}