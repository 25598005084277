@import '../../styles/style-variables.scss';

.passcode-update-screen {
    padding: 0 !important;
    background-color: $white;

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: none !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .header-text {
        font-weight: 700;
        font-size: $font-size-28px;
    }

    .info-max-width {
        max-width: 3.5% !important;
    }

    .info-image-size {
        width: 1.5rem;
    }

    .info-text {
        color: #455B64;
        font-weight: 600;
        font-size: $font-size-16px;
    }

    .update-passcode-wrapper {
        // padding-top: 6rem;
        // padding-left: 10rem;
    }

    .update-passcode-text {
        font-size: $font-size-18px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .invalid-text {
        color: $red;
        text-align: center;
        font-size: $font-size-16px !important;
    }

    .success-text {
        color: #60D590;
        text-align: center;
        font-size: $font-size-16px !important;
    }



    .update-button-disabled {
        background: #DFDFDF;
        color: #9D9D9D;
        // padding-top: 0.6rem;
        margin-top: 2rem;
        font-size: $font-size-19px;
        font-weight: 600;
        border-radius: 50px;
        padding: 0.5rem 1rem;
    }

    .update-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        color: white;
        margin-top: 2rem;
        font-size: $font-size-19px;
        font-weight: 600;
        border-radius: 50px;
        padding: 0.5rem 1rem;
    }


    .pass-code {
        position: relative;

        .passcode-boxes {
            input {
                height: 3.2rem;
                width: 4.3rem;
                font-size: $font-size-22px;
                text-align: center;
                background: #F6F7F5;
                // border: 1px solid #C4C4C4;
                box-sizing: border-box;
                // border-radius: 5px;
                margin: 12px;
                border: 1px solid rgba(69, 91, 100, 0.5);
                border-radius: 10px;

            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                appearance: none;
                margin: 0;
                /* Optional: You can also adjust the margin if needed */
            }

        }

        .input-one-margin-left {
            margin-left: 0 !important;
        }

        .cw-input-label-1 {
            font-size: $font-size-16px;
            font-weight: 600;
        }

        .forgot-passcode {
            margin-left: 0.9rem;
            font-size: $font-size-15px;
            color: #11BFD7;
            font-weight: 600;
        }

        .enter-passcode {
            font-size: $font-size-14px;
            color: #455B64;

        }

        .custom-toast-for-invalid-passcode {
            position: absolute;
            left: 3.5rem;
        }

    }




}