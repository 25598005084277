

.option-wrapper-for-clicker-practice {

    // width: 60rem;
    flex-wrap:wrap;
    // min-width: 35rem;
    max-width: 99% !important;
    gap: 1rem;
    // border: 1px solid;
    //     border-radius: 14px;
    //     padding: 1rem;

    .option-border {
        border: 1px solid;
        border-radius: 14px;
        padding: 1rem;
    }

    .expand-icon {
        width: 1.6rem;
    }
}

.fixed-column-width {
    max-width: 49.5% !important;
}


.option-wrapper-for-full-screen-for-practice {

    // width: 60rem;
    flex-wrap:wrap;
    // min-width: 35rem;
    max-width: 95% !important;
    gap: 0.2rem;
    // border: 1px solid;
    //     border-radius: 14px;
    //     padding: 1rem;

    .option-border {
        border: 1px solid;
        border-radius: 14px;
        padding: 1rem;
        // height: auto;
    }

    .expand-icon {
        width: 1.6rem;
    }
}