@import '../../../styles/style-variables.scss';

.clicker-assessment-leaderboard {
    $blue-bg: #003478;
    $gold-border: #D4AF37;
    $white-text: #ffffff;
    width: 97%;

    .score-board {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 5px;
        background-color: $blue-bg;
        border-radius: 15px;
        // border: 3px solid $gold-border;
    }

    .no-leaderboard-text {
        color: white;
        font-size: $font-size-30px;
        font-weight: 700;
        padding: 4rem;
    }

    .score-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        // background-color: #37b71d;
        color: $white-text;
        border: 3px solid $gold-border;
        padding: 10px 20px;
        margin: 10px 0;
        border-radius: 30px;
        position: relative;

        // &:before,
        // &:after {
        //     content: '';
        //     position: absolute;
        //     top: 50%;
        //     transform: translateY(-50%);
        //     border: 20px solid transparent;
        // }

        // &:before {
        //     left: -40px;
        //     border-right-color: $gold-border;
        // }

        // &:after {
        //     right: -30px;
        //     border-left-color: $gold-border;
        // }
    }

    .rank {
        margin-right: 20px;
        font-weight: bold;
    }

    .rank-wrapper {
        // width: 30px;
        // height: 30px;
        color: $white-text;
        border: 3px solid $gold-border;
        padding: 10px 20px;
        margin: 10px 0;
        border-radius: 30px;
        font-size: $font-size-30px;
        font-weight: 600;
        max-width: 8%;
    }

    .name {
        flex-grow: 1;
        font-size: $font-size-30px;
        text-align: start;
        font-weight: 600;
        margin-left: 10px;
    }

    .correct-question-count {
        font-size: $font-size-30px;
        text-align: center;
        font-weight: 600;
        margin-right: 30px;
    }

    .score {
        margin-left: 20px;
        font-size: $font-size-30px;
        font-weight: bold;
    }
}