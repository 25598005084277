@import '../home/styles/style-variables.scss';

.layout {

    .sideBar {
        position: fixed;
        // width: 320px;
        width: 260px;
        z-index: 999;
    }

    .change-side-bar-width {
        width: 120px;
    }

    .main-component {
        // margin-left: 300px;
        margin-left: 240px;
        // overflow: auto;
        // overflow-y: hidden;
        background-color: $screen-bg;
        // height: 100vh;
        padding-top: 5rem;
        padding-left: 3rem;
        padding-right: 0;
        padding-bottom: 1rem;
    }

    .change-main-component-margin {
        margin-left: 100px;
    }

    .custom-toast-for-new-passcode {
        position: absolute;
        /* top: 30rem; */
        bottom: 3rem;
        left: 45rem;
    }

    .toggle-side-bar {
        display: none;
    }

    .show-side-bar {
        display: block;
    }

    .change-main-component-margin-for-side-bar-hide {
        margin-left: 0px;
        padding-left: 1rem;
        padding-top: 0rem;
    }


}