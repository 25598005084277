@import '../../../styles/style-variables.scss';
.learning-summary {
    min-height: 15.5rem;

    .innovation-image-size {
        width: 2.5rem;
    }

    .title {
        font-size: $font-size-22px;
        font-weight: 700;
    }

    .summary-tick-image-size {
        width: 1.8rem;
    }

    .no-margin-bottom {
        div {
            margin-bottom: 0px !important;
        }
        
    }

    .bookmark-image-size {
        width: 2rem;
    }
}