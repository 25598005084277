@import '../../../styles/style-variables.scss';

.learning-footer {
    // position: fixed;
    width: calc(100% + 48px) !important;
    margin-left: -3rem !important;
    bottom: 0;
    right: 0;

    .footer-image-size {
        // position: relative;
        // position: absolute;
        // bottom: 0;
        // right: 0;
        // width: 78.1vw !important;
        width: 100%;
        // object-fit: contain;
        height: 3.5rem;
        // object-fit: contain;
        // margin-left: -3rem !important;

    }

    .button-wrapper {
        position: relative;
        top: 28px;
        // left: 10rem;
        z-index: 1000;
        right: 2.7rem;
        // bottom: 0;
        // margin-right: calc(100vh - 50px);
    }

    .next-button {
        background: #6B52C8;
        border: 2px solid #6B52C8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        // padding: 1.1rem 0rem;
        // padding: 10px 31px;
        // padding: 18px 39px;
        // width: 10%;
        padding: 0.5rem 4rem;
        font-size: $font-size-20px;
        font-weight: 600;
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-top: 1.2rem;
        // position: relative;
        // margin-left: 23rem;
        // top : -5rem;
        // z-index: -1;
        position: relative;
    }
}