@import '../../../styles/style-variables.scss';
.things-to-remember {
    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color !important;
        font-weight: 600;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .header-image-size {
        max-width: 100%;
        object-fit: contain;
        height: 5rem;
        position: absolute;
        top: 5rem;
        right: 0;
        z-index: 0;
    }

    .things-to-remember-icon {
        width: 4rem;
    }

    .header-text {
        font-size: $font-size-28px;
        font-weight: 800;
    }

    .chapter-title {
        font-size: $font-size-22px;
        font-weight: 600;
        color: #878787;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .ttr-wrapper {
        // overflow: scroll;
        // overflow-x: hidden;
        // min-height: 30rem;
    }

    .title {
        font-size: $font-size-26px;
        font-weight: 700;
        padding-right: 1rem;
        border-right: 1.5px solid #E4E4E4;
    }

    .chapter-progress-bar {
        width: 96%;
        height: 0.9rem;
        background-color: #E4E4E4;
        border-radius: 10px;

        .chapter-inner-progress {
            background-color: #4C95F3 !important;
            height: 0.9rem;
            border-radius: 10px;
        }
    }

    .progress-value {
        font-size: $font-size-13px;
        font-weight: 600;
    }

    .chapter-card {
        width: 97%;
        border-bottom: 1px solid #D9D9D9;
    }

    .arrow-image-size {
        width: 3rem;
    }

    .revise-text {
        font-size: $font-size-22px;
        font-weight: 700;
    }

    .table-of-content-title {
        font-size: $font-size-20px;
        color: #455B64;
        font-weight: 600;
    }

    .chapter-icon {
        width: 2.2rem !important;
    }


}