@import '../../styles/style-variables.scss';

.all-video-list {


    font-size: $font-size-15px;
    font-weight: 500;
    background-color: #FFFFFF;

    .video-poster-size {
        border-radius: 10px;
    }

    .display-none{
        display: none;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: none !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }
    
    .back-icon {
        width: 1.5rem;
        // margin-right: 1rem;
    }

    .disable-input {
        background-color: #D9D9D9 !important;
    }

    .filter-icon-new-size {
        width: 1.5rem;
        margin-top: 0.3rem;
    }

    .dropdown-scroll {
        position: absolute;
        z-index: 99999;
        overflow-y: auto;
        width: 95%;
        max-height: 15.625rem;
        min-height: 7.5rem;
        background-color: white;
        border:1px solid $dark-gray;
        border-radius: 6px;
    }

    .dropdown-list {
        .country-name {
            font-size: $font-size-13px;
        }

        .country-name:hover {
            font-weight: 700;
        }
    }


    .no-data {
        label {
            font-size: 14px;
        }
    }


    .heading-wrapper {
        border-radius: 12px;
        background: #81E4EE;
        box-shadow: 0px 4px 4px 0px rgba(194, 194, 194, 0.25);
        position: relative;
        width: 98% !important;
        margin-left: 0.5rem;
    }

    .bread-crumb-title:hover {
        color: #353535 !important;
        font-weight: 700;
        cursor: pointer;
    }

    .header-text {
        font-size: 23px;
        font-weight: 800;
    }

    .cw-input-for-search {
        border-radius: 1.875rem;
        width: 100%;

        border: 1px solid #A9A9A9 !important;
        height: 2.2rem !important;
        background: $report-gb-color;
        border: none;
        font-size: $font-size-12px;
        padding: 1rem;
        color: $app-black !important;
        outline: none;
    }

    .cw-input-border-bottom {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .cw-input-for-search::placeholder {
        // font-style: italic;
    }

    .filter-icon {
        width: 2.2rem;
    }

    .search-icon-size {
        width: 1.3rem;
        position: absolute;
        right: 15rem;
        top: 0.5rem;
        z-index: 9999;
    }

    .text-search {
        color: $app-black;
        font-weight: 600;
    }

    .case-study-bg {
        width: 5rem;
        position: absolute;
        right: 0.5rem;
        // top: 0;
        bottom: 0;
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
    }

    .orange-play-icon {
        width: 4.375rem;
    }

    .alphabet-wrapper {
        border: 1px solid #D8D8D8;
        padding: 0.5rem;
        width: 2rem;
        font-size: $font-size-14px;
        font-weight: 600;
        border-radius: 5px;
    }

    .clear-button-wrapper {
        margin-left: -3rem;
    }

    .selected-alphabet-bg {
        background-color: #4ECACE;
        color: white;
        border: none !important;
    }

    .disabled-alphabet {
        background-color: #D8D8D8;
        color: #BBBBBB;
    }

    .comprehension-keyword-list-wrapper {
        border: 1px solid #008FAE;
        border-radius: 10px;
        padding: 2rem;


        .keyword-wrapper {
            background: linear-gradient(165deg, #B6F8FF 0%, #A7FFCC 100%);
            border-radius: 10px;
            padding: 1rem;
            max-height: 5.9rem;
            overflow: hidden;

            p {
                margin-bottom: 0.5rem !important;
                color: #343434;
            }

            .keyword-title {
                font-weight: 700;
                font-size: $font-size-18px;
            }

            .max-width-for-meaning {
                max-width: 9.6%;
            }

            .margin-left-for-content {
                // margin-left: -2rem;
            }

            .keyword-meaning {
                font-weight: 500;
                font-size: $font-size-15px;
                color: #343434;
            }
        }
    }





    .back-icon {
        width: 1.5rem;
        margin-right: 1rem;
    }

    .orange-play-icon {
        width: 4.375rem;
    }

    // .heading-wrapper {
    //     border-radius: 18px;
    //     background: #CAE6FF;
    //     box-shadow: 0px 4px 4px 0px rgba(210, 210, 210, 0.25);
    //     position: relative;
    // }

    .all-video-eclipse {
        width: 5rem;
        position: absolute;
        right: 0rem;
        top: 0;
        border-top-right-radius: 18px;
    }

    .all-video-polygon {
        width: 5rem;
        position: absolute;
        right: 0rem;
        bottom: 0;
        border-bottom-right-radius: 18px;
    }

    .solved-examples-card {
        // background: #FFFFFF;
        // border-radius: 15px;
        height: auto;
    }

    .header-text {
        font-size: 23px;
        font-weight: 800;
    }

    .videos-count {
        font-size: $font-size-15px;
        font-weight: 400;
        color: #8E8E8E;
    }

    .filter-button {
        // margin-top: 1.6rem;
    }

    .cw-input {
        background-color: white;
    }


    .filter-input-label {
        font-size: $font-size-12px;
        //line-height: 1rem;
        padding-bottom: 0.4rem;
        padding-left: 0.9rem;
        margin-bottom: 0 !important;
        color: $report-filter-label;
        font-weight: 600;
    }

    .solved-example-title {

        p,
        .sr-no {
            font-weight: 800;
            font-size: 17px;
            padding: 4px 0 4px 0;
        }
    }

    .solved-example-solution {
        p {
            font-weight: 600;
            font-size: 15px;
            padding: 5px 0 5px 0;
        }
    }

    .video-detail-header-image {
        position: absolute;
        right: 1.5rem;
        height: 4rem;
        border-top-right-radius: 18px;
    }

    .video-detail-wrapper {
        border-radius: 20px;
        border: 0.5px solid #21B8C7;
        background: linear-gradient(165deg, #FFF 0%, #E9FDFF 100%);
    }

    .elo-title {
        font-size: $font-size-18px;
        font-weight: 700;
        color: #353535;
        // position: relative;

    }

    .video-detail-divider {
        border: 0.5px solid #C9C9C9;
    }



    .blue-dimond-icon {
        width: 8rem;
        position: absolute;
        right: 14rem;
        top: 0;
    }

    .video-learning-content-wrapper-header {
        border-radius: 18px;
        // background: #E9F7FF;
        // box-shadow: 0px 4px 4px 0px rgba(194, 194, 194, 0.25);
    }


    .video-learning-content-wrapper {
        border-radius: 12px;
        border: 0.5px solid #21B8C7;
        background: linear-gradient(165deg, #FFF 0%, #E9FDFF 100%);

    }

    .button-wrapper {
        // position: absolute;
        // right: calc(47vw - 100px);
        // bottom: 0rem;
        // bottom: -73px;
    }

    .read-more-wrapper {
        border-radius: 10px;
        border: 1.5px solid #009AFF;
        padding: 0.4rem 0.2rem;
        // height: 2.2rem;


        .read-more {
            border-radius: 6.19px;
            background: #009AFF;
            color: $white;
            font-size: $font-size-14px;
            padding: 0.3rem;
            font-weight: 600;
            height: 2rem !important;
        }
    }




    .table-responsive {
        overflow: inherit;
    }

    .answer-result-icon {
        height: 35px !important;
        width: 35px !important;
        // margin-top: -1px;
    }

    .item-2 {
        margin-left: 30rem !important;
    }

    .name-padding {
        padding-left: 8rem;
    }

    .view-image {
        color: #008FFF;
    }

    .report-card {
        max-width: 500px;
        border-radius: 15px;

        .report-header {
            width: 100%;
            font-weight: bold;
            font-size: $font-size-22px;
            border-radius: 15px 15px 0 0;
            padding: 30px;
            color: #FFFFFF;
        }

        .report-body {
            font-size: $font-size-22px;
            border-radius: 15px;
            padding-left: 30px;
            padding-bottom: 30px;
        }
    }

    // .cw-plain-card1 {
    //     background: #f3f7fa !important;
    //     border: 1px solid #eee !important;
    //     border-radius: 15px !important;
    // }

    .card-background-none {
        background: none !important;
    }

    .border-green {
        border: 1px solid #5DC7AD;
    }

    .border-bottom-tr {
        // border-bottom: 1px solid rgb(223, 222, 222) !important;
    }

    .img-td {
        width: 50px !important;
    }

    .change-color {
        color: #009AFF;
        font-weight: 600;
        font-size: $font-size-11px;
    }


    .border-red {
        border: 1px solid #FF7171;
    }

    .background-green {
        background: #5DC7AD;
    }

    .background-red {
        background: #FF7171;
    }


    .student-name {
        font-weight: 600;
        font-size: $font-size-18px;
    }

    .student-mark {
        font-size: $font-size-14px;
    }

    .flex {
        display: flex !important;
        align-items: center;
        // justify-content: space-between;
    }

    .user-image {
        border-radius: 10rem;
        height: 33px;
        width: 33px;
        overflow: hidden;
    }

    .report-icon {
        height: 22px;
        width: 22px
    }

    .cw-answer-sheet-card-wrapper {
        color: '#00000';
        padding: 3rem;
    }

    .card-text {
        display: flex;
        align-items: center;
        font-weight: 300;
    }

    .bar-chart-icon {
        height: 6.5rem;
        width: 6.5rem;
    }

    .dashboard-sl-number {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 800;
        font-size: $font-size-32px;

    }

    .col-min-width {
        min-width: 60px !important
    }

    .separator {
        width: 100%;
        height: 1px !important;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .separator-1 {
        width: 100%;
        height: 1px !important;
    }

    .answer-image {
        border-radius: 15px;
        overflow: hidden;
        max-width: 650px;
        max-height: 750px;
    }

    .answer-image {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .question-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .submission-question-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .answer-image-container {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
    }

    .sub-title {
        font-size: $font-size-15px;
        font-weight: 800;
        color: #000000;
    }

    .time-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;

    }

    .image-container {
        max-width: 200px;
        max-height: 200px
    }

    .match-option {
        min-height: 90px;
        min-width: 130px;
        border-radius: 8px;
        background-color: #E5E5E5;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 5px;
    }

    .match-arrow {
        height: 20px;
        width: 20px;
        // align-self: center;
        // justify-self: center;

    }

    .matching-option {}

    .image-option-container {
        max-height: 60px;
        max-width: 100px;
        text-align: center;

    }

    .sequence-option {
        min-height: 70px;
        min-width: 130px;
        max-width: 100%;
        border: 1px solid #BFBEC2;
        box-sizing: border-box;
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        background-color: #ffffff;
        align-items: center;
        justify-content: center;
        display: flex;

    }

    .drag-container {
        border-width: 0px 2px 0px 0px solid #000000;
    }

    .border {
        // border-style: solid;
        // border-width: 0px 0px 0px 2px solid #000000;
    }

    .group-option {
        min-height: 130px;
        min-width: 250px;
        max-width: 300px;
        border: 1px solid #BFBEC2;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: #ffffff;
        display: flex;
        overflow: hidden;
    }

    .group-items {
        min-height: 70px;
        min-width: 130px;
        max-width: 300px;
        border-radius: 8px;
        background-color: #E5E5E5;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .group-header {
        width: 100%;
        height: 40px;
        background-color: #E5E5E5;
        align-items: center;
        justify-content: center;
    }

    .sequence-border {
        width: 1px;
        min-height: 70Px;
        height: 100%;
        background-color: #BFBEC2;
    }


    // max-height:calc(100vh - 300px);
    // overflow-y: auto;

    .option-wrapper {
        overflow: auto;
        white-space: nowrap;
    }

    .check-box {
        height: 20px;
        width: 20px;
        border: 1px solid #BFBEC2;
        box-sizing: border-box;
        border-radius: 4px;
    }

    .radio-btn {
        height: 18px;
        width: 18px;
        border: 1px solid #BFBEC2;
        box-sizing: border-box;
        border-radius: 20px;
    }

    .correct-answer-border {
        border: 2px solid #6AE5D3;
        box-shadow: none;
    }

    .correct-answer-light-border {
        border: 1px solid #6AE5D3;
        box-shadow: none;
    }

    .answer-result-icon {
        height: 62px;
        width: 62px
    }

    .incorrect-answer-border {
        border: 2px solid #F37079;
        box-shadow: none;
    }

    .dot {
        height: 10px;
        width: 10px;
        border-radius: 5px;
        align-self: center;
        margin-right: 8px;
    }

    .green-dot {
        background-color: #5DC7AD;
    }

    .red-dot {
        background-color: #FF7171;
    }

    .yellow-dot {
        background-color: #FFC571;
    }

    .blue-dot {
        background-color: #009AFF;
    }

    .image-container {
        max-width: 200px;
        max-height: 200px
    }

    .roll-no {
        font-weight: 400 !important;
        font-size: $font-size-15px;
        color: #7D7D7D;
    }

    .student-image-1 {
        height: 40px;
        width: 40px;
        border-radius: 10rem;
    }

    .vertical-separator {}

    .vertical-separator-container {
        // max-height:100%;
        position: absolute;
        transform: rotate(90deg);
    }

    .question-separator {
        align-self: center;
        width: 100%
    }

    .solution-container {
        display: inline-block;
        align-items: center;
        justify-content: 'space-evenly'
    }

    p {
        margin: 0 !important;
        font-weight: 500;
    }

    .status-row {
        position: relative;
    }

    .students-card {
        position: absolute;
        margin-top: 30px;
        margin-left: -50px;
        padding: 20px;
        background-color: #ffffff;
        width: 400px;
        min-height: 100px;
        max-height: 450px;
        z-index: 1000;
        border: 1px solid #21B8C7;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        overflow: auto;

        .profile-avatar {
            overflow: hidden;
            border-radius: 50%;
            height: 45px;
            width: 45px;

            img {
                height: 45px;
                width: 45px;
            }
        }

        .student-name {
            font-weight: 600;
            font-size: $font-size-18px;
        }

        .close-icon {
            position: absolute;
            right: 0;
            z-index: 999999999;
            width: 20px;
            margin-right: 10px;
        }
    }

    .custom-report-table {
        border-spacing: 0px 5px;

        tr {
            background-color: transparent !important;
            border: 4px !important;
            font-size: $font-size-15px
        }

        td {
            vertical-align: inherit !important;
            border: 4px !important;
            font-size: $font-size-13px;
            font-weight: 500;
        }

        th {
            background-color: #21B8C7;
            color: white;
            font-size: $font-size-16px;
            height: 50px;
            vertical-align: inherit !important;
            border: 4px !important;
        }

    }

    .video-content-alignment {
        // margin-left: 37px !important;
        // margin-bottom: 40px;
    }


    .video-learning-content {

        // display: flex;
        // align-items: end;
        // justify-content: flex-end;
        // max-width: 80%;
        .chapter-image-icon {
            width: 3rem !important;
        }

        .chapter-info-wrapper {
            min-height: 4.1rem;
        }

        .chapter-title {
            font-size: $font-size-17px;
            font-weight: 700;
        }


        .content-alignment {
            margin-left: 2rem;
        }

        p,
        .sr-no {
            font-weight: 800 !important;
            font-size: 13px !important;
            padding: 0px 0 0px 0px !important;
            margin-bottom: 0px !important;
            //   display: inline;
            //   align-items: center;
            //   justify-content: space-evenly;
            //   flex-wrap: wrap;
            // display: flex;
            // align-items: flex-end;
            // justify-content: flex-end;
        }

        .sr-no-bg {
            font-weight: 800 !important;
            font-size: 16px !important;
            padding: 0px 0 0px 0px !important;
            margin-bottom: 0px !important;
            background-color: #FF8761;
            max-width: 30px !important;
            height: 30px !important;
            border-radius: 50px;
            // flex-basis: 35px;
            //   display: inline;
            //   align-items: center;
            //   justify-content: space-evenly;
            //   flex-wrap: wrap;
            // display: flex;
            // align-items: flex-end;
            // justify-content: flex-end;
        }



        video {
            // width: 50%;
            width: 100%;
            border-radius: 8px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 3px;
            padding: 1px;
            cursor: pointer;

            //   position: relative;
            //   float: left;
            //   top : -23rem;
        }

        // @counter-style custom-unordered {
        //   system: cyclic;
        //   symbols:  url("../assets/images/rectangle-10.png") ;
        //   suffix: ' ';
        // }

        ul {
            padding-left: 20px;
            margin-bottom: 3px !important;
            // list-style: custom-unordered !important;
            //   display: flex;
            // align-items: flex-end;
            // justify-content: space-around;
        }

        li {
            font-weight: 800 !important;
            font-size: 13px !important;
            padding: 0px 0 0px 2px !important;
        }

        .cw-step {
            background: #F0FBFF;
            border: 1.02326px solid #38C8FF;
            border-radius: 8px;
            width: 40%;
            // margin-left: 2rem;
            margin-bottom: 0.5rem !important;
            margin-top: 0.5rem !important;
        }

        .div {
            text-align: left !important;
        }

        img {
            height: auto !important;
            width: 380px !important;
            margin-left: 5px;
            // display: none;
        }

    }



    .video-content-wrapper {
        padding-left: 1rem;
    }

    .video-detail {
        img {
            max-height: 25rem !important;
            max-width: 100% !important;
            // margin-left: 5px;
        }
    }

    .video-learning-content-title {

        // margin-left: 2rem;

        p,
        .video-content-title,
        .sr-no {
            font-weight: 800 !important;
            font-size: 18px !important;
            padding: 4px 0 4px 0 !important;
            //border-bottom: 4px solid #21B8C7;
            // border-radius: 15px;
            // background-color: #F0FBFF;
        }
    }

    .video-learning-content-title-min-height {
        min-height: 3.8rem !important;
    }



    .video-content-title {
        font-weight: 800 !important;
        font-size: 16px !important;
        padding: 4px 0 4px 0 !important;
        //border-bottom: 4px solid #21B8C7;
        // border-radius: 15px;
        // background-color: #F0FBFF;
    }

    .divider {
        border: 1px solid rgba(0, 0, 0, .125);
        width: 100.8%;
        margin-left: -5px;
    }

    .title-divider {
        border: 2px solid #21B8C7;
        width: 22%;
        margin-left: 35px;
        margin-bottom: 5px;
    }

    .total-video-text {
        font-weight: 700;
    }

    .bread-crumb-title:hover {
        color: #353535;
        font-weight: 700;
        cursor: pointer;
    }


}