@import'../../../styles/style-variables.scss';

.checkbox-container {

    .check-box {
        width: 28px;
        height: 28px;
        
        border: 1.7px solid black;
        margin-right: 0.5rem;
        padding: 0.2rem;
    }

    .inner-selected {
        width: 100%;
        height: 100%;
        // padding: 0.6rem;
        background-color: rgb(86, 52, 221);
        border: 1px solid white;
        border-radius: 50%;
    }

    .tick-mark-size {
        width: 1.5rem;
        padding: 0.1rem;
        margin-top: -0.5rem;
        margin-left : -0.2rem;
    }
}