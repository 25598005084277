@import '../../../styles/style-variables.scss';

.event-card {
    width: 99%;

    .event-card-container {
        height: 15rem;
        // border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 25px;

    }

    .margin-bottom {
        margin-bottom: 3rem;
    }

    .event-card-bg-image {
        position: absolute;
        top: 0;
        right: 0rem;
        // width: 100%;
        height: 14.8rem;
        // object-fit: contain;
        // z-index: 20;
        // border-radius: 20px;
    }

    .logo-image-size {
        width: 5rem;
    }

    .help-image-size {
        width: 1.8rem;
    }

    .event-name {
        font-size: $font-size-22px;
        font-weight: 600;
        // color: white;
        color: #353535;
    }

    .min-height {
        min-height: 9rem;
    }

    .min-height-2 {
        min-height: 11rem;
    }

    .event-note {
        font-size: $font-size-15px;
        font-weight: 500;
        // color: white;
        color: #353535;
    }

    .paid-event-container {
        margin-left: 6.35rem;
        // min-height: 5.5rem;
    }

    .paid-event-wrapper {}



    .paid-text {
        background-color: #FF7C79;
        // border: 1px solid #F15B4C;
        padding: 0.3rem;
        border-radius: 5px;
        color: white;
        font-size: $font-size-14px;
        font-weight: 600;
    }

    .white-border {
        border: 1px solid white;
        padding: 0.3rem;
    }

    .amount-text {
        color: $white;
        font-size: $font-size-14px;
        margin-left: 0.5rem;
    }

    .know-more-button {
        color: $white;
        border-radius: 20px;
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        // padding: 0.3rem 1.5rem;
        padding: 12px 80px 11px 80px;
        font-size: $font-size-16px;
        font-weight: 700;
    }

    .secodary-logo-size {
        width: 3.5rem;
        position: absolute;
        right: 3rem;
    }

    .know-more-button-disabled {
        color: #9D9D9D;
        border-radius: 20px;
        background: #DFDFDF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: $font-size-16px;
        font-weight: 700;
        // padding: 0.3rem 1.5rem;
        padding: 12px 80px 11px 80px;

    }

    .time-running-text {
        // color: $white;
        font-size: $font-size-18px;
        font-weight: 700;
        // color: #0CC6D4;
        border-radius: 20px;
        padding: 5px 70px 5px 70px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: 2px solid #0CC6D4;
        background: linear-gradient(90deg, #0CC6D4 0%, #53D39A 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // line-height: 3rem;
    }

    .timer-text {
        // color: $white;
        font-size: $font-size-15px;
        font-weight: 600;
    }

    .paid-event-flag {
        width: 1.5rem;
    }

    .missed-event-flag {
        width: 1.8rem;
    }

    .missed-event-text {
        font-size: $font-size-16px;
        font-weight: 700;
        // color: $white;
        color: #FF8989;
    }

    .success-bg {
        // width: 10rem;
        position: absolute;
        bottom: 1rem;
        left: 15rem;
    }

    .paid-event-flag-wrapper {
        position: relative;
        z-index: 10;
    }

    .start-time-text {
        font-size: $font-size-15px;
        font-weight: 600;
        // color: $white;
    }

    .live-wrapper {
        border-radius: 40px;
        border: 1.5px solid #FF5151;
        background: #FFF0F0;
        padding: 0.2rem 1rem;
        max-width: 10rem !important;
    }

    .red-dot {
        width: 12px;
        height: 12px;
        // fill: #FF5151;
        background-color: #FF5151;
        border-radius: 50px;
        margin-right: 0.2rem;
    }

    .result-announeced-text {
        font-size: $font-size-20px;
        font-weight: 600;
        color: #353535;
    }


}