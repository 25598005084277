@import '../../../styles/style-variables.scss';
.solved-examples-container {
    min-height: 23rem; // added this code to show the next button at the bottom in exam prep kit
    // overflow-y: scroll;
    .detail-button {
        border: 1.5px solid #6f42c1;
        // background-color: #6f42c1;
        border-radius: 20px;
        padding: 1px 1.5px;
        width: 22rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #6f42c1;
        font-weight: 600;
        font-size: $font-size-20px;
        cursor: pointer;
    }

    .detail-text {
        padding: 0.5rem;
    }

    .arrow-size {
        width: 2.2rem;
    }

    .question-text {
        font-size: $font-size-24px;
        font-weight: 700;
    }

    .step-arrow-size {
        width: 1.5rem;
    }

    .ellipse-size {
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        left: -1.5rem;
        top : 1.5rem
    }

    .step-button-wrapper {
        border: 1px solid #6B52C8;
        border-radius: 10px;
        padding: 0.5rem 1rem;
    }

    .step-wrapper {
       
        span {
            font-size: $font-size-22px !important;
            font-weight: 700 !important;
        }
    }

    .step-title {
        font-size: $font-size-22px;
        font-weight: 700;
        
        // text-decoration: underline;
    }

    .border-left {
        border-left: 1px solid $dark-gray ;
        // padding-left: 1rem;
        // margin-top: -1rem;
        // top: 1.5rem;
        // position: relative;
        // margin-left: 3rem !important;
    }


   
}