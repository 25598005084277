@import'../../../styles/style-variables.scss';

.class-details-wrapper {
    margin-left: 10.2rem;
    min-height: 26rem;

    .academic-text {
        font-size: $font-size-20px;
        font-weight: 500;
        font-style: italic;
        color: #263238;
    }

    .note-text {
        background: #E5FEEC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        max-width: 70rem;
        padding: 0.8rem;
    }

    .sections-list {
        gap: 3rem;
    }

    .section-wrapper {
        border: 1px solid rgba(69, 91, 100, 0.50);
        border-radius: 10px;
        background: #FFF;
        padding: 1rem;


    }

    .active-section-bg {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        color: $white;
        border: none !important;
    }
}