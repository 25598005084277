@import '../../../styles/style-variables.scss';

.stats-card-for-practice-flow {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #455B6480;
    // width: 72.7rem;
    width: 99%;
    min-height: 15rem;
    position: relative;
    // overflow: scroll;

    .chapter-background {
        border-radius: 10px;
        background: #E8E2FF;
        padding: 1rem;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        .chapter-title {
            font-size: $font-size-20px;
            font-weight: 800;
            color: #263238;
        }

        .chapters-count {
            font-size: $font-size-18px;
            font-weight: 600;
            color: #263238;
        }

        .assessment-badge {
            border-radius: 5px;
            background: #D2CEE6;
            font-size: $font-size-12px;
            font-weight: 600;
        }

        .arrow-size {
            width: 1.3rem !important;
            margin-bottom: 0 !important;
            padding-top: 2.2px !important;
        }

        .completed-text {
            color: #60D590;
        }

        .not-started-text {
            color: #EF5350;
        }

        .inprogress-text {
            color: #FF881A;
        }

        .arrow-size-1 {
            width: 1.2rem !important;
            margin-bottom: 0 !important;

        }

        .assignment-status-text {
            font-size: $font-size-15px;
            font-weight: 700;
        }

        .status-text {
            color: var(--light-50, rgba(69, 91, 100, 0.50));
            font-size: 16px;
            font-family: Mont;
            font-weight: 600;
        }

        .created-date {
            font-size: $font-size-20px;
            font-weight: 600;
            color: #263238;
        }

        .due-date-passed-date-color {
            color: #FF7C79 !important;
        }



    }

    .icon-size {
        width: 1.3rem;
        margin-top: -0.2rem;
        margin-right: 0.2rem;
    }

    .due-date-passed {
        color: #EF5350 !important;
        font-size: $font-size-16px !important;
        font-weight: 600 !important;
    }



    .chapter-image-size {
        width: 3rem;
        margin-right: 0.5rem;
    }

    .homework-card-footer {
        position: absolute;
        bottom: 0;
        // width: 72.7rem;
        width: 100%;
        border-radius: 10px;
    }

    .homework-days-text {
        position: absolute;
        bottom: 2rem;
        right: 0;
        z-index: 1;
        border-radius: 30px 0px 0px 30px;
        background: #D2CEE6;
        padding: 0.5rem;
        width: 10rem;
    }

    .last-date-text {
        font-size: $font-size-16px;
        font-weight: 600;
        line-height: 0 !important;
        padding: 0.8rem;
        color: #FFFFFF !important;
    }

    .last-date-bg {
        background-color: #7E51D2 !important;
        // border: 1px solid #7E51D2;
        border-right: none !important;
    }

    .remaining-days-text {
        font-size: $font-size-16px;
        font-weight: 600;
        line-height: 0 !important;
        padding: 0.8rem;
        // color: #7E51D2 !important;
    }

    .days-count {
        font-weight: 800;
        font-size: $font-size-22px;
    }

    .button-padding {
        padding-top: 2rem;
        // margin-top: 3rem;
    }

    .completed-button-padding {
        padding-top: 1rem !important;
    }

    .button-wrapper {
        // position: absolute;
        z-index: 2;
        bottom: 1.5rem;
        left: 50rem;
        border-radius: 20px;
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        min-width: 6.25rem;
        height: 2.5rem;
    }

    .completed-left {
        left: 38rem !important;
    }

    .button-gap {
        gap: 6rem;
        // height: 5rem;
    }

    .view-answer-wrapper {
        // position: absolute;
        z-index: 2;
        bottom: 1.5rem;
        left: 25rem;
        border-radius: 20px;
        border: 1px solid #69C7C1;
        background: white;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem;
        width: 10rem;
        height: 2.6rem;
    }

    .start-button {
        font-size: $font-size-16px;
        font-weight: 600;
        color: white;
    }

    .view-answer-button {
        font-size: $font-size-16px;
        font-weight: 600;
        color: #69C7C1;
    }

    .exam-pad-size {
        width: 1rem;
    }

    .assessmentCardText {
        font-size: $font-size-16px;
        font-weight: 600;
        color: $sub-text-color;
    }

    .boldText {
        font-size: $font-size-20px;
        font-weight: 800;
        color: black;
    }

    .correct-button {
        padding: 0.3rem 1.5rem;
        font-size: $font-size-19px;
        border-radius: 20px;
    }

    .correct-button {
        padding: 0.3rem 1.3rem;
        font-size: $font-size-19px;
        border-radius: 20px;
    }

    .incorrect-button {
        background-color: #FDDADC;
        padding: 0.3rem 1.5rem;
        font-size: $font-size-19px;
        border-radius: 20px;
    }

    .not-answered-button {
        background-color: #EDEDED;
        padding: 0.3rem 1.5rem;
        font-size: $font-size-19px;
        border-radius: 20px;
    }
}