@import '../../../../../styles/style-variables.scss';

.grouping-question-type-read-only {
    .arrow-size {
        width: 1.8rem;
    }

    .view-answer-button {
        border-radius: 50px;
        border: 2px solid #6B52C8;
        background: #FFF;
        padding: 0.6rem;
        width: 18rem;
        text-align: center;
        font-size: $font-size-19px;
        font-weight: 500;
        color: #6B52C8;
    }

    // .view-answer-button {
    //     border-radius: 10px;
    //     // border: 2px solid #6B52C8;
    //     background: #FFF;
    //     padding: 0.6rem 4rem;
    //     // width: 15rem;
    //     text-align: center;
    //     font-size: $font-size-22px;
    //     font-weight: 600;
    //     color: #6B52C8;
    // }

    
        div::-webkit-scrollbar {
            display: none;
        }
    
        .drop-zone {
            min-width: 35rem;
            min-height: 20rem;
            background: #F2F0F9;
            border: 2px solid #B1ACE9;
            // box-shadow: 0px 7.13043px 7.13043px rgba(0, 0, 0, 0.25);
            border-radius: 14.2609px;
            margin-right: 1rem;
    
            .close-icon-wrapper {
                position: absolute;
                right: 0rem;
                top: -0.9rem;
                cursor: pointer;
            }
    
            .close-icon {
                width: 1.5rem;
            }
    
    
            .drop-zone-title {
                font-size: $font-size-19px;
                font-weight: 700;
                background: #B1ACE9;
                color: black;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                ;
            }
    
            .answer-wrapper {
                flex-wrap: wrap;
                padding: 1rem;
                gap: 1rem;
            }
    
            .answer-card {
                min-width: 15rem;
                min-height: 8rem;
                padding: 1rem;
                background: #F2F0F9;
                border: 2px solid #B1ACE9;
                box-shadow: 0px 7.13043px 7.13043px rgba(0, 0, 0, 0.25);
                border-radius: 14.2609px;
            }
    
            
        }
    
        .answer-card-container {
            overflow: scroll;
            gap: 1rem;
            margin-right: 1rem;
    
            .answer-card {
                min-width: 20rem;
                min-height: 8rem;
                padding: 1rem;
                background: #F2F0F9;
                border: 2px solid #B1ACE9;
                // box-shadow: 0px 7.13043px 7.13043px rgba(0, 0, 0, 0.25);
                border-radius: 14.2609px;
    
            }
    
            .drag-image {
                width: 1.8rem;
            }
    
        }
    
        .matching-question-container {
    
            .matching-question {
                gap: 1rem;
            }
    
    
            .question-card {
                min-width: 20rem;
                min-height: 8rem;
                padding: 1rem;
                // background: #F2F0F9;
                border: 2px solid #B1ACE9;
                box-shadow: 0px 7.13043px 7.13043px rgba(0, 0, 0, 0.25);
                border-radius: 14.2609px;
    
            }
    
            .question-card-dashed {
                border: 2px dashed rgba(69, 91, 100, 0.5);
            }
    
            .close-icon-wrapper {
                position: absolute;
                right: 0rem;
                top: -0.9rem;
                cursor: pointer;
            }
    
            .close-icon {
                width: 1.5rem;
            }
    
            .arrow-image {
                width: 3rem;
            }
        }
    
        .question-separator {
    
            // .divider-wrapper {
            //     max-width: 67rem;
            // }
    
            .question-divider-image-width {
                width: 60rem;
                height: 0.12rem;
            }
    
            .marks-image-size {
                width: 1.2rem;
                margin-right: 0.3rem;
            }
    
            .marks-container {
                border: 2.45238px solid #A99ADF;
                width: 70px;
                height: 70px;
                border-radius: 50px;
                // padding: 2rem;
            }
    
            .mark-text {
                font-size: $font-size-26px;
            }
    
        }
    
    
    


   
}