@import '../../styles/style-variables.scss';

.login-screen {

    padding: 0 !important;
    background: #F8FAFC;


    .container-height {
        height: 100vh;
        gap: 15rem;
    }


    .header-abs-div {
        position: absolute;
        top: -1rem
    }

    .footer-abs-div {
        position: absolute;
        bottom: 0.1rem
    }


    .login-header {
        // width: 96rem;
        width: 100vw !important;
        height: 6.3rem;
    }

    .login-footer {
        // width: 96rem;
        width: 100vw !important;
        height: 5.5rem;
    }

    .logo-container {
        margin-top: -10rem;
    }

    .coutingwell-logo {
        width: 28rem;
    }

    .female-workout-image {
        height: 18rem;
        margin-top: -2rem;
    }

    .master-maths-text {
        color: #416D6E;
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .master-maths-sub-text {
        font-weight: 800;
    }

    .cw-card {
        width: 28.5rem;
        min-height: 37rem;
        // height: auto;
        // height: 33rem;
        // height: 25rem;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0.625rem;


        .cw-card-body {
            padding: 20px 20px 20px 20px;
        }

        .login-title {
            font-size: $font-size-20px !important;
            font-weight: 700 !important;
        }

        .user-nickname {
            font-size: $font-size-18px;
            font-weight: 600;
        }

        .cw-input-label-1 {
            font-size: $font-size-15px !important;
            font-weight: 500 !important;
        }

        .country-code {
            min-width: 3.2rem !important;
            font-size: $font-size-18px !important;
            font-weight: 600 !important;
        }

        .country-code-down-arrow {
            width: 1.5rem;
            margin-top: -0.4rem;
            margin-left: 0.4rem;
        }

        .country-code-down-arrow-margin {
            margin-left: -0.5rem !important;
        }




        .country-code-separator {
            height: 3.2rem;
            margin-top: -0.6rem;
            margin-left: 0.5rem;
        }


        .cw-input-1 {
            border: none !important;
            outline: none;
            font-size: $font-size-18px !important;
            font-weight: 600 !important;
            background: none;
            margin-left: 10px;
        }


        .cw-input-1::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #b2aeab;
            opacity: 1;
            font-weight: 600;
            /* Firefox */
        }

        .cw-input-1:focus {
            outline: none;
        }

        .custom-toast {
            position: absolute;
            top: 5.8rem;
            left: 2.5rem;
        }

        .custom-toast-for-new-passcode {
            position: absolute;
            top: 5.8rem;
            left: 4.555rem;
        }


        .pass-code {
            position: relative;

            .passcode-boxes {
                margin-left: -0.7rem;

                input {
                    height: 3.2rem;
                    width: 4.3rem;
                    font-size: $font-size-18px !important;
                    text-align: center;
                    background: #F6F7F5;
                    // border: 1px solid #C4C4C4;
                    box-sizing: border-box;
                    // border-radius: 5px;
                    margin: 12px;
                    border: 1px solid rgba(69, 91, 100, 0.5);
                    border-radius: 10px;

                }

                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    appearance: none;
                    margin: 0;
                    /* Optional: You can also adjust the margin if needed */
                }

            }

            .forgot-passcode {
                margin-left: 0rem !important;
                font-size: $font-size-14px !important;
                color: #11BFD7;
                font-weight: 400 !important;
            }

        }

        .resend-otp {
            font-size: $font-size-14px;
            font-weight: 500;
        }


        .login-button {
            width: 13.75rem;
            height: 3rem;
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            margin-bottom: 30px;
            margin-top: 1rem;
            padding-top: 0.6rem;
            font-size: $font-size-18px;
            font-weight: 600;
            color: white
        }

        .login-button-disabled {
            background: #DFDFDF !important;
            color: #9D9D9D !important;
        }

        .invalid-mobilenumber {
            font-size: $font-size-14px;
            color: red;
        }


        .invalid {
            border: 1px solid $red !important;
        }

        .invalid-text {
            font-size: $font-size-12px;
            color: $red;
            margin-left: 0.9rem;
        }

        .verify-button {
            background: #DFDFDF !important;
            color: #9D9D9D !important;
            padding-top: 0.6rem;
            margin-top: 4rem;
        }

        .verify-button-enabled {
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            color: white;
            padding-top: 0.6rem;
            margin-top: 4rem;
        }

        .send-otp-disabled {
            background: #DFDFDF !important;
            color: #9D9D9D !important;
            padding-top: 0.6rem;
            margin-top: 13rem;
        }

        .send-otp-button-enabled {
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            color: white;
            padding-top: 0.6rem;
            margin-top: 13rem;
        }

        .enter-passcode {
            font-size: $font-size-13px;
            color: #455B64;
            margin-left: 0.9rem;
        }

        .confirm-button {
            background: #DFDFDF;
            color: #9D9D9D;
            padding-top: 0.6rem;
            margin-top: 2rem;
            font-size: $font-size-19px;
            font-weight: 600;
        }

        .confirm-button-enabled {
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            color: white;
        }

        .login-with-otp {
            width: 13.75rem;
            height: 3rem;
            box-shadow: 7px 8px 8px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            border: 2px solid transparent;
            position: relative;
            padding-top: 0.6rem;
            display: inline-block;
            font-size: $font-size-18px;
            font-weight: 600;
            color: #69C7C1;
            margin-top: 0.5rem;
        }

        .login-with-otp-disabled {
            background: #DFDFDF;
            color: #9D9D9D;
            border: none !important;
            // background: none !important;
        }

        .login-with-otp-disabled::before {
            background: none !important;
        }




        .login-with-otp::before {
            content: "";
            width: 13.75rem;
            height: 3rem;
            position: absolute;
            inset: 0;
            border-radius: 30px;


            padding: 2px;
            /* control the border thickness */
            background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            pointer-events: none;
        }

        .register-coutingwell {
            margin-top: 2rem;

            .new-to-countingwell {
                font-size: $font-size-15px;
                font-weight: 500;
            }

            .register {
                font-size: $font-size-16px;
                font-weight: 600;
                color: #85CB9E;
            }
        }

        .abs-div {
            position: absolute;
            z-index: 99999;
            top: 65px;
            left: -0.5px;
            // width: 100%;
        }




        .country-code-drop-down {
            width: 23rem;
            height: 410px;
            min-height: 120px;
            // overflow-y: scroll;
            background: white;
            border-radius: 5px;
            border: 1px solid #d7d8d6;
            position: relative;

            .country-header-div {
                position: absolute;
                top: 0;
                //  z-index: 9999;


                .country-header-img {
                    width: 23rem;
                    height: 14rem;
                    // height: 10rem;
                }
            }

            .country-footer-div {
                position: absolute;
                bottom: 0;
                //  z-index: 9999;


                .country-header-img {
                    width: 23rem;
                    // height: 1rem;
                    // height: 10rem;
                }
            }

            .country-close-icon {
                position: absolute;
                z-index: 99999;
                top: 0.6rem;
                right: 0.8rem;

                .close-icon {
                    width: 1.5rem;
                }
            }

            .select-country {
                position: absolute;
                top: 1.8rem;
                left: 1.5rem;
                font-size: $font-size-17px;
                font-weight: 700;
            }

            .country-search-div {
                position: absolute;
                z-index: 9999996;
                top: 5rem;

                .country-search {
                    width: 25px;
                    margin-left: 2rem;
                    // margin-top: -0.2rem;
                    margin-top: -1.7rem;
                }

            }


            .search {
                position: sticky;
                top: 3rem;
                z-index: 999999;
                left: 30px;
                width: 90%;
                margin-top: 3.3rem;

                input {
                    background: #F6F7F5;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 30px;
                    min-height: 3rem;
                    // background: #F6F7F5;
                    border: 1px solid #F6F7F5;
                    // border-radius: 50px;
                    font-size: $font-size-14px;
                    width: -webkit-fill-available;
                    text-indent: 35px;
                    margin-left: 1rem;
                }



                input:focus {
                    background: #F6F7F5;
                    border: 1px solid #F6F7F5;
                }

                label {
                    margin: 0 !important;
                    font-size: 14px;
                }

            }

            .dropdown-scroll {
                overflow-y: auto;
                max-height: 15.625rem;
                min-height: 7.5rem;
            }

            .dropdown-list {
                .country-name {
                    font-size: $font-size-15px;
                }

                .country-name:hover {
                    font-weight: 700;
                }
            }


            .no-data {
                label {
                    font-size: 14px;
                }
            }

        }



    }

    .back-arrow {
        width: 1.2rem;
    }

    .edit-icon-for-login {
        width: 1.5rem !important;
        // padding: 0.5rem;
    }

    .custom-toast-for-invalid-otp {
        position: absolute;
        right: 5rem;
    }


    .forgot-passcode-container {

        .forgot-passcode-edit-icon {
            position: absolute;
            right: 1.5rem;
            top: 1.3rem;
        }

        .custom-toast-for-forgot-passcode {
            position: absolute;
            right: 17rem;
        }



        .custom-toast-for-passcode-not-match {
            position: absolute;
            right: 19rem;
            bottom: 10rem;
        }


    }

    .custom-toast-for-invalid-passcode {
        position: absolute;
        right: 4rem;
    }



}

.mobile-login-screen {

    // padding: 2rem;

    margin-top: 10rem;


    .header-abs-div {
        position: absolute;
        top: -1rem
    }

    .footer-abs-div {
        position: absolute;
        bottom: 0.1rem
    }


    .login-header {
        // width: 96rem;
        width: 100vw !important;
        height: 6.3rem;
    }

    .login-footer {
        // width: 96rem;
        width: 100vw !important;
        height: 5.5rem;
    }

    .info-text {
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: $font-size-22px;
        font-weight: 600;
        color: $app-black;
    }

    .app-button {
        background: linear-gradient(90deg, #06c5d9, #60d590);
        box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
        border-radius: 30px;
        color: #fff;
        padding: 1rem 0;
        width: 22rem;
        font-size: 1.125rem;
        font-weight: 500;
        margin-top: 1.2rem;
        // width: 95vw;
        display: flex;
        justify-content: center;
    }

    .navigation-text {
        // margin-top: 3rem;
        padding-left: 0.5rem;
        padding-right: 0.3rem;
        font-size: $font-size-20px;
        font-weight: 600;
        color: white;

    }
}