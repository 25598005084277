@import '../../../styles/style-variables.scss';

.classwork-concept-list {

    .image-size {
        width: 40rem;
        // height: 80px;
    }

    .header-text {
        font-size: $font-size-28px;
        font-weight: 700;
    }

    .sub-header-text {
        font-size: $font-size-18px;
        font-weight: 500;
        color: #263238;
    }

    .desktop-image-size {
        width: 15rem;
    }


    .header-for-practice-question {
        margin-top: -3.7rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover, .home:hover, .title-2:hover {
        color: black;
        font-weight: 800;
    }


    .divider-image-width {
        // width: 1150px;
        width: 98%;
        height: 1.5px;
    }

    .selected-text {
        font-size: $font-size-22px;
        font-weight: 500;
        color: white;
        background: #6B52C8;
    }

    .row-max-width {
        width: 40rem;
        background: #FFFFFF;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        height: 49.6px;
    }

    .single-status-width {
        width: 20rem !important;
    }

    .not-seleted-text {
        font-size: $font-size-22px;
        font-weight: 600;
        color: $sub-text-color;
        // background: #6B52C8;
    }

    .border-top-right-radius {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .border-top-left-radius {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .green-circle {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: #71D3CD;
    }

    .red-circle {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: #F56D77;
    }
    .gray-circle {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: #C4C4C4;
    }

    .concept-title {
        font-size: $font-size-22px;
        font-weight: 700;
        color: #263238;
    }

    .elo-title {
        font-size: $font-size-19px;
        font-weight: 600;
        color: $text-black-color;
    }

    // .column-width {
    //     width: 20rem !important;
    // }

    .custom-button {
        font-size: $font-size-18px;
        font-weight: 500;
        color: white;
        background-color: #6B52C8;
        border-radius: 30px;
        padding: 5px 5px 5px 5px;
        max-width : 8rem;
    }

    .lock-image-size {
        width: 2rem;
    }


    .cleared-text {
        font-size: $font-size-22px;
        color: #31D48F;
        font-weight: 700;
    }


    .not-cleared-text {
        font-size: $font-size-22px;
        color: #F36D73;
        font-weight: 700;
    }

    .mobile-app-icon {
        max-width: 100%;
        height: 22rem;
        object-fit: contain;
    }

    .mobile-app-icon-with-text {
        max-width: 100%;
        height: 358px;
        object-fit: contain;
    }

    .button-wrapper {
        width: 70%;
        border-radius: 120px;
        background: #FFC727;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .send-me-text {
        font-size: $font-size-16px;
        font-weight: 600;
        color: $white;
    }

    .learning-text {
        font-size: $font-size-19px;
        font-weight: 600;
        color: black;
    }

    .classwork-completed-text {
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .success-image-size {
        width: 37px;
        height: 37px;
    }




    .store-wrapper {
        gap: 2rem;
    }

    .store-icon {
        width: 1.8rem;
    }

    .app-store-button, .play-store-button {
        background-color: $white;
        font-size: $font-size-16px;
        font-weight: 600;
        border-radius: 41px;
        padding: 0.5rem 1rem;
    }

    .button-padding {
        padding-top: 2rem;
        // margin-top: 3rem;
    }

    .completed-button-padding {
        padding-top: 1rem !important;
    }

    .button-wrapper {
        // position: absolute;
        z-index: 2;
        bottom: 1.5rem;
        left: 50rem;
        border-radius: 20px;
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        min-width: 6.25rem;
        height: 2.5rem;
    }

    .completed-left {
        left: 38rem !important;
    }

    .button-gap {
        gap: 6rem;
        // height: 5rem;
    }

    .view-answer-wrapper {
        // position: absolute;
        z-index: 2;
        bottom: 1.5rem;
        left: 25rem;
        border-radius: 20px;
        border: 1px solid #69C7C1;
        background: white;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem;
        width: 10rem;
        height: 2.6rem;
    }

    .start-button {
        font-size: $font-size-16px;
        font-weight: 600;
        color: white;
    }


    .view-answer-button {
        font-size: $font-size-16px;
        font-weight: 600;
        color: #69C7C1;
    }

    .exam-pad-size {
        width: 1rem;
    }

    .assessmentCardText {
        font-size: $font-size-16px;
        font-weight: 600;
        color: $sub-text-color;
    }

    .boldText {
        font-size: $font-size-20px;
        font-weight: 800;
        color: black;
    }

    .correct-button {
        padding: 0.3rem 1.5rem;
        font-size: $font-size-19px;
        border-radius: 20px;
    }

    .correct-button {
        padding: 0.3rem 1.3rem;
        font-size: $font-size-19px;
        border-radius: 20px;
    }

    .incorrect-button {
        background-color: #FDDADC;
        padding: 0.3rem 1.5rem;
        font-size: $font-size-19px;
        border-radius: 20px;
    }

    .not-answered-button {
        background-color: #EDEDED;
        padding: 0.3rem 1.5rem;
        font-size: $font-size-19px;
        border-radius: 20px;
    }




}