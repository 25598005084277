@import "../../../styles/style-variables.scss";

.learning-resources-filter{

    .filter-buttons, .assign-section{
        display: flex;
        align-items: flex-end;
    }

    .cw-input-for-search {
        border-radius: 1.875rem;
        width: 100%;

        border: 1px solid #A9A9A9 !important;
        height: 2.2rem !important;
        background: $report-gb-color;
        border: none;
        font-size: $font-size-12px;
        padding: 1rem;
        color: $app-black !important;
        outline: none;
    }

    .assign-section {
        justify-content: center;
        // margin-left: 2rem !important;
        margin-left: 13rem !important;
    }

    .un-assigned-text{
        position: absolute;
        color: red;
        font-size: $font-size-12px;
        font-weight: 400;
        // margin-left: -3rem;
    }

    .disable-input {
        background-color: #D9D9D9 !important;
    }

    .margin-1 {
        margin-left: -3rem;
    }

    .margin-1 {
        margin-left: -1rem;
    }
 
    .filter-header{

        margin: 0 !important;;

        .filter-title{
            font-size: $font-size-14px !important;
            color: #000000;
            font-weight: bold;
        }

        .filter-arrow {
            border: solid black;
            border-width: 0 0.1rem 0.1rem 0;
            display: inline-block;
            padding: 0.18rem;
            margin-left: 0.5rem;

        }

        .up {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
        }

        .down {
            margin-bottom: 0.1rem;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
    }

    .filter-content{
        transition: .3s all ease;
    }

    .filter-input-label{
        font-size: $font-size-12px;
        //line-height: 1rem;
        padding-bottom: 0.4rem;
        padding-left: 0.9rem;
        margin-bottom: 0 !important;
        color: $report-filter-label;
        font-weight: 600;
    }

    .filter-splitter{
        margin-top: 1.2rem;
    }

    .filter-button{
        // margin-top: 1.35rem;
    }

    .clear-btn{
        background-color: #008FFF;
    }

    .cw-input{
        background-color: white;
    }

}


