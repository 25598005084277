@import '../../../styles/style-variables.scss';

.paid-event-landing-screen {

    .header-image-size {
        width: calc(100% + 48px);
        margin-left: -3rem;
        height: 5rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        // margin-top: -4rem;
        position: absolute;
        top: 5.5rem;
        background: transparent !important;
        padding: 0 !important;
        // z-index: 12;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .title {
        font-size: $font-size-22px;
        font-weight: 700;
        font-style: 'Mont';
        font-family: 'Mont';
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .description-text {
        font-size: $font-size-17px;
        font-weight: 400;
        max-width: 95%;
    }

    .comming-soon-text {
        border-radius: 40px;
        border: 2px solid #7964D3;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem 1.5rem;
        color: #7964D3;
        font-size: $font-size-19px;
        font-weight: 600;
    }

    .divider-size {
        width: 98%;
        height: 0.2rem;
    }

    .paid-card {
        max-width: 35rem;
        gap: 1rem;
        border-radius: 20px;
        border: 2px solid #7964D3;
        background: #F8F5FE;
        box-shadow: 2px 4px 4px 0px rgba(160, 160, 160, 0.25);
        padding: 0.5rem 1.5rem;
    }

    .paid-logo,
    .tick-icon {
        width: 4rem;
    }

    .footer-size {
        width: calc(100% - 370px);
        position: absolute;
        bottom: 0;
        right: 0;
        height: 5rem;
    }

    .sub-header-text {
        font-size: $font-size-18px;
        font-weight: 600;
    }

    .pay-button {
        border-radius: 40px;
        background: #6B52C8;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: $white;
        font-size: $font-size-17px;
        font-weight: 600;
        padding: 0.6rem 2.5rem;
    }

}