@import'../../../styles/style-variables.scss';

.cw-custom-modal-center-for-concepts {
    font-size: $font-size-12px;
    margin-top: 12rem !important;
    float: center;
    max-width: 650px !important;
    height: 450px !important;

    .pratice-container {
        margin-top: -3rem;
      }
    
  
    .modal-content {
  
      padding: 1rem;
      background: #F8FAFC;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    }
  
    .challenge-header {
      width: 650px;
      height: 350px;
      position: absolute;
  
      left: 0rem;
      top: 0rem;
      border-radius: 20px;
    }
  
    .challenge-footer {
      width: 650px;
      height: 89px;
      position: absolute;
  
      left: -2rem;
      top: -3.5rem;
      border-radius: 20px;
      z-index: 1;
    }
  
    .position {
      position: relative;
    }
  
    .header-text {
      font-size: 22px;
      font-weight: 700;
    }
  
    .close-icon {
      width: 30px;
      float: right;
      position: relative;
      top: -1.3rem;
      right: 0.5rem;
    }
  
    .sub-text {
      font-size: 0.9rem;
      font-weight: 500;
      margin-left: 2rem;
    }
  
    .image-width {
      width: 180px;
    }
  
    .name-header {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: $font-size-22px;
      line-height: 27px;
      text-align: center;
      color: #000000;
    }
  
    .challege-yourself {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 800;
      font-size: $font-size-18px;
      line-height: 27px;
      text-align: center;
      color: #000000;
    }
  
    .take-challenge {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #FFFFFF;
    //   line-height: 26px;
      width: 200px;
      height: 45px;
      background: #6B52C8;
      border-radius: 30px;
      position: relative;
      z-index: 10;
    }
  
   
    .margin-left {
      margin-left: 5.5rem;
      margin-bottom: 1rem;
      font-weight: 800;
      font-size: 1rem;
    }

    .concepts-covered-wrapper {
        min-height: 10rem;

        .concept-title {
            font-size: $font-size-19px;
            font-weight: 700;
            color: #263238;
        }

        .elo-title {
            font-size: $font-size-16px;
            font-weight: 600;
            color: $sub-text-color;
        }
    }
  }