@import '../../styles/style-variables.scss';

.my-certificate-list {

    .certificate-header {
        .title {
            font-size: $font-size-28px;
            font-weight: 700;
        }

        .image-size {
            width: 900px;
            height: 80px;
        }

        .divider-image-width {
            width: 98%;
            height: 1.5px;
        }

        .header-title-margin-top {
            margin-top: -4rem;
        }

        .breadcrumb {
            font-size: $font-size-16px;
            color: $sub-text-color;
            font-weight: 600;

            background: transparent !important;
            padding: 0 !important;

            .active {
                color: #1C1C1C;
            }
        }

        .title-1:hover,
        .home:hover {
            color: black;
            font-weight: 800;
        }
    }


    .card-wrapper {
        flex-wrap: wrap;
        gap: 3rem;
        margin-top: 1rem;
        margin-left: 1rem;
    }

    .certificate-list-card {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: 1px solid #455B6480;
        // width: 72.7rem;
        width: 45%;
        min-height: 14rem;
        // position: relative;
        display: flex;
        // justify-content: center;
        padding-top: 1rem;
        align-items: center;
        flex-direction: column;
    }

    .event-name {
        font-size: $font-size-18px;
        font-weight: 700;
        margin-bottom: 0.2rem;
    }

    .event-image {
        max-width: 100%;
        object-fit: contain;
        height: 7rem;
    }

    .view-certificate-button-wrapper {
        // z-index: 2;
        bottom: 1.5rem;
        left: 50rem;
        border-radius: 20px;
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        min-width: 6.25rem;
        height: 2.5rem;
        color: white;
        margin-top: 0.5rem;

        .view-certificate-button {

        }
    }

}