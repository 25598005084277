@import "../../../styles/style-variables.scss";

.pill-button-select {
    .pill-button {
        display: table-cell !important;
        vertical-align: middle;
        font-weight: 800;
        height: 5rem;
        font-size: $font-size-20px;
        min-width: 120px;
    }

    .pill-button-selected {
        color: white;
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        border-radius: 10px;
    }

    .pill-button-not-selected {
        color: #B2AEAB;
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 5px;
    }

    .pill-button-selected-for-class {
        color: #455B64;
        background: #E5FEEC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    .pill-button-not-selected-for-class {
        columns: #455B64;
        background: #FFFFFF;
        border: 4px solid #E5FEEC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    .class-pill-button-size {
        width: 14.25rem;
    }

    .green-tick-mark {
        width: 2.5rem;
    }

    .sm {
        height: 40px;
        min-width: 90px;
    }

    .md {
        height: 50px;
        min-width: 140px;
    }

    .lg {
        height: 80px;
        min-width: 190px;
    }

    .scroll-view {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        padding-bottom: 5px;
    }

    #pill-scroll-1::-webkit-scrollbar {
        height: 4px;
        background-color: transparent;
    }

    #pill-scroll-1::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #999999;
    }

    .class-image-size {
        width: 2.5rem;
    }

}