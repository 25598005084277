@import '../../styles/style-variables.scss';

.clicker-testing-student-answered-status-board {
    // width: 84vw;
    // min-height: 90vh;
    // background-color: rgb(240, 248, 240);
    // padding: 1rem;


    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .arrow-size {
        width: 0.6rem;
        margin-bottom: 0.2rem;
    }

    .image-size {
        width: 40rem;
        // height: 80px;
    }

    .sub-header-text {
        font-size: $font-size-32px;
        font-weight: 600;
    }

    .chapter-title {
        font-size: $font-size-32px;
        font-weight: 600;
        font-style: 'Mont';
        font-family: 'Mont';
    }

    .header-for-practice-question {
        margin-top: -3.7rem;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }


    .timer-text {
        font-size: $font-size-18px !important;
        color: black;
    }

    .button-wrapper {
        max-width: 75%;
        // max-height: 10rem !important;
        flex-wrap: wrap;
    }

    .student-roll-number-button {
        // border: 1px solid darkgray;
        color: white;
        background-color: #20D2F6;
        // padding: 0.2rem 1rem;
        border-radius: 10px;
        width: 6rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        font-weight: 600;

    }

    .enable-button {
        background-color: #000080 !important;
    }
}