@import '../../../styles/style-variables.scss';
.view-certificate {
    .certificate-header {
        .title {
            font-size: $font-size-28px;
            font-weight: 700;
        }

        .image-size {
            width: 900px;
            height: 80px;
        }

        .divider-image-width {
            width: 98%;
            height: 1.5px;
        }

        .header-title-margin-top {
            margin-top: -4rem;
        }

        .breadcrumb {
            font-size: $font-size-16px;
            color: $sub-text-color;
            font-weight: 600;

            background: transparent !important;
            padding: 0 !important;

            .active {
                color: #1C1C1C;
            }
        }

        .title-1:hover,
        .home:hover {
            color: black;
            font-weight: 800;
        }
    }

    .certificate-size {
        max-width: 100%;
        object-fit: contain;
        height: auto;
    }

}
