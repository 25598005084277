@import '../../../styles/style-variables.scss';

.practice-completion-screen {
    .header-image-size {
        // width: 76rem;
        width: 100%;
        height: 4rem;
        position: absolute;
        left: 0;
        top: 5rem;
    }

    .screen-bg {
        position: absolute;
        top: 13rem;
        height: 27rem;
        width: 50rem;
        right: 10rem;
        // z-index: 1;
    }

    .footer-image-size {

        width: 100%;
        height: 4rem;
        position: absolute;
        bottom: 0;
        right: 0;
        // left: 16rem;
    }

    .breadcrumb {
        position: absolute;
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        top: 4.8rem;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .divider {
        background-color: #6B52C8;
        width: 96.5%;
        height: 0.15rem;
    }

    .learning-gap {
        font-size: $font-size-26px;
        font-family: "Mont";
        font-style: normal;
        font-weight: 800;
    }

    .view-details {
        font-size: $font-size-19px;
        font-weight: 500;
        color: #5B99E6;
    }

    .lets-fill {
        font-size: $font-size-19px;
        font-weight: 800;
        color: #263238;
    }

    .button {
        border-radius: 30px;
        background: #FFFFFF;
        width: 10rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: $font-size-19px;
        padding: 0.5rem 1rem;
        font-weight: 600;
        color: #6B52C8;
        border: 1px solid #6B52C8;
    }

    .learning-gap-button {
        border-radius: 30px;
        background: #6B52C8;
        width: 18rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: $font-size-19px;
        padding: 0.5rem 1rem;
        font-weight: 600;
        color: white;
    }

    .continue-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.5rem 1rem;
        width: 18rem !important;
        font-size: $font-size-19px;
        font-weight: 600;
    }

    .workout-image-size {
        width: 11rem;
    }

    .success-image-size {
        width: 10rem;
    }

    .arrow-size {
        width: 1.3rem;
    }

    .chapter-concept-list-card {
        border: 1px solid #6B52C8;
        padding: 0.8rem;
        border-radius: 10px;
        min-width: 35rem;
    }

    .great-message {
        font-size: $font-size-28px;
        font-weight: 800;
        color: #263238;
        position: relative;
    }

    .non-descriptive-text {
        font-size: $font-size-20px;
        font-weight: 500;
    }

    .chapter-title {
        font-size: $font-size-18px;
        font-weight: 600;
    }

    .revise-text {
        font-size: $font-size-18px;
        font-weight: 500;
    }

    .lets-text {
        color: #455B64;
        font-size: $font-size-16px;
        font-weight: 600;
    }

    .message {
        font-size: $font-size-19px;
        font-weight: 600;
        color: $sub-text-color;
    }

    .featuresItemBullet {
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background-color: $sub-text-color;
    }

    .chapter-title {
        font-size: $font-size-19px;
        font-weight: 800;
        color: #263238;
    }

    .concept-name {
        font-size: $font-size-18px;
        font-weight: 500;
        color: $sub-text-color;
    }

    .moreText {
        font-size: $font-size-15px;
        font-weight: 500;
        color: $text-black-color;
    }

    .success-screen {
        overflow: scroll;
        max-height: 40rem;
        overflow-x: hidden;
    }

    // div::-webkit-scrollbar {
    //     display: none;
    // }

    .continue-learning {
        font-size: $font-size-15px;
        font-weight: 500;
        color: white;
        background-color: #6B52C8;
        padding: 0.8rem 2rem;
        border-radius: 30px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        min-width: 16rem;
        position: relative;
        z-index: 1;

    }

    .go-to-home {
        font-size: $font-size-15px;
        font-weight: 500;
        color: #6B52C8;
        // background-color: ;
        padding: 0.8rem 2rem;
        border-radius: 30px;
        background: #FFFFFF;
        border: 1px solid #6B52C8;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        min-width: 15rem;
        position: relative;
        z-index: 1;

    }

    .divider-gray-size {
        width: 34rem;
    }



}