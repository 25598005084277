@import '../../../styles/style-variables.scss';

.cw-custom-modal-center-for-country {
    font-size: $font-size-12px;
    margin-top: 10rem !important;
    max-width: 600px !important;
    height: 450px !important;
  
    .modal-content {
      padding: 1rem;
      background: #F8FAFC;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    }
  
    .challenge-header {
      width: 600px;
      height: 350px;
      position: absolute;
  
      left: 0rem;
      top: 0rem;
      border-radius: 20px;
    }
  
    .challenge-footer {
      width: 600px;
      height: 89px;
      position: absolute;
  
      left: -2rem;
      top: -3.5rem;
      border-radius: 20px;
      z-index: 1;
    }
  
    .position {
      position: relative;
    }

    .country-code-drop-down {
        width: 23rem;
        max-height: 400px;
        min-height: 120px;
        overflow-y: scroll;
        background: white;
        border-radius: 5px;
        border: 1px solid #d7d8d6;

        .country-header-div {
            position: sticky;
            top: 10px;
            // z-index: 9999999;

            .country-header-img {
                width: 100%;
            }
        }

        .search {
            position: sticky;
            top: 3rem;
            z-index: 999999;
            left: 15px;
            width: 90%;
            margin-top: -10rem;

            input {
                background: #F6F7F5;
                border: 1px solid #F6F7F5;
                border-radius: 50px;
                font-size: 12px;
                width: -webkit-fill-available;
            }

            input:focus {
                background: #F6F7F5;
                border: 1px solid #F6F7F5;
            }

            label {
                margin: 0 !important;
                font-size: 14px;
            }

        }

        .dropdown-list {
            .country-name {
                font-size: 12px;
            }

            .country-name:hover {
                font-weight: 700;
            }
        }


        .no-data {
            label {
                font-size: 14px;
            }
        }

    }

  
    .header-text {
      font-size: 22px;
      font-weight: 700;
    }
  
    .close-icon {
      width: 24px;
      float: right;
      position: relative;
      top: -1.3rem;
      right: 0.5rem;
    }
  
    .sub-text {
      font-size: 0.9rem;
      font-weight: 500;
      margin-left: 2rem;
    }
  
    .image-width {
      width: 180px;
    }
  
    .name-header {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: $font-size-18px;
      line-height: 27px;
      text-align: center;
      color: #000000;
    }
  
    .challege-yourself {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 800;
      font-size: $font-size-18px;
      line-height: 27px;
      text-align: center;
      color: #000000;
    }
  
    .take-challenge {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 26px;
      width: 240px;
      height: 60px;
      background: #6B52C8;
      border-radius: 30px;
      position: relative;
      z-index: 10;
    }
  
    .pratice-container {
      margin-top: -2rem;
    }
  
    .margin-left {
      margin-left: 5.5rem;
      margin-bottom: 1rem;
      font-weight: 800;
      font-size: 1rem;
    }
  }