@import '../../../styles/style-variables.scss';

.classwork-completion-screen {
    .header-image-size {
        // width: 76rem;
        width: 100%;
        height: 5rem;
    }

    .screen-bg {
        position: absolute;
        top: 13rem;
        height: 27rem;
        width: 50rem;
        right: 10rem;
        // z-index: 1;
    }

    .footer-image-size {
        
        width: calc(100% - 256px);
        height: 5rem;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 16rem;
    }

    .learning-gap {
        font-size: $font-size-26px;
        font-family: "Mont";
        font-style: normal;
        font-weight: 800;
    }

    .view-details {
        font-size: $font-size-19px;
        font-weight: 500;
        color: #5B99E6;
    }

    .lets-fill {
        font-size: $font-size-19px;
        font-weight: 800;
        color: #263238;
    }

    .button {
        border-radius: 30px;
        background: #DCDCFC;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 280px;
        height: 50px;
        padding: 12px 60.278px 18.364px 61.25px;
        font-size: $font-size-19px;
        font-weight: 600;
        color: #6B52C8;
    }

    .workout-image-size {
        width: 13rem;
    }

    .success-image-size {
        width: 10rem;
    }

    .arrow-size {
        width: 1.3rem;
    }

    .chapter-concept-list-card {
        border: 1px solid #6B52C8;
        padding: 0.8rem;
        border-radius: 10px;
        min-width: 35rem;
    }

    .great-message {
        font-size: $font-size-28px;
        font-weight: 800;
        color: #263238;
    }

    .message {
        font-size: $font-size-19px;
        font-weight: 600;
        color: $sub-text-color;
    }

    .featuresItemBullet {
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background-color: $sub-text-color;
    }

    .chapter-title {
        font-size: $font-size-19px;
        font-weight: 800;
        color: #263238;
    }

    .concept-name {
        font-size: $font-size-18px;
        font-weight: 500;
        color: $sub-text-color;
    }

    .moreText {
        font-size: $font-size-15px;
        font-weight: 500;
        color: $text-black-color;
    }

    .success-screen {
        overflow: scroll;
        max-height: 40rem;
        overflow-x: hidden;
    }

    // div::-webkit-scrollbar {
    //     display: none;
    // }

    .continue-learning {
        font-size: $font-size-15px;
        font-weight: 500;
        color: white;
        background-color: #6B52C8;
        padding: 0.8rem 2rem;
        border-radius: 30px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        min-width: 16rem;
        position: relative;
        z-index: 1;

    }

    .go-to-home {
        font-size: $font-size-15px;
        font-weight: 500;
        color: #6B52C8;
        // background-color: ;
        padding: 0.8rem 2rem;
        border-radius: 30px;
        background: #FFFFFF;
        border: 1px solid #6B52C8;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        min-width: 15rem;
        position: relative;
        z-index: 1;

    }

    .divider-gray-size {
        width: 34rem;
    }



}