@import '../../../styles/style-variables.scss';

.assessment-footer {

  position: fixed;
  width: calc(100% + 48px) !important;
  margin-left: -3rem !important;
  bottom: 0;
  right: 0;
  // top : 5rem;
  // left: 0;
  // height: 5rem;

  // bottom: 0;
  // z-index: -1;
  // min-height: 7rem;

  .footer-image-size {
    // position: relative;
    // position: absolute;
    // bottom: 0;
    // right: 0;
    // width: 78.1vw !important;
    width: 100%;
    // object-fit: contain;
    height: 5rem;
    // object-fit: contain;
    // margin-left: -3rem !important;

  }


  .button-wrapper {
    position: relative;
    top: 28px;
    // left: 10rem;
    z-index: 1000;
    right: 4.5rem;
    // bottom: 0;
    // margin-right: calc(100vh - 50px);
  }

  .button-wrapper-for-finish {
    position: relative;
    top: 33px;
    // left: 10rem;
    z-index: 1000;
    right: 24rem;
  }

  .button-wrapper-for-read-only {
    position: relative;
    top: 28px;
    // left: 10rem;
    z-index: 1000;
    right: 3.5rem;
    // bottom: 0;
    // margin-right: calc(100vh - 50px);
  }

  .back-button {
    background: white;
    border: 2px solid #6B52C8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: #6B52C8;
    padding: 1.1rem 0rem;
    // padding: 10px 31px;
    // padding: 18px 39px;
    width: 12rem;
    font-size: $font-size-18px;
    font-weight: 500;
    // margin-top: 1.2rem;
    position: relative;
    // z-index: -1;
  }

  .finish-button {
    background: white;
    border: 2px solid #6B52C8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: $white;
    padding: 1.1rem 0rem;
    // padding: 10px 31px;
    // padding: 18px 39px;
    width: 12rem;
    font-size: $font-size-18px;
    font-weight: 500;
    // margin-top: 1.2rem;
    position: relative;
    // z-index: -1;
  }


  .next-button {
    background: #6B52C8;
    border: 2px solid #6B52C8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: white;
    // padding: 1.1rem 0rem;
    // padding: 10px 31px;
    // padding: 18px 39px;
    // width: 10%;
    padding: 0.5rem 4rem;
    font-size: $font-size-20px;
    font-weight: 600;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 1.2rem;
    // position: relative;
    // margin-left: 23rem;
    // top : -5rem;
    // z-index: -1;
    position: relative;
  }

  .next-button-2 {
    background: #6B52C8;
    border: 2px solid #6B52C8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: white;
    padding: 1.1rem 0rem;
    // padding: 10px 31px;
    // padding: 18px 39px;
    width: 12rem;
    font-size: $font-size-18px;
    font-weight: 500;
    height: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 1.2rem;
    // position: relative;
    // margin-left: 23rem;
    // top : -5rem;
    // z-index: -1;
  }
}

// .clock {
//     width: 300px;
//     height: 300px;
//     border-radius: 50%;
//     background-color: #D6CBFF;
//     margin: 0 auto;
//     position: relative;
//     border: 3px solid #000000;
// }

// .hand {
//     position: absolute;
//     left: calc(50% - 50px);
//     top: 50%;
//     width: 100px;
//     height: 150px;
//     background-color: #000000;
//     transform-origin: center 0;
//     border-radius: calc(300px / 2);
//     // transform: rotate(20deg);
//     animation: rotate linear calc(10 * 12) infinite;
//   animation-delay: calc(
//     5 * -1
//   );
// }

.timer-wrapper {
  display: flex;
  justify-content: center;
  // position: absolute;
  // top: -0.7rem;
  // right: calc(100% - 58.5%);
}

.timer-placement {
  margin-top: -4rem;
  margin-left: -1.5rem;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: $font-size-16px;
  // background: #6B52C8;
  // color: white;
}