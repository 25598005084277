@import '../../../styles/style-variables.scss';

.correct-incorrect-screen {
    // font-size: $font-size-12px;
    // margin-top: 10rem !important;
    // // margin-left: 30rem !important;
    // margin: auto auto;
    // // float: center;
    // // min-width: 800px !important;
    // max-width: 800px !important;
    // min-height: 200px !important;
    div::-webkit-scrollbar {
        display: none;
    }

    .assessment-footer {

        // position: absolute;
        width: calc(100% + 48px);
        margin-left: -3rem !important;
        bottom: 0;
        right: 0;
        .footer-image-size {
            // position: absolute;
            // bottom: 0;
            // right: 0;
            // width: 78.1vw !important;
            width: 100%;
            height: 6rem;
            // object-fit: contain;
            // margin-left: -3rem !important;
    
        }

        .button-wrapper {
            position: relative;
            top: 33px;
            // left: 10rem;
            z-index: 1000;
        }
    
        .back-button {
            background: white;
            border: 2px solid #6B52C8;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            color: #6B52C8;
            padding: 1.1rem 0rem;
            // padding: 10px 31px;
            // padding: 18px 39px;
            width: 12rem;
            font-size: $font-size-18px;
            font-weight: 500;
            // margin-top: 1.2rem;
            position: relative;
            // z-index: -1;
        }
    
        .finish-button {
            background: white !important;
            border: 2px solid #6B52C8;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            color: #6B52C8;
            padding: 1.1rem 0rem;
            // padding: 10px 31px;
            // padding: 18px 39px;
            width: 16rem;
            font-size: $font-size-18px;
            font-weight: 500;
            // margin-top: 1.2rem;
            position: relative;
            // z-index: -1;
        }
    
    
        .next-button {
            background: #6B52C8;
            border: 2px solid #6B52C8;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            color: white;
            // padding: 1.1rem 0rem;
            // padding: 10px 31px;
            // padding: 18px 39px;
            width: 12rem;
            font-size: $font-size-18px;
            font-weight: 500;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-top: 1.2rem;
            // position: relative;
            // margin-left: 23rem;
            // top : -5rem;
            // z-index: -1;
        }
    
        .next-button-2 {
            background: #6B52C8;
            border: 2px solid #6B52C8;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            color: white;
            padding: 1.1rem 0rem;
            // padding: 10px 31px;
            // padding: 18px 39px;
            width: 12rem;
            font-size: $font-size-18px;
            font-weight: 500;
            height: 4.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-top: 1.2rem;
            // position: relative;
            // margin-left: 23rem;
            // top : -5rem;
            // z-index: -1;
        }
    
    
    }

    


    .pratice-container {
        margin-top: -2rem;
    }


    .modal-content {
        padding: 1rem;
        background: #F8FAFC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        min-height: 15rem !important;
        max-height: 40rem !important;
    }

    // .challenge-header {
    //     width: 800px;
    //     height: 350px;
    //     position: absolute;

    //     left: 0rem;
    //     top: 0rem;
    //     border-radius: 20px;
    // }

    // .challenge-footer {
    //     width: 800px;
    //     height: 70px;
    //     position: absolute;
    //     left: -2rem;
    //     top: -2.3rem;
    //     border-radius: 20px;
    //     z-index: 1;
    // }

    .position {
        position: relative;
    }

    .image-size {
        width: 2.5rem;
    }

    .header-text {
        font-size: 22px;
        font-weight: 700;
    }

    .close-icon {
        width: 30px;
        float: right;
        position: relative;
        top: -1.3rem;
        right: 0.5rem;
    }

    .sub-text {
        font-size: 0.9rem;
        font-weight: 500;
        margin-left: 2rem;
    }

    .image-width {
        width: 180px;
    }

    .name-header {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-20px;
        line-height: 27px;
        // text-align: center;
        color: #263238;
    }

    .sub-title {
        font-size: $font-size-15px; 
        font-weight: 400;
        color: $sub-text-color;
    }

    .challege-yourself {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 800;
        font-size: $font-size-18px;
        line-height: 27px;
        text-align: center;
        color: #000000;
    }

    .take-challenge {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        //   line-height: 26px;
        width: 180px;
        height: 51px;
        background: #6B52C8;
        border-radius: 30px;
        position: relative;
        z-index: 10;
        padding-top: 0.85rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .view-correct-answer {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #6B52C8;
        //   line-height: 26px;
        // width: 180px;
        height: 50px;
        background: white;
        border: 1px solid #6B52C8;
        border-radius: 30px;
        position: relative;
        z-index: 10;
        padding-top: 0.8rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }


    .margin-left {
        margin-left: 5.5rem;
        margin-bottom: 1rem;
        font-weight: 800;
        font-size: 1rem;
    }

    .concepts-covered-wrapper {
         min-height: 19.5rem;
        // max-height: 26rem;
        //  max-height: 19.5rem;
         overflow: scroll;
        // flex-wrap: wrap;
         overflow-x: hidden;

        .concept-title {
            font-size: $font-size-19px;
            font-weight: 700;
            color: #263238;
        }

        .elo-title {
            font-size: $font-size-16px;
            font-weight: 600;
            color: $sub-text-color;
        }
    }

    .question-separator {

        // .divider-wrapper {
        //     max-width: 67rem;
        // }

        .question-divider-image-width {
            width: 60rem;
            height: 0.12rem;
          }

          .marks-image-size {
            width: 1.2rem;
            margin-right: 0.3rem;
          }

          .marks-container {
            border: 2.45238px solid #A99ADF;
            width: 70px;
            height: 70px;
            border-radius: 50px;
            // padding: 2rem;
          }

          .mark-text {
            font-size: $font-size-26px;
          }

    }


    // div::-webkit-scrollbar {
    //     display: none;
    // }
}