@import '../../../styles/style-variables.scss';

.view-answer-timer {
    .header-image-size {
        width: calc(100% + 48px);
        margin-left: -3rem;
        height: 5rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        // margin-top: -4rem;
        position: absolute;
        top: 5.5rem;
        background: transparent !important;
        padding: 0 !important;
        // z-index: 12;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .notes-image {
        width: 13rem;
    }

    .timer-text {
        font-size: $font-size-20px;
        font-weight: 600;
    }

    .gray-divider {
        border: 1px solid rgba(69, 91, 100, 0.50);
        margin-left: -3rem;
    }

    .bulb-image {
        width: 2.5rem;
    }

    .did-you-know-text {
        font-weight: 700;
        font-size: $font-size-19px;
    }

    .cross-linking-image {
        width: 12rem;
    }

    .cross-detail-wrapper {
        max-width: 98%;
        border-radius: 20px;
        // background: #FDF3E9;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 1rem !important;
        gap: 2rem;
    }

    .description-text {
        font-size: $font-size-16px;
        font-weight: 600;
    }

    .practice-button {
        border-radius: 20px;
        min-width: 10rem;
        max-width: 15rem !important;
        padding: 0.4rem 0.4rem !important;
        // background: #FDF3E9;
        color: $white;
        font-size: $font-size-15px;
    }


}