@import '../../styles/style-variables.scss';

.my-profile {
    .image-size {
        width: 900px;
        height: 80px;
    }

    .title {
        font-size: $font-size-28px;
        font-weight: 700;
    }

    .image-size {
        width: 900px;
        height: 80px;
    }

    .divider-image-width {
        width: 98%;
        height: 1.5px;
    }

    .search-icon-width {
        width: 35px;
        position: relative;
        left: 2.5rem;
    }

    .input-box {
        width: 200px;
        height: 48px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        border: none;
        text-indent: 40px;
    }

    .header-title-margin-top {
        margin-top: -4.5rem;
    }

    .breadcrumb {
        font-size: $font-size-16px;
        color: $sub-text-color;
        font-weight: 600;
        position: relative;
        z-index: 1;

        background: none !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .title-2:hover,
    .title-3:hover,
    .title-4:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }

    .profile-circle {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        background-color: #F0F0F0;
        // overflow: hidden;
        border: 2px solid #BDEBEF;
        position: relative;
    }

    .std-image {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
    }

    .edit-icon {
        position: absolute;
        right: 0;
        bottom: 0.6rem;
        width: 35px;
        height: 35px;
        border-radius: 50px;
        background-color: #BDEBEF;


        .pen-edit-icon {
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    .profile-info {
        border-radius: 20px;
        border: 2px solid #BDEBEF;
        width: 98%;
        min-height: 10rem;
        

        .profile-info-header {
            padding: 0.7rem 1.5rem;
            background: #BDEBEF;
            border-radius: 18px 18px 0 0;
        }

        .info-text {
            color: #455B64;
            font-size: $font-size-17px;
            font-weight: 600;
        }

        .info-edit-icon {
            width: 1.2rem;
        }
    }

    .field-wrapper {
        padding: 0.7rem 1.5rem;
        border-bottom: 1.5px solid rgba(0, 0, 0, 0.10);

        .field-name {
            color: #455B64;
            font-size: $font-size-15px;
            font-weight: 600;
        }

        .field-value {
            // color: #455B64;
            font-size: $font-size-16px;
            font-weight: 600;
        }

        .place-holder-text {
            font-size: $font-size-14px;
            font-weight: 400;
        }

        .school-code {
            font-size: $font-size-12px;
            font-weight: 500;
            color: #455B64;
        }
    }

    .no-border {
        border-bottom: 0 !important;
    }

    .delete-wrapper {
        padding: 1.5rem 1.5rem;

        .delete-image {
            width: 1.5rem;
        }

        .field-value {
            color: #455B64;
            font-size: $font-size-16px;
            font-weight: 600;
        }

    
    }

   
}