
@import '../../styles/style-variables.scss';
.my-classwork {
    .row-max-width {
        width: 35rem;
        background: #FFFFFF;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        height: 46px !important;
    }

    .no-homework-image-size {
        width: 20rem;
    }

    .no-homework-text {
        font-size: $font-size-19px;
        font-weight: 600;
        color: $report-filter-label
    }

    .answer-status-text {
        font-size: $font-size-20px;
        font-weight: 500;
        color: #455B64;
        cursor: pointer;
    }

    .completed-tab {
        background-color: #69C7C1;
        color: white !important;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .not-completed-tab {
        background-color: #69C7C1;
        color: white !important;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

}