@import'../../../styles/style-variables.scss';

.intro-video {

    // margin-left: 10.2rem;
    

    .video-heading {
        width: 70rem;
        border: 1px solid rgba(69, 91, 100, 0.5);
        border-radius: 10px;
        padding: 0.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        margin-top: 1rem;
        // margin-left: 12.5rem;
    }

    .video-wrapper {
        min-height: 25.5rem;
        video {
            border-radius: 15px;
            width: 43rem;
        }
    }

    .countingwell-text {
        color: #263238;
        font-size: $font-size-20px;
        font-weight: 800;
    }

    .learning-text {
        color: #263238;
        font-size: $font-size-18px;
        font-weight: 500;
    }

    .play-text {
        color: #455B64;
    }

    .plus {
        color: #11BFD7;
    }

    .poster-image-size {
        width: 43rem;
    }

    .skip-button {
        background: linear-gradient(90deg, #06C5D9 0%, #60D590 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        color: white;
        padding: 0.7rem 0rem;
        width: 12rem;
        font-size: $font-size-18px;
        font-weight: 500;
        margin-top: 1.2rem;
    }

    .skip-button-disabled {
        background: #DFDFDF !important;
        color: #9D9D9D !important;
    }

}