@import '../../../styles/style-variables.scss';

.event-help {

    .close-icon {
        width: 1.5rem;
        margin-top: -1rem;
    }

    .divider-line {
        width: 99%;
        height: 0.12rem;
        background-color: #85CB9E;
    }

    .header {
        padding-bottom: 0.5rem !important;
    }

    .header-text {
        font-size: $font-size-20px;
        font-weight: 700;
    }

    .arrow-size {
        width: 1rem;
    }

    .question-divider-line {
        width: 99%;
        height: 0.03rem;
        background-color: rgba(69, 91, 100, 0.50) !important;
    }

    .question-title {
        font-size: $font-size-16px;
        font-weight: 600;
    }

    .event-list-header {
        .title {
            font-size: $font-size-32px;
            font-weight: bold;
        }

        .filter-wrapper {
            margin-right: 5rem;
        }

        .filter-size {
            width: 1.8rem;
        }

        .image-size {
            width: 900px;
            height: 80px;
        }

        .divider-image-width {
            // width: 1160px;
            width: 99%;
            height: 1.5px;
        }

        .search-icon-width {
            width: 35px;
            position: relative;
            left: 2.5rem;
        }

        .input-box {
            width: 200px;
            height: 48px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            border: none;
            text-indent: 40px;
        }

        .header-title-margin-top {
            margin-top: -4rem;
        }

        .breadcrumb {
            font-size: $font-size-16px;
            color: $sub-text-color;
            font-weight: 600;

            background: transparent !important;
            padding: 0 !important;

            .active {
                color: #1C1C1C;
            }
        }

        .title-1:hover,
        .home:hover {
            color: black;
            font-weight: 800;
        }



    }
}

// .custom-help-modal {

//     margin-top: 5rem !important;

//     .modal-body {

//     }


//     .modal-content {
//         min-height: 25rem !important;
//     }

//     .close-icon {
//         width: 1.5rem;
//         margin-top: -1rem;
//     }

//     .divider-line {
//         width: 100%;
//         height: 0.12rem;
//         background-color: #85CB9E;
//     }

//     .header {
//         padding-bottom: 0.5rem !important;
//     }

//     .header-text {
//         font-size: $font-size-20px;
//         font-weight: 700;
//     }

//     .arrow-size {
//         width: 1rem;
//     }

//     .question-divider-line {
//         width: 96.4%;
//         height: 0.05rem;
//         background-color: rgba(69, 91, 100, 0.50) !important;
//     }

//     .question-title {
//         font-size: $font-size-16px;
//         font-weight: 600;
//     }
// }