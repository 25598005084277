@import '../../../../styles/style-variables.scss';
.approach-to-problem-solving-experience {
    .breadcrumb {
        position: relative;
        z-index: 99999;
        font-size: $font-size-16px;
        color: $sub-text-color !important;
        font-weight: 600;

        background: transparent !important;
        padding: 0 !important;

        .active {
            color: #1C1C1C;
        }
    }

    .title-1:hover,
    .home:hover {
        color: black;
        font-weight: 800;
    }
}