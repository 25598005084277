.dropdown-for-fill-in-the-blank {
    position: absolute;
    top: 3.55rem;
    z-index: 999999;
    width: 50%;
    // margin-left: 0.5rem;
    min-height: 4rem;
    max-height: 50rem;
    // padding: 1rem;
    background: #FFFFFF;
    border: 1.78261px solid #B1ACE9;
    box-shadow: 0px 7.13043px 7.13043px rgba(0, 0, 0, 0.25);
    border-radius: 14.2609px;

    .list-item-style {
        border-bottom: 1px solid #B1ACE9;
        // width: 100%;
        padding: 0.6rem;
        cursor: pointer;
        padding-left: 2rem;
    }

    .close-icon-wrapper {
        position: absolute;
        z-index: 9999999;
        right: 0;
        top: -0.9rem;
        cursor: pointer;
    }

    .close-icon {
        width: 1.5rem;
    }

}